var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('app-card',{attrs:{"has-header":false,"is-transparent":true,"closeable":false}},[_c('v-layout',{attrs:{"row":"","wrap":"","py-4":""}},[_c('v-form',{ref:"form-changepassword",staticStyle:{"width":"100%"},attrs:{"lazy-validation":false}},[_c('span',[_vm._v("Bitte geben Sie das alte Passwort ein:")]),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"type":"password","rules":[
          _vm.rules.required
        ],"data-cy":'input-password-old',"dense":""},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('span',[_vm._v("Bitte geben Sie das neue Passwort ein:")]),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"type":"password","rules":[
          _vm.rules.required,
          _vm.rules.minLength,
          _vm.rules.numeric,
          _vm.rules.letterLowercase,
          _vm.rules.letterUppercase
        ],"data-cy":'input-password-new',"dense":""},model:{value:(_vm.passwords.password),callback:function ($$v) {_vm.$set(_vm.passwords, "password", $$v)},expression:"passwords.password"}}),_c('span',[_vm._v("Bitte geben Sie das neue Passwort erneut ein:")]),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"type":"password","rules":[
          _vm.rules.required,
          _vm.rules.passwordConfirmed
        ],"data-cy":'input-password-new-confirm',"dense":""},model:{value:(_vm.passwords.passwordConfirmed),callback:function ($$v) {_vm.$set(_vm.passwords, "passwordConfirmed", $$v)},expression:"passwords.passwordConfirmed"}})],1),_c('v-btn',{staticStyle:{"border-radius":"0px","background-color":"#3365bd","color":"white"},attrs:{"depressed":"","data-cy":'button-http-change-password'},on:{"click":function($event){return _vm.changePassword()}}},[_vm._v(" Passwort ändern ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }