
import { Component, Vue } from 'vue-property-decorator';

import AppCard from '../../../core/components/cards/AppCard.vue';
import DashboardDownloadsRow from '../components/DashboardDownloadsRow.vue';

@Component({
  components: { AppCard, DashboardDownloadsRow}
})
export default class ViewDashboardPersons extends Vue {

}
