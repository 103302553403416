import { render, staticRenderFns } from "./ConfirmJamaatHalqaDeleteDialog.vue?vue&type=template&id=060f0dcc&scoped=true"
import script from "./ConfirmJamaatHalqaDeleteDialog.vue?vue&type=script&lang=ts"
export * from "./ConfirmJamaatHalqaDeleteDialog.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "060f0dcc",
  null
  
)

export default component.exports