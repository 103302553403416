
  import {
    Component,
    Prop,
    Vue,
    Watch,
    Mixins,
    Emit
  } from "vue-property-decorator";
  import Chart from "chart.js";
  import VueChart from "vue-chartjs";
  import "chartjs-plugin-colorschemes";
  import "chartjs-plugin-zoom";

  /**
   * Time serese chart
   */
  @Component
  export default class TimeSeriesChart extends Mixins(
    VueChart.Line,
    VueChart.mixins.reactiveProp
  ) {
    /**
     * Chart data
     */
    @Prop({ required: true, default: {} })
    public chartData!: Chart.ChartData;

    @Prop({ required: true, default: 100 })
    public maximumReports!: number;

    @Prop({ required: true, default: [] })
    public xAxesLabels!: string[];

    /**
     * Chart options(If override default options)
     */
    @Prop()
    public chartOptions!: Chart.ChartOptions;

    /**
     * Chart options
     */
    private options: Chart.ChartOptions = {};

    /**
     * Chart instance
     */
    private chart!: Chart;

    /**
     * Chart instance for handling zoom actions
     */
    private zoomChartInstance!: Chart;

    /**
     * initialize
     */
    mounted() {
      if (!this.chartOptions) {
        this.applyDefaultOptions();
      }
      // Draw chart
      this.renderChart(this.chartData, this.options);
      this.chart = this.$data._chart;
    }

    /**
     * Default Options
     */
    private applyDefaultOptions() {
      this.options.maintainAspectRatio = false;
      this.options.scales = {
        xAxes: [
          {
            type: 'category',
            labels: this.xAxesLabels
          }
        ],
        yAxes: [
          {
            ticks: {
              precision: 0,
              max: this.maximumReports,
              min: 0,
            },
          }
        ],
      };
      this.options.plugins = {
        colorschemes: {
          scheme: "brewer.Paired12"
        }
      };
      this.options.elements = {
        line: {
          tension: 0,
          fill: false
        }
      };
    }
  }
