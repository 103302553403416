
  import {Component, Prop, Vue} from "vue-property-decorator";
  import ApprovalService from '../../../approval.service';
  import LoadingOverlay from "../../../../../core/components/LoadingOverlay.vue"
  import {Tajneed} from "../../../tajneed.types";
  import BasedataService from "../../../../../services/basedata.service"

  @Component({
    components: {LoadingOverlay},
  })
  export default class ApproveNewTajneedDialog extends Vue {

    private declineReason = '';

    @Prop({default: null, required: true})
    newTajneed: Tajneed;

    private showOverlay = false;

    private jamaatItems: string[] = [];

    private majlisItems: string[] = [];

    private rules = {
      required: (value: any) => !!value || 'Pflichtfeld',
      dateFormat: (v: string) => /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/.test(v) || 'TT.MM.JJJJ Format',
      digitsOnly: (v: string) => /^(\s*|\d+)$/.test(v) || 'Jamaat-ID darf nur Zahlen beinhalten.',
      validWaqfenauId: (v: string) => /^[0-9][0-9]?[0-9]?[0-9]?[0-9]?[A-Z]$/.test(v) || 'Keine gültige Waqf-e-Nau Nummer',
    };

    created(){
      BasedataService.getJamaatAndMajalis()
        .then((response: any) => {
          this.jamaatItems = response.data.jamaats;
          this.majlisItems = response.data.majalis;
        })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: 'Netzwerkfehler',
            type: 'error'
          });
        })
        .finally(() => {
          setTimeout(() => {
          }, 5000);
        });
    }

    approveNewTajneed() {
      // @ts-ignore
      this.newTajneed.waqfenauId = this.newTajneed.waqfenauId.replace(" ", "");
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.showOverlay = true;
        ApprovalService.approveNewTajneed(this.newTajneed.id)
          .then(
            (response: any) => {
              this.showOverlay = false;
              this.$notify({
                group: 'foo',
                title: 'Info',
                text: response.data,
                type: 'success'
              });
              this.$emit('on-success', "");
            })
          .catch((error: any) => {
            this.$notify({
              group: 'foo',
              title: 'Fehler',
              text: error.response.data.message,
              type: 'error'
            });
            this.showOverlay = false;
          })
          .finally(() => {
            setTimeout(() => {
              this.showOverlay = false;
            }, 5000);
          });
      }

    }

    declineNewTajneed() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.showOverlay = true;
        ApprovalService.declineNewTajneed(this.newTajneed.id)
          .then((response: any) => {
            this.showOverlay = false;
            this.$notify({
              group: 'foo',
              title: 'Info',
              text: response.data,
              type: 'success'
            });
            this.$emit('on-success');
          })
          .catch((error: any) => {
            this.$notify({
              group: 'foo',
              title: 'Fehler',
              text: error,
              type: 'error'
            });
            this.showOverlay = false;
          })
          .finally(() => {
            setTimeout(() => {
              this.showOverlay = false;
            }, 5000);
          });
      }
    }

  }
