
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TableButton extends Vue {
  @Prop()
  private loading!: boolean;

  @Prop()
  private text!: string;

  @Prop()
  private icon!: string;

  @Prop({ required: false, default: false })
  private preventMobileSwitch!: boolean;

  @Prop()
  private callback!: () => {};

  @Prop({ required: false, default: false })
  private isDisabled!: boolean;

  @Prop({ required: false, default: false })
  private xtraSmall!: boolean;

  private click() {
    this.callback();
  }

  customRowClass(shape: string) {
    let classes = '';

    if (this.$vuetify.breakpoint.xsOnly ) {
      if(shape == 'round') {
        classes = classes + ' mr-1';
      }
      if(shape == 'block') {
        classes = classes + ' my-1';
      }
    } else {
      if(shape == 'round') {
        classes = classes + ' ma-1';
      }
      if(shape == 'block') {
        classes = classes + ' mb-3';
      }
    }

    if(this.xtraSmall) {
      classes = classes + ' xtra-small';
    }

    return classes;

  }
}
