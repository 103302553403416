
  import {Component, Prop, Vue} from "vue-property-decorator";
  import ApprovalService from '../../../approval.service';
  import LoadingOverlay from "../../../../../core/components/LoadingOverlay.vue"

  @Component({
    components: {LoadingOverlay},
  })
  export default class DeleteTajneedDeclineDialog extends Vue {

    private declineReason = '';

    @Prop({default: null})
    selectedTajneed: any;

    private showOverlay = false;
    notempty = [
      (v: string) => !!v || 'Feld muss ausgefüllt sein'
    ];

    declineDeleteTajneed() {
      this.selectedTajneed.declineReason = this.declineReason;
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.showOverlay = true;
        ApprovalService.declineTajneedDelete(this.selectedTajneed.id, this.declineReason)
          .then((response: any) => {
              this.showOverlay = false;
              this.$notify({
                group: 'foo',
                title: 'Info',
                text: response.data,
                type: 'success'
              });
              this.$emit('event');
            })
          .catch((error: any) => {
            this.$notify({
              group: 'foo',
              title: 'Fehler',
              text: error,
              type: 'error'
            });
            this.showOverlay = false;
          })
          .finally(() => {
            setTimeout(() => {
              this.showOverlay = false;
            }, 5000);
          });
      }

    }

  }
