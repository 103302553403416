
  import moment from 'moment';
  import {Component, Vue} from "vue-property-decorator";
  import AppLoader from "../../../core/components/AppLoader.vue"
  import {LocalReport, NationalReport, NationalReportJson} from '../report.types';
  import ReportService from '../report.service';
  import {Getter} from "vuex-class";
  import {Useraccount} from "../../../models/useraccount.model";
  import LocalReportSignatureInput from "../components/LocalReportSignatureInput.vue";
  import {FileReadUtils} from "../../../utils/filereadutils";
  import NationalReportIndividualData from "../components/NationalReportIndividualData.vue";

  @Component({
    components: {NationalReportIndividualData, AppLoader, LocalReportSignatureInput}
  })
  export default class ViewNationalReport extends Vue {
    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    private loadingReports: boolean = false;

    private finishDisabled: boolean = true;

    private saveDisabled: boolean = false;

    private showIdividualData: boolean = false;

    private search = '';

    private signature: string = '';

    private tajneedAndReportsData: any = null;

    private report: NationalReport | null = null;

    private rules = {
      required: (value: any) => !!value || 'Pflichtfeld',
      digitsOnly: (v: string) => /^(\s*|\d+)$/.test(v) || 'Zur Zahlen erlaubt',
      dateFormat: (v: string) => /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/.test(v) || 'TT.MM.JJJJ Format',
    };

    created() {
      if (!this.$route.params.month) {
        this.$router.push({path: '/bericht/organisation/tabelle'});
      } else {
        let month = this.$route.params.month;
        if (month.toString().length == 1) {
          month = "0" + (month.toString());
        }

        let today = moment();
        let tenthOfMonthAfterMonthOfReport = moment("11." + month + "." + this.$route.params.year, "DD.MM.YYYY").add(1, 'M');
        if (today.isAfter(tenthOfMonthAfterMonthOfReport)) {
          // Current local Date is after the 10th of the next month
          this.saveDisabled = true;
          this.finishDisabled = false;
          this.showIdividualData = true;
        } else {
          // Current local Date is before the 10th of the next month
          this.saveDisabled = false;
          this.finishDisabled = true;
        }
        let thirteenthOfMonthAfterMonthOfReport = moment("31." + month + "." + this.$route.params.year, "DD.MM.YYYY").add(1, 'M');
        if (today.isAfter(thirteenthOfMonthAfterMonthOfReport)) {
          // Current local Date is after the 13th of the next month
          this.finishDisabled = true;
          this.saveDisabled = true;
          this.showIdividualData = true;
        }
        this.loadReports();
      }
    }

    private reloadReports() {
      this.loadReports();
    }

    loadReports() {
      this.loadingReports = true;
      //@ts-ignore
      ReportService.getNationalReport(this.$route.params.month, this.$route.params.year, this.$route.params.type).then((response: any) => {
        this.report = response.data.report;
        this.tajneedAndReportsData = response.data
        this.signature = response.data.report.signature;
        this.loadingReports = false;
      }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
        this.loadingReports = false;
      }).finally(() => {
        setTimeout(() => {
          this.loadingReports = false;
        }, 5000);
      });
    }

    private saveSignature(signature: { signature: string }, type: string) {
      this.signature = signature.signature;
    }

    private save(type: string) {
      if (type != 'save' && !this.signature) {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: "Signatur fehlt",
          type: 'error'
        });
      } else {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
          let test: NationalReportJson = {
            month: this.$route.params.month,
            year: this.$route.params.year,
            //@ts-ignore
            status: type == 'save' ? 'SAVED' : 'FINISHED',
            signature: this.signature
          };
          /* ReportService.saveRegionalReport(test)
            .then((response: any) => {
              this.$notify({
                group: 'foo',
                title: 'Info',
                text: "Gespeichert",
                type: 'success'
              });
            }).catch((error: any) => {
            this.$notify({
              group: 'foo',
              title: 'Fehler',
              text: '',
              type: 'error'
            });
          }).finally(() => {
            setTimeout(() => {
            }, 5000);
          }); */
        }
      }
    }

    private printReport() {
      ReportService.printLocalReport(this.$route.params.month, this.$route.params.year, this.$route.params.organizationName).then((response: any) => {
        FileReadUtils.openSaveAs(response.data.document, 'bericht.pdf');
      }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
        this.loadingReports = false;
      }).finally(() => {
        setTimeout(() => {
          this.loadingReports = false;
        }, 5000);
      });
    }

    getMonthForNumber(month: number): string {
      switch (month) {
        case 0:
          return "Januar";
        case 1:
          return "Februar";
        case 2:
          return "März";
        case 3:
          return "April";
        case 4:
          return "Mai";
        case 5:
          return "Juni";
        case 6:
          return "Juli";
        case 7:
          return "August";
        case 8:
          return "September";
        case 9:
          return "Oktober";
        case 10:
          return "November";
        case 11:
          return "Dezember";
        default:
          return "";
      }
    }

    getNumberForMonth(month: string): number {
      switch (month) {
        case "Januar":
          return 0;
        case "Februar":
          return 1;
        case "März":
          return 2;
        case "April":
          return 3;
        case "Mai":
          return 4;
        case "Juni":
          return 5;
        case "Juli":
          return 6;
        case "August":
          return 7;
        case "September":
          return 8;
        case "Oktober":
          return 9;
        case "November":
          return 10;
        case "Dezember":
          return 11;
        default:
          return -1;
      }
    }
  }
