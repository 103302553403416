
  import moment from 'moment';
  import {Component, Vue} from "vue-property-decorator";
  import AppLoader from "../../../core/components/AppLoader.vue"
  import {LocalReport, RegionalReportJson, ReportTajneedSessionAttendedSet} from '../report.types';
  import ReportService from '../report.service';
  import {Getter} from "vuex-class";
  import {Useraccount} from "../../../models/useraccount.model";
  import LocalReportIndividualData from "../components/LocalReportIndividualData.vue";
  import LocalReportSignatureInput from "../components/LocalReportSignatureInput.vue";
  import {FileReadUtils} from "../../../utils/filereadutils";
  import RegionalReportIndividualData from "../components/RegionalReportIndividualData.vue";

  @Component({
    components: {RegionalReportIndividualData, LocalReportIndividualData, AppLoader, LocalReportSignatureInput}
  })
  export default class ViewRegionalReport extends Vue {
    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    private loadingReports: boolean = false;

    private allLocalOrganizations: any[] = [];

    private allLocalOrganizationsItems: any[] = [];

    private organizationsMet: any[] = [];

    private reportOrganizationsVisitedList: any[] = [];

    private reportRegionalSittingsList: any[] = [];

    private finishDisabled: boolean = true;

    private saveDisabled: boolean = false;

    private showIdividualData: boolean = false;

    private search = '';

    private signature: string = '';

    private tajneedAndReportsData: any = null;

    private report: LocalReport | null = null;

    private rules = {
      required: (value: any) => !!value || 'Pflichtfeld',
      digitsOnly: (v: string) => /^(\s*|\d+)$/.test(v) || 'Zur Zahlen erlaubt',
      dateFormat: (v: string) => /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/.test(v) || 'TT.MM.JJJJ Format',
    };

    private setId(visit: any) {
      visit.organizationId = this.allLocalOrganizationsItems.filter(o => o.text == visit.text)[0].organizationId;
    }

    created() {
      if (!this.$route.params.month) {
        this.$router.push({path: '/bericht/organisation/tabelle'});
      } else {
        let month = this.$route.params.month;
        if (month.toString().length == 1) {
          month = "0" + (month.toString());
        }

        let today = moment();
        let dateAfterWhichFinishIsPossible = moment("01." + month + "." + this.$route.params.year, "DD.MM.YYYY").add(1, 'M');
        if (today.isSameOrAfter(dateAfterWhichFinishIsPossible)) {
          // Current local Date is after dateAfterWhichFinishIsPossible
          this.saveDisabled = true;
          this.finishDisabled = false;
          this.showIdividualData = true;
        } else {
          // Current local Date is before dateAfterWhichFinishIsPossible
          this.saveDisabled = false;
          this.finishDisabled = true;
        }
        let dateBeforeWhichFinishIsPossible = moment("11." + month + "." + this.$route.params.year, "DD.MM.YYYY").add(1, 'M').endOf('month');;
        if (today.isSameOrAfter(dateBeforeWhichFinishIsPossible)) {
          // Current local Date is after dateBeforeWhichFinishIsPossible
          this.finishDisabled = true;
          this.saveDisabled = true;
          this.showIdividualData = true;
        }
        this.loadReports();
      }
    }

    private reloadReports() {
      this.loadReports();
    }

    loadReports() {
      this.loadingReports = true;
      //@ts-ignore
      ReportService.getRegionalReport(this.$route.params.month, this.$route.params.year, this.$route.params.type, this.$route.params.organizationName,).then((response: any) => {
        this.report = response.data.report;
        this.tajneedAndReportsData = response.data
        this.allLocalOrganizations = response.data.allLocalOrganizations;
        for (var i = 0; i < this.allLocalOrganizations.length; i++) {
          this.allLocalOrganizationsItems.push({
            text: this.allLocalOrganizations[i].name,
            organizationId: this.allLocalOrganizations[i].id
          });
        }
        this.organizationsMet = [];
        response.data.report.reportOrganizationsMetList.forEach((m: any) => {
          this.organizationsMet.push({
            text: m.text,
            organizationId: m.organizationId,
          });
        });
        this.reportOrganizationsVisitedList = [];
        response.data.report.reportOrganizationsVisitedList.forEach((m: any) => {
          this.reportOrganizationsVisitedList.push({
            text: m.text,
            organizationId: m.organizationId,
            dateOfVisit: m.dateOfVisit
          });
        });
        this.reportRegionalSittingsList = [];
        response.data.report.reportRegionalSittingsList.forEach((m: any) => {
          this.reportRegionalSittingsList.push({
            text: m.text,
            attendance: m.attendance,
            date: m.date
          });
        });
        this.signature = response.data.report.signature;
        this.loadingReports = false;
      }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
        this.loadingReports = false;
      }).finally(() => {
        setTimeout(() => {
          this.loadingReports = false;
        }, 5000);
      });
    }

    private addRowToVisitedSet() {
      this.reportOrganizationsVisitedList.push({});
    }

    private removeRowFromVisitedSet(index: number) {
      this.reportOrganizationsVisitedList.splice(index, 1);
    }

    private addListToSittingsList() {
      this.reportRegionalSittingsList.push({});
    }

    private removeRowFromSittingsList(index: number) {
      this.reportRegionalSittingsList.splice(index, 1);
    }

    private saveSignature(signature: { signature: string }, type: string) {
      this.signature = signature.signature;
    }

    private save(type: string) {
      if (type != 'save' && !this.signature) {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: "Signatur fehlt",
          type: 'error'
        });
      } else {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
          let test: RegionalReportJson = {
            month: this.$route.params.month,
            year: this.$route.params.year,
            reportOrganizationsMetList: this.organizationsMet,
            reportOrganizationsVisitedList: this.reportOrganizationsVisitedList,
            reportRegionalSittingsList: this.reportRegionalSittingsList,
            //@ts-ignore
            sittingWithWaqifin: this.report.sittingWithWaqifin,
            status: type == 'save' ? 'SAVED' : 'FINISHED',
            signature: this.signature
          };
          ReportService.saveRegionalReport(test)
            .then((response: any) => {
              this.$notify({
                group: 'foo',
                title: 'Info',
                text: "Gespeichert",
                type: 'success'
              });
              this.loadReports();
            }).catch((error: any) => {
            this.$notify({
              group: 'foo',
              title: 'Fehler',
              text: '',
              type: 'error'
            });
          }).finally(() => {
            setTimeout(() => {
            }, 5000);
          });
        }
      }
    }

    private printReport() {
      let type = "";
      if (this.report!.regionLocalAmarat != null && this.report!.regionLocalAmarat != '') {
        type = "jamaat";
      } else if (this.report!.lajnaRegion != null && this.report!.lajnaRegion != '') {
        type = "lajna"
      }
      ReportService.printRegionalReport(this.$route.params.month, this.$route.params.year, this.$route.params.organizationName, type).then((response: any) => {
        FileReadUtils.openSaveAs(response.data.document, 'bericht.pdf');
      }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
        this.loadingReports = false;
      }).finally(() => {
        setTimeout(() => {
          this.loadingReports = false;
        }, 5000);
      });
    }

    getMonthForNumber(month: number): string {
      switch (month) {
        case 0:
          return "Januar";
        case 1:
          return "Februar";
        case 2:
          return "März";
        case 3:
          return "April";
        case 4:
          return "Mai";
        case 5:
          return "Juni";
        case 6:
          return "Juli";
        case 7:
          return "August";
        case 8:
          return "September";
        case 9:
          return "Oktober";
        case 10:
          return "November";
        case 11:
          return "Dezember";
        default:
          return "";
      }
    }

    getNumberForMonth(month: string): number {
      switch (month) {
        case "Januar":
          return 0;
        case "Februar":
          return 1;
        case "März":
          return 2;
        case "April":
          return 3;
        case "Mai":
          return 4;
        case "Juni":
          return 5;
        case "Juli":
          return 6;
        case "August":
          return 7;
        case "September":
          return 8;
        case "Oktober":
          return 9;
        case "November":
          return 10;
        case "Dezember":
          return 11;
        default:
          return -1;
      }
    }
  }
