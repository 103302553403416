
  import {Component, Vue} from "vue-property-decorator";
  import AppLoader from "../../../core/components/AppLoader.vue"
  import AppCard from '../../../core/components/cards/AppCard.vue';
  import {Getter} from "vuex-class";
  import {Useraccount} from "../../../models/useraccount.model";
  import CreateNewMissionaryEntryDialog from "../components/CreateNewMissionaryEntryDialog.vue";
  import ParentsCouncellingService from "./../parents-councelling.service";
  import TableButton from '../../../core/components/TableButton.vue';
  import {Missionary} from "../parents-councelling.types";

  @Component({
    components: {AppCard, AppLoader, CreateNewMissionaryEntryDialog: CreateNewMissionaryEntryDialog, TableButton}
  })
  export default class ViewMissionariesList extends Vue {
    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    private loadingMissionaries: boolean = false;

    private createNewMissionaryEntryDialogVisible: boolean = false;

    private selectedMissionaryForEdit: Missionary | null = null;

    private missionariesTableHeaders = [
      {text: 'Name', align: 'start', sortable: false, value: 'name'},
      {text: 'Jamaat-ID', align: 'start', sortable: false, value: 'aimsId'},
      {text: 'Jamaats', align: 'start', sortable: false, value: 'jamaats'},
      {text: 'Username', align: 'start', sortable: false, value: 'username'},
      {text: 'Aktionen', align: 'center', sortable: false, value: 'actions'},
    ];

    private missionariesTableItems: any[] = [];

    created() {
      this.loadMissionariesList();
    }

    private loadMissionariesList() {
      this.loadingMissionaries = true;
      ParentsCouncellingService.getMissionariesList().then((response: any) => {
        this.missionariesTableItems = response.data;
        this.loadingMissionaries = false;
      })
    }

    private createNewMissionaryEntry() {
      this.selectedMissionaryForEdit = null;
      this.createNewMissionaryEntryDialogVisible = true;
    }

    private editMissionaryEntry(item: Missionary) {
      this.selectedMissionaryForEdit = JSON.parse(JSON.stringify(item));
      this.createNewMissionaryEntryDialogVisible = true;
    }

    private handleCloseCreateNewMissionaryEntryDialog() {
      this.createNewMissionaryEntryDialogVisible = false;
      this.selectedMissionaryForEdit = null;
      this.loadMissionariesList();
    }

    private deleteMissionaryEntry(id: number) {
      this.loadingMissionaries = true;
      ParentsCouncellingService.deleteMissionaryEntry(id).then((response: any) => {
        this.loadMissionariesList();
        this.loadingMissionaries = false;
      })
    }
  }
