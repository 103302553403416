var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticStyle:{"padding":"20px"}},[_c('h2',{attrs:{"align":"center"}},[_vm._v("Kern Tajneed Daten ändern")]),_c('br'),_c('v-container',[_c('v-form',{ref:"form"},[_c('span',[_vm._v("Vorname: "+_vm._s(this.selectedTajneed.firstname)+" ")]),_c('br'),_c('span',[_vm._v("Nachname: "+_vm._s(this.selectedTajneed.lastname)+" ")]),_c('br'),_c('br'),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required,
          _vm.rules.digitsOnly,
        ],"dense":"","label":"Jamaat-ID"},model:{value:(_vm.selectedTajneed.aimsId),callback:function ($$v) {_vm.$set(_vm.selectedTajneed, "aimsId", $$v)},expression:"selectedTajneed.aimsId"}}),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required,
          _vm.rules.validWaqfenauId,
        ],"dense":"","label":"Waqf-e-Nau-ID"},model:{value:(_vm.selectedTajneed.waqfenauId),callback:function ($$v) {_vm.$set(_vm.selectedTajneed, "waqfenauId", $$v)},expression:"selectedTajneed.waqfenauId"}}),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required,
          _vm.rules.dateFormat,
        ],"dense":"","label":"Geburtsdatum"},model:{value:(_vm.selectedTajneed.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.selectedTajneed, "dateOfBirth", $$v)},expression:"selectedTajneed.dateOfBirth"}}),_c('v-select',{staticStyle:{"border-radius":"0px"},attrs:{"label":"Geschlecht","outlined":"","dense":"","item-text":"text","item-value":"value","items":[{text: 'Männlich', value: 'Male'}, {text: 'Weiblich', value: 'Female'}],"rules":[
          _vm.rules.required,
        ]},model:{value:(_vm.selectedTajneed.sex),callback:function ($$v) {_vm.$set(_vm.selectedTajneed, "sex", $$v)},expression:"selectedTajneed.sex"}}),(_vm.selectedTajneed.sex=='Male')?_c('v-autocomplete',{staticStyle:{"border-radius":"0px"},attrs:{"dense":"","outlined":"","items":_vm.jamaatItems,"rules":[
          _vm.rules.required,
        ],"label":"Jamaat"},model:{value:(_vm.selectedTajneed.jamaat),callback:function ($$v) {_vm.$set(_vm.selectedTajneed, "jamaat", $$v)},expression:"selectedTajneed.jamaat"}}):_vm._e(),(_vm.selectedTajneed.sex=='Female')?_c('v-autocomplete',{staticStyle:{"border-radius":"0px"},attrs:{"dense":"","outlined":"","items":_vm.majlisItems,"rules":[
          _vm.rules.required,
        ],"label":"Majlis"},model:{value:(_vm.selectedTajneed.majlis),callback:function ($$v) {_vm.$set(_vm.selectedTajneed, "majlis", $$v)},expression:"selectedTajneed.majlis"}}):_vm._e(),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"dense":"","label":"Name Vater"},model:{value:(_vm.selectedTajneed.fathersName),callback:function ($$v) {_vm.$set(_vm.selectedTajneed, "fathersName", $$v)},expression:"selectedTajneed.fathersName"}}),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"dense":"","label":"Name Mutter"},model:{value:(_vm.selectedTajneed.mothersName),callback:function ($$v) {_vm.$set(_vm.selectedTajneed, "mothersName", $$v)},expression:"selectedTajneed.mothersName"}})],1),_c('br'),_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":"","justify":'center'}},[_c('v-btn',{attrs:{"color":"primary"},nativeOn:{"click":function($event){return _vm.changeTajneedCoreData.apply(null, arguments)}}},[_vm._v("Speichern")])],1),(_vm.showOverlay)?_c('LoadingOverlay'):_vm._e()],1),_c('br')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }