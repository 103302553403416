
import moment from 'moment';

import {Component, Prop, Vue} from 'vue-property-decorator';

import AppCard from '../../../core/components/cards/AppCard.vue';
import DashboardCol from '../../../core/components/DashboardCol.vue';
import DashboardRow from '../../../core/components/DashboardRow.vue';
import DashboardTileReport from './DashboardTileReport.vue';
import DashboardTileJaiza from './DashboardTileJaiza.vue';
import DashboardTileDocuments from "./DashboardTileDocuments.vue";

@Component({
components: {
  DashboardTileDocuments,
  DashboardTileReport,
  DashboardTileJaiza,
  AppCard,
  DashboardCol,
  DashboardRow,
},
})
export default class DashboardMenuSecondRow extends Vue {

  @Prop({default: "", required: false})
  dateOfBirth!: string;

  @Prop({default: "", required: false})
  personId!: string;

  private isYoungerThenSeven: boolean = false;

  created() {
    this.isYoungerThenSeven = moment().diff(this.dateOfBirth, 'years') < 7 ? true : false
  }

}
