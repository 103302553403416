
  import {Component, Vue} from "vue-property-decorator";
  import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
  import AppLoader from "../../../core/components/AppLoader.vue"
  import {Getter, Mutation} from "vuex-class";
  import {Useraccount} from "../../../models/useraccount.model";
  import {IndividualReportsForGroupJson} from '../report.types';
  import ReportService from '../report.service';
  import moment from 'moment';
  import TableButton from '../../../core/components/TableButton.vue';
  import BasedataService from "../../../modules/basedata/basedata.service"

  @Component({
    components: {AppLoader, LoadingOverlay, TableButton}
  })
  export default class ViewTableIndividualReportsGroup extends Vue {
    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    private tableDataWaqifin: IndividualReportsForGroupJson[] = [];
    private tableDataWaqifat: IndividualReportsForGroupJson[] = [];

    @Mutation('SET_INDIVIDUAL_REPORTS', {namespace: 'report'})
    SET_INDIVIDUAL_REPORTS!: (individualReports: IndividualReportsForGroupJson[]) => void;

    @Getter('getIndividualReports', {namespace: 'report'})
    private getIndividualReports!: IndividualReportsForGroupJson[];

    private personId: string = "";

    private nameFilterValue = "";

    private monthFilterValue = "";

    private jamaatFilterItems: string[] = [];

    private tab: number = 0;

    headers = [
      {text: 'Name', value: 'name'}
    ];

    private loadingReports: boolean = false;

    private monthsAndYears: any[] = [];

    created() {
      if(this.getUseraccount.isMissionaryUser) {
        BasedataService.getJamaatAndMajalisForUser()
        .then((response: any) => {
          this.jamaatFilterItems = response.data.jamaats;
        })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: 'Netzwerkfehler',
            type: 'error'
          });
        })
        .finally(() => {
          setTimeout(() => {
          }, 5000);
        });
      }
      if (this.getUseraccount.isLocalUser || this.getUseraccount.isRegionalUser) {
        this.headers.push({text: 'Name Vater', value: 'fathersName'});
      }
      if (this.getUseraccount.isNationalJamaatUser) {
        this.headers.push({text: 'Jamaat', value: 'jamaatMajlis'});
        this.headers.push({text: 'Region', value: 'region'});
      }
      if (this.getUseraccount.isMissionaryUser) {
        this.headers.push({text: 'Jamaat', value: 'jamaatMajlis'});
      }
      this.headers.push({text: 'Alter', value: 'age'});
      this.loadReports();

      for (let i = 12; i > 0; i--) {
        this.headers.push({
          text: this.getMonthForNumber(moment().subtract(i, 'M').month()),
          value: '',
        });
        this.monthsAndYears.push({
          month: moment().subtract(i, 'M').month() + 1,
          year: moment().subtract(i, 'M').year(),
        })
      }

      this.headers.push({
        text: 'Jahr',
        value: '',
      });
    }

    private getReportIsPresent(item: IndividualReportsForGroupJson, month: number, year: number): boolean {
      if (item.allReports.filter(r => r.month == month && r.year == year).length == 1) {
        return true;
      }
      return false;
    }

    private reloadReports() {
      this.loadReports(true);
    }

    loadReports(force: boolean = false) {
      this.loadingReports = true;
      if (this.getIndividualReports.length == 0 || force) {
        //@ts-ignore
        ReportService.getIndividualReportsForGroup().then((response: any) => {
          this.SET_INDIVIDUAL_REPORTS(response.data);
          this.tableDataWaqifin = response.data.filter((r: any) => r.sex == 'Male');
          this.tableDataWaqifat = response.data.filter((r: any) => r.sex == 'Female');
          this.loadingReports = false;
        }).catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: error,
            type: 'error'
          });
          this.loadingReports = false;
        }).finally(() => {
          setTimeout(() => {
            this.loadingReports = false;
          }, 5000);
        });
      } else {
        this.tableDataWaqifin = this.getIndividualReports.filter((r: any) => r.sex == 'Male');
        this.tableDataWaqifat = this.getIndividualReports.filter((r: any) => r.sex == 'Female');
        this.loadingReports = false;
      }

    }

    private nameFilter(valueInRow: any) {
      if (!this.nameFilterValue) {
        return true;
      }
      else if (!valueInRow) {
        return false;
      }
      else {
        return valueInRow.toString().toUpperCase().indexOf(this.nameFilterValue.toUpperCase()) >= 0;
      }
    }

    private monthFilter(valueInRow: any) {
      if (!this.monthFilterValue || this.monthFilterValue == 'Alle') {
        return true;
      }
      else if (!valueInRow) {
        return false;
      }
      else {
        return valueInRow == this.getNumberForMonth(this.monthFilterValue) + 1
      }
    }

    private openReport(selectedEntry: IndividualReportsForGroupJson, month: number, year: number) {
      let report = selectedEntry.allReports.filter(r => r.month == month && r.year == year)[0];
      //@ts-ignore
      this.$router.push({
        name: 'ViewReportIndividual',
        params: {
          reportId: report.id.toString(),
          personId: selectedEntry.personId.toString()
        }
      });
    }

    openDevelopment(selectedEntry: IndividualReportsForGroupJson) {
      //@ts-ignore
      this.$router.push({
        name: 'ViewIndividualDevelopment',
        params: {
          personId: selectedEntry.personId.toString()
        }
      });
    }

    getMonthForNumber(month: number): string {
      switch (month) {
        case 0:
          return "Jan";
        case 1:
          return "Feb";
        case 2:
          return "Mär";
        case 3:
          return "Apr";
        case 4:
          return "Mai";
        case 5:
          return "Jun";
        case 6:
          return "Jul";
        case 7:
          return "Aug";
        case 8:
          return "Sep";
        case 9:
          return "Okt";
        case 10:
          return "Nov";
        case 11:
          return "Dez";
        default:
          return "";
      }
    }

    getNumberForMonth(month: string): number {
      switch (month) {
        case "Januar":
          return 0;
        case "Februar":
          return 1;
        case "März":
          return 2;
        case "April":
          return 3;
        case "Mai":
          return 4;
        case "Juni":
          return 5;
        case "Juli":
          return 6;
        case "August":
          return 7;
        case "September":
          return 8;
        case "Oktober":
          return 9;
        case "November":
          return 10;
        case "Dezember":
          return 11;
        default:
          return -1;
      }
    }
  }
