
  import {Component, Prop, Vue} from "vue-property-decorator";
  import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
  import {Jaiza, JaizaRequest} from "../jaiza.types";
  import JaizaService from "../jaiza.service";

  @Component({
    components: {LoadingOverlay},
  })
  export default class ConfirmSendJaizaDialog extends Vue {

    @Prop({ default: null, required: true })
    jaizaRequest!: JaizaRequest;

    private showOverlay = false;

    deleteLajnaMajlis() {
      this.showOverlay = true;
      JaizaService.finishJaiza(this.jaizaRequest)
        .then((response: any) => {
          this.showOverlay = false;
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: response.data,
            type: 'success'
          });
          this.$emit('success');
        })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: error.response!.data.message,
            type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
    }

  }
