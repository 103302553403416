
  import {Component, Vue} from "vue-property-decorator";
  import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
  import AppLoader from "../../../core/components/AppLoader.vue"
  import {Getter} from "vuex-class";
  import {Useraccount} from "../../../models/useraccount.model";
  import ExamService from '../exam.service';
  import moment from 'moment';
  import TableButton from '../../../core/components/TableButton.vue';
  import BasedataService from "../../../modules/basedata/basedata.service"
  import {FileReadUtils} from "../../../utils/filereadutils";

  @Component({
    components: {AppLoader, LoadingOverlay, TableButton}
  })
  export default class ViewExamsGroup extends Vue {
    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    exams: any[] = [];

    headers = [
      {text: 'Name', value: 'name'},
    ];

    private loadingReports: boolean = false;

    private loadingSearchData: boolean = false;

    private jamaatFilterItems: string[] = [];

    private majlisFilterItems: string[] = [];

    private statusFilterItems: string[] = ['CREATED', 'SAVED', 'FINISHED'];

    private statusForGreenColour: string[] = ['FINISHED'];

    private jamaatFilterValue: string = '';

    private majlisFilterValue: string = '';

    private statusFilterValue: string = '';

    private waqfenauIdFilterValue: string = '';

    private aimsIdFilterValue: string = '';

    private lastExamFinishDate: string = '';

    private years: any[] = [];

    private page: number = 1;
    private totalPages: number = 0;
    private pageSize: number = 30;

    private pageSizes = [10, 30, 90];

    private isResultAvailable(item: any, year: any): boolean {
      if (!this.loadingReports) {
        // For all older jayzas jsut show result:
        if (year < Math.max(...this.years.map(y => y.year).map(Number))) {
          return true;
        }
        if (moment().isSameOrAfter(this.lastExamFinishDate)
          && item.years.filter((yearInItem: any) => yearInItem.year == year && (this.statusForGreenColour.includes(yearInItem.status))).length == 1) {
          return true;
        }
      }
      return false;
    }

    private getRequestParams(jamaatFilterValue: string, majlisFilterValue: string, statusFilterValue: string, waqfenauIdFilterValue: string, aimsIdFilterValue: string, page: number, pageSize: number) {
      let params = {};

      if (jamaatFilterValue) {
        //@ts-ignore
        params["jamaatString"] = jamaatFilterValue;
      }

      if (majlisFilterValue) {
        //@ts-ignore
        params["majlisString"] = majlisFilterValue;
      }

      if (statusFilterValue) {
        //@ts-ignore
        params["status"] = statusFilterValue;
      }

      if (waqfenauIdFilterValue) {
        //@ts-ignore
        params["waqfenauId"] = waqfenauIdFilterValue;
      }

      if (aimsIdFilterValue) {
        //@ts-ignore
        params["aimsId"] = aimsIdFilterValue;
      }

      if (page) {
        //@ts-ignore
        params["page"] = page - 1;
      }

      if (pageSize) {
        //@ts-ignore
        params["size"] = pageSize;
      }

      return params;
    }


    created() {
      this.loadingSearchData = true;
      BasedataService.getJamaatAndMajalisForUser()
        .then((response: any) => {
          this.jamaatFilterItems = response.data.jamaats;
          this.majlisFilterItems = response.data.majalis;
          this.loadingSearchData = false;
          this.loadExams();
        })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: 'Netzwerkfehler',
            type: 'error'
          });
        })
        .finally(() => {
          setTimeout(() => {
          }, 5000);
        });

      //static !?
      for (let i = 2024; i <= 2024; i++) {
        this.headers.push({
          text: i.toString(),
          value: '',
        });
        this.years.push({
          year: i.toString(),
        })
      }
    }

    private getExamIsPresent(item: any, year: string): boolean {
      if (item.years.filter((yearInItem: any) => yearInItem.year == year).length == 1) {
        return true;
      }
      return false;
    }

    private reloadExams() {
      this.loadExams();
    }

    private handlePageChange(value: any) {
      this.page = value;
      this.loadExams();
    }

    handlePageSizeChange(size: any) {
      this.pageSize = size;
      this.page = 1;
      this.loadExams();
    }

    private loadExams() {
      const params = this.getRequestParams(
        this.jamaatFilterValue,
        this.majlisFilterValue,
        this.statusFilterValue,
        this.waqfenauIdFilterValue,
        this.aimsIdFilterValue,
        this.page,
        this.pageSize
      );
      this.loadingReports = true;
      //@ts-ignore
      ExamService.getExamsForGroup(params).then((response: any) => {
        const {exams, totalPages} = response.data;
        this.exams = exams;
        this.totalPages = totalPages;

        this.exams = this.exams.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.personId === value.personId
          ))
        )
        this.loadingReports = false;
        this.lastExamFinishDate = response.data.lastExamFinishDate;
      }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
        this.loadingReports = false;
      }).finally(() => {
        setTimeout(() => {
          this.loadingReports = false;
        }, 5000);
      });
    }

    private search() {
      this.loadExams()
    }

    openExam(selectedEntry: any, year: number) {
      console.log(selectedEntry);
      console.log(year);
      let id = selectedEntry.years.filter((y: any) => y.year == year)[0].id;
      console.log(id);
      //@ts-ignore
      this.$router.push({
        name: 'ViewExam',
        params: {
          personId: selectedEntry.personId.toString(),
          examId: id
        }
      });
    }

    /* openResult(selectedEntry: any, year: string) {
      JaizaService.getResultPdf(selectedEntry.personId.toString(), year).then((response: any) => {
        FileReadUtils.openSaveAs(response.data.document, 'Jaiza.pdf');
      });
    } */

    /* restoreExam(selectedEntry: any, year: string) {
      JaizaService.restoreExam(selectedEntry.personId.toString(), year)
      .then((response: any) => {
        this.loadExams();
        this.$notify({
          group: 'foo',
          title: 'Info',
          text: response.data,
          type: 'success'
        });
      })
      .catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error.response!.data.message,
          type: 'error'
        });
      })
      .finally(() => {
        setTimeout(() => {
        }, 5000);
      });
    } */

    customCellClass(item: any, year: number) {
      if (item.years.filter((yearInItem: any) => yearInItem.year == year && (this.statusForGreenColour.includes(yearInItem.status))).length == 1) {
        return 'light-green-bg';
        }
      else {
        return '';
      }
    }
  }
