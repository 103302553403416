
  import moment from 'moment';
  import {Component, Vue} from "vue-property-decorator";
  import TajneedService from "../../tajneed.service";
  import LoadingOverlay from "../../../../core/components/LoadingOverlay.vue"
  import AppLoader from "../../../../core/components/AppLoader.vue"
  import AppCard from '../../../../core/components/cards/AppCard.vue';

  import TajneedForm from "./TajneedForm.vue";
  import {Tajneed} from "../../tajneed.types";
  import {Getter, Mutation} from "vuex-class";
  import {Useraccount} from "../../../../models/useraccount.model";
  import EditCoreTajneedDataDialog from "../../components/tajneed/dialogs/EditCoreTajneedDataDialog.vue";
  import DeleteTajneedEntryDialog from "../../components/tajneed/dialogs/DeleteTajneedEntryDialog.vue";
  import CommentTajneedEntryDialog from "../../components/tajneed/dialogs/CommentTajneedEntryDialog.vue";

  import {store} from "../../../../store";
  import CreateNewTajneedDialog from "../../components/tajneed/dialogs/CreateNewTajneedDialog.vue";
  import TableButton from '../../../../core/components/TableButton.vue';
  import StaticData from "../../../../staticdata/staticdata"
  import studiengaenge from '../../../../staticdata/studiengaenge.json';
  import studiengaengeundfachrichtungen from '../../../../staticdata/studiengaengeundfachrichtungen.json';
  import ausbildungenundfachrichtungen from '../../../../staticdata/ausbildungenundfachrichtungen.json';
  import BasedataService from "../../../basedata/basedata.service";
  import OfficeHolderDataDialog from '../../../useraccount/components/OfficeHolderDataDialog.vue';

  export interface test {
    text: string,
    value: string,
    filter?: any
  }

  @Component({
    components: {OfficeHolderDataDialog, CreateNewTajneedDialog, DeleteTajneedEntryDialog, EditCoreTajneedDataDialog, AppCard, AppLoader, TajneedForm, LoadingOverlay, TableButton, CommentTajneedEntryDialog}
  })
  export default class TajneedTable extends Vue {

    @Mutation('SET_TAJNEED', {namespace: 'tajneed'})
    SET_TAJNEED!: (tajneed: Tajneed[]) => void;

    @Getter('getTajneed', {namespace: 'tajneed'})
    private getTajneed!: Tajneed[];

    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    private numbersonly = [
      (v: string) => !v || /^\d+$/.test(v) || 'Nur Zahlen erlaubt',
    ];

    private legendLabels = [
        { text: "Waqifin haben keinen Account", color: "indianred" },
        { text: "Waqifin müssen Tajneed eintragen", color: "orange" },
        { text: "Waqifin müssen Tajneed aktualisieren", color: "yellow" },
        { text: "Waqifin haben alle Tajneed Daten aktualisiert", color: "limegreen" }
      ];


    private schoolTypeItems = StaticData.getAllSchulformen();

    private jamaatItems: string[] = [];
    private majlisItems: string[] = [];

    private officeHolderDataDialogVisible: boolean = false;

    tableData: Tajneed[] = [];
    search = "";

    private on: any = null;

    private showOverlay = false;
    private loadingTajneed = false;

    private fullnameFilterValue = "";
    private aimsIdFilterValue = "";
    private waqfenauIdFilterValue = "";
    private jamaatFilterValue = "";
    private majlisFilterValue = "";
    private personFormularIdFilterValue = "";
    private mainFocusFilterValue = "";
    private subjectAreaFilterValue = "";


    private studentOrGraduateFilterValue  = "";

    private courseOfStudyFilterValue: string[] = [];

    private courseOfStudyItems: string[] = [];

    private trainingTypeFilterValue: string[] = [];
    private trainingTypeItems: string[] = [];

    private menu1 = false;
    private dateOfBirthStartFilterValue = null;

    private dateOfBirthEndFilterValue = null;

    private type = "";

    private isReadOnlyUser = false;

    private regionFilterValue = "";

    private lajnaRegionFilterValue = "";

    private schoolTypeFilterValue = "";

    private ageComparatorFilterValue = "";

    private ageFilterValue = "";

    private zusageFilterValue = "";

    private editCoreTajneedDataDialogVisible: boolean = false;

    private commentDialogVisible: boolean = false;

    private selectedTajneedForCoreEdit: Tajneed | null = null;

    private selectedTajneedForComment: Tajneed | null = null;

    private selectedTajneedForDelete: Tajneed | null = null;

    private deleteTajneedDialogVisible: boolean = false;

    private createNewTajneedDialogVisible: boolean = false;

    private showDetailedView: boolean = false;

    private mainFocusItems: string[] = [];

    private subjectAreaItems: string[] = [];


    created() {
      if ((this.getUseraccount.getUsername.startsWith('s-')
        || this.getUseraccount.getUsername.startsWith('m-')
        || this.getUseraccount.getUsername.startsWith('sekretaer-')
        || this.getUseraccount.getUsername.startsWith('muawina-')
        || this.getUseraccount.getUsername.startsWith('1111'))
        && (!this.getUseraccount.getOfficeHolderDataReupdated)
      ) {
        this.officeHolderDataDialogVisible = true;
      }
      this.type = this.$route.meta.id;
      this.courseOfStudyItems = studiengaenge;
      this.trainingTypeItems = ausbildungenundfachrichtungen.map(a => a.ausbildungsart);;
      this.mainFocusItems = studiengaengeundfachrichtungen.map(s => s.schwerpunkt)
      this.subjectAreaItems = studiengaengeundfachrichtungen.map(s => s.fachbereich);

      if (this.getUseraccount.isNaibAmirUser) {
        this.isReadOnlyUser = true;
      }

      BasedataService.getJamaatAndMajalisForUser()
        .then((response: any) => {
          this.jamaatItems = response.data.jamaats;
          this.majlisItems = response.data.majalis;
        })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: 'Netzwerkfehler',
            type: 'error'
          });
        })
        .finally(() => {
          setTimeout(() => {
          }, 5000);
        });

      if(!this.getUseraccount.isNationalUserOrNationalAssistantUser) {
        this.loadTajneed();
      }
    }

    get getHeaders() {
      let headers: test[] = [];

      if (!this.showDetailedView) {
        headers.push(
          {text: 'Jamaat ID', value: 'aimsId'},
          {text: 'Waqf-e-Nau ID', value: 'waqfenauId'},
        );

        headers.push(
          {text: 'Name', value: 'fullname'},
        );

        if ((this.getUseraccount.isNationalJamaatUser || this.getUseraccount.isRegionalJamaatUser) || this.getUseraccount.isMissionaryUser && (this.type == 'waqifin' || this.type == 'all')) {
          headers.push(
            {text: 'Jamaat', value: 'jamaat'}
          );
        }

        if ((this.getUseraccount.isNationalJamaatUser || this.getUseraccount.isNationalLajnaUser || this.getUseraccount.isRegionalLajnaUser) && (this.type == 'waqifat')) {
          headers.push(
            {text: 'Majlis', value: 'majlis'},
          );
        }

        if ((this.getUseraccount.isNationalJamaatUser || this.getUseraccount.isNationalLajnaUser)) {
          headers.push(
            {text: 'Region', value: 'region'},
          );
        }

        headers.push(
          {text: 'Alter', value: 'age'},
        );

        if ((this.getUseraccount.isLocalJamaatUser || this.getUseraccount.isLocalLajnaUser)) {
          headers.push(
            {text: 'Telefon', value: 'phoneNumber'},
          );
        }

        if (this.getUseraccount.isMissionaryUser) {
          headers.push(
            {text: 'Geb.Datum', value: 'dateOfBirth'},
            {text: 'Name Vater', value: 'fathersName'},
            {text: 'Telefon', value: 'phoneNumber'},
          );
        }

        headers.push(
          {text: 'Aktionen', value: 'dateOfBirth'}
        );
      } else {
        if (this.getUseraccount.isNationalJamaatUser) {
          headers.push(
            {text: 'Portal-ID', value: 'id'},
          );
        }

        headers.push(
          {text: 'Jamaat ID', value: 'aimsId'},
          {text: 'Waqf-e-Nau ID', value: 'waqfenauId'},
        );

        if (this.getUseraccount.isNationalJamaatUser) {
          headers.push(
            {text: 'PF-ID', value: 'personFormularId'}
          );
        }

        headers.push(
          {text: 'Name', value: 'fullname'},
        );

        if ((this.getUseraccount.isNationalJamaatUser || this.getUseraccount.isRegionalJamaatUser) && (this.type == 'waqifin' || this.type == 'all')) {
          headers.push(
            {text: 'Jamaat', value: 'jamaat'}
          );
        }

        if ((this.getUseraccount.isNationalJamaatUser || this.getUseraccount.isNationalLajnaUser || this.getUseraccount.isRegionalLajnaUser) && (this.type == 'waqifat')) {
          headers.push(
            {text: 'Majlis', value: 'majlis'},
          );
        }

        if ((this.getUseraccount.isNationalJamaatUser || this.getUseraccount.isNationalLajnaUser)) {
          headers.push(
            {text: 'Region', value: 'region'},
          );
        }

        headers.push(
          {text: 'Alter', value: 'age'},
        );

        if (this.getUseraccount.isNationalJamaatUser || this.getUseraccount.isRegionalJamaatUser || this.getUseraccount.isRegionalLajnaUser || this.getUseraccount.isMissionaryUser) {
          headers.push(
            {text: 'Geb.Datum', value: 'dateOfBirth'},
            {text: 'Name Vater', value: 'fathersName'},
            {text: 'Telefon', value: 'phoneNumber'},
          );
        }

        headers.push(
          {text: 'Aktionen', value: 'dateOfBirth'}
        );
      }

      return headers;
    }

    loadTajneed(fromButton: boolean = false) {
      this.loadingTajneed = true;
      if (this.getTajneed.length == 0 || fromButton) {
        //We dont have the tajneed in the store yt

        //@ts-ignore
        TajneedService.getTajneed(
          this.fullnameFilterValue,
          this.fullnameFilterValue,
          this.aimsIdFilterValue,
          this.waqfenauIdFilterValue,
          this.personFormularIdFilterValue,
          this.jamaatFilterValue,
          this.regionFilterValue,
          this.lajnaRegionFilterValue,
          this.majlisFilterValue,
          this.schoolTypeFilterValue,
          this.studentOrGraduateFilterValue,
          this.courseOfStudyFilterValue,
          this.trainingTypeFilterValue,
          this.mainFocusFilterValue,
          this.subjectAreaFilterValue,
          this.ageComparatorFilterValue,
          this.ageFilterValue,
          this.zusageFilterValue
        ).then((response: any) => {

          //We only want the latest delete request to be seen:
          for (var i = 0; i < response.data.length; i++) {
            let maxId = 0;
            let maxIdIndex = 0;
            if(response.data[i].deleteTajneedRequestSet) {
              for (var j = 0; j < response.data[i].deleteTajneedRequestSet.length; j++) {
              if (response.data[i].deleteTajneedRequestSet[j].id > maxId) {
                maxId = response.data[i].deleteTajneedRequestSet[j].id;
                maxIdIndex = j;
              }
            }

            let results = response.data[i].deleteTajneedRequestSet.splice(maxIdIndex, 1);
            response.data[i].deleteTajneedRequestSet = results;
            }
          }
          this.SET_TAJNEED(response.data);
          this.tableData = response.data;

          if (this.type == 'waqifin') {
            this.tableData = this.getTajneed.filter(t => t.sex == 'Male')
          }

          else if (this.type == 'waqifat') {
            this.tableData = this.getTajneed.filter(t => t.sex == 'Female')
          }
          this.loadingTajneed = false;
        }).catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: error,
            type: 'error'
          });
          this.loadingTajneed = false;
        }).finally(() => {
          setTimeout(() => {
            this.loadingTajneed = false;
          }, 5000);
        });
      } else {
        // We already have tajneed in store
        this.loadTajneedFormStore();
        this.loadingTajneed = false;
      }
    }

    private loadTajneedFormStore() {
      if (this.type == 'waqifin') {
        this.tableData = this.getTajneed.filter(t => t.sex == 'Male')
      } else if (this.type == 'waqifat') {
        this.tableData = this.getTajneed.filter(t => t.sex == 'Female')
      } else if (this.type == 'all') {
        this.tableData = this.getTajneed;
      }
    }

    private openEdit(item: any) {
      this.selectedTajneedForCoreEdit = JSON.parse(JSON.stringify(item));
      this.editCoreTajneedDataDialogVisible = true;
    }

    private openComment(item: any) {
      this.selectedTajneedForComment = JSON.parse(JSON.stringify(item));
      this.commentDialogVisible = true;
    }

    private openDelete(item: Tajneed) {
      this.selectedTajneedForDelete = JSON.parse(JSON.stringify(item));
      this.deleteTajneedDialogVisible = true;
    }

    handlerDeleteTajneedEntryDialog() {
      this.deleteTajneedDialogVisible = false;
    }

    handlerCommentTajneedEntryDialog() {
      this.commentDialogVisible = false;
    }

    private handleCoreUpdateSuccess(test: Tajneed) {
      store.commit('tajneed/UPDATE_TAJNEED', test);
      this.loadTajneedFormStore();
      this.editCoreTajneedDataDialogVisible = false;
    }

    private handleCreateNewSuccess(test: Tajneed) {
      store.commit('tajneed/ADD_TAJNEED', test);
      this.loadTajneedFormStore();
      this.createNewTajneedDialogVisible = false;
    }


    formatDate(date: any) {
      if (!date) {
        return null;
      }
      const [year, month, day] = date.split('-');

      return `${day}.${month}.${year}`;
    }


    customRowClass(item: Tajneed) {
      /* if (item.status === 'NEW_TAJNEED_REQUEST') {
        return 'blue-bg';
      }
      if (item.status === 'NEW_TAJNEED_APPROVED' || item.status === 'SHIFT_APPROVED') {
        return 'green-bg';
      }
      if (item.status === 'DELETE_REQUESTED' || item.status === 'SHIFT_REQUESTED') {
        return 'yellow-bg';
      }
      if (item.status === 'NEW_TAJNEED_DECLINED' || item.status === 'DELETE_TAJNEED_DECLINED') {
        return 'red-bg';
      } else {
        return 'normal';
      } */


      if (item.status === 'NEW_TAJNEED_REQUEST') {
        return 'grey-bg';
      }
      else if (item.status === 'DELETE_REQUESTED' || item.status === 'SHIFT_REQUESTED') {
        return 'blue-bg';
      }
      else if (item.tajneedUpdated) {
        return 'green-bg';
      }
      else if (item.grandfathersName) {
        return 'yellow-bg';
      }
      else if (item.activated) {
        return 'orange-bg';
      }
      else {
        return 'red-bg';
      }
    }

    /* dateOfBirthFilter(valueInRow: any) {
      let valid = false;
      if ((!this.dateOfBirthStartFilterValue && !this.dateOfBirthEndFilterValue)) {
        return true;
      } else {
        let dateOfBirthInRow = moment(valueInRow, 'DD.MM.YYYY');
        let validStart = false;
        let validEnd = false;
        if (this.dateOfBirthStartFilterValue) {
          console.log("firstIf");
          //@ts-ignore
          let dateOfBirthStart = moment(this.dateOfBirthStartFilterValue, "YYYY-MM-DD").subtract(1, 'd');

          console.log(dateOfBirthStart);
          console.log(dateOfBirthInRow);

          validStart = dateOfBirthInRow.isAfter(dateOfBirthStart);
        }

        if (this.dateOfBirthEndFilterValue) {
          console.log("secondIf");
          console.log(this.dateOfBirthStartFilterValue);
          //@ts-ignore
          let dateOfBirthEnd = moment(this.dateOfBirthEndFilterValue, "YYYY-MM-DD").add(1, 'd');

          validEnd = dateOfBirthInRow.isBefore(dateOfBirthEnd);
        }

        if (this.dateOfBirthStartFilterValue && this.dateOfBirthEndFilterValue) {
          return (validStart && validEnd)
        }

        if (this.dateOfBirthStartFilterValue) {
          return (validStart)
        }

        if (this.dateOfBirthEndFilterValue) {
          return (validEnd)
        }
      }
    } */

    private fullnameFilter(valueInRow: any) {
      if (!this.fullnameFilterValue) {
        return true;
      }
      else if (!valueInRow) {
        return false;
      }
      else {
        return valueInRow.toString().toUpperCase().indexOf(this.fullnameFilterValue.toUpperCase()) >= 0;
      }
    }

    private aimsIdFilter(valueInRow: any) {
      if (!this.aimsIdFilterValue) {
        return true;
      }
      else if (!valueInRow) {
        return false;
      }
      else {
        //return valueInRow.toString().indexOf(this.aimsIdFilterValue) >= 0;
        return valueInRow.toString().startsWith(this.aimsIdFilterValue);
      }
    }

    private waqfenauIdFilter(valueInRow: any) {
      if (!this.waqfenauIdFilterValue) {
        return true;
      }
      else if (!valueInRow) {
        return false;
      }
      else {
        return valueInRow.toString().startsWith(this.waqfenauIdFilterValue);
      }
    }

    private personFormularIdFilter(valueInRow: any) {
      if (!this.personFormularIdFilterValue) {
        return true;
      }
      else if (!valueInRow) {
        return false;
      }
      else {
        return valueInRow.toString().toUpperCase().startsWith(this.personFormularIdFilterValue.toUpperCase());
      }
    }

    private jamaatFilter(valueInRow: any) {
      if (!this.jamaatFilterValue) {
        return true;
      }
      else if (!valueInRow) {
        return false;
      }
      else {
        return valueInRow.toString().toUpperCase().indexOf(this.jamaatFilterValue.toUpperCase()) >= 0;
      }
    }

    private majlisFilter(valueInRow: any) {
      if (!this.majlisFilterValue) {
        return true;
      }
      else if (!valueInRow) {
        return false;
      }
      else {
        return valueInRow.toString().toUpperCase().indexOf(this.majlisFilterValue.toUpperCase()) >= 0;
      }
    }

    private regionFilter(valueInRow: any) {
      if (!this.regionFilterValue) {
        return true;
      }
      else if (!valueInRow) {
        return false;
      }
      else {
        return valueInRow.toString().toUpperCase().indexOf(this.regionFilterValue.toUpperCase()) >= 0;
      }
    }


    showNewTajneedEntryDialog() {
      this.$router.push({name: 'TajneedForm'});
    }

    openTajneed(selectedEntry: any) {
      if (!this.getUseraccount.isMissionaryUser) {
        this.$router.push({path: '/dashboard/menue/' + selectedEntry.id});
      }
    }

    private openCreateNewTajneedDialog() {
      this.createNewTajneedDialogVisible = true;
    }

    private saveOfficeHolderDataSuccess() {
      this.officeHolderDataDialogVisible = false;
    }
  }
