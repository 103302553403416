
import { Component, Prop, Vue } from 'vue-property-decorator';

import AppCard from './cards/AppCard.vue';

@Component({
  components: {AppCard },
})
export default class AppLoader extends Vue {
  @Prop({ default: '', required: false })
  icon!: string;

  @Prop({ default: '', required: false })
  text!: string;
}

