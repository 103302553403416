
import {Component, Vue} from 'vue-property-decorator';

import AppCard from '../../../core/components/cards/AppCard.vue';
import {ChildAddRequest} from '../useraccount.types';
import {UseraccountService} from '../useraccount.service'
import {AxiosError, AxiosResponse} from "axios";
import {ServerResponse} from "../../../core/core.types";
import BasedataService from "../../../services/basedata.service"

@Component({
  components: {
    AppCard,
  },
})
export default class ViewAddChild extends Vue {

  private formChildAddRequest = "formChildAddRequest";

  private childAddRequest: ChildAddRequest = {};

  private jamaatItems: any[] = [];

  private majlisItems: any[] = [];

  private showOverlay: boolean = false;

  private daterulesforchildren = [
    (v: string) => !!v || 'Feld muss ausgefüllt sein.',
    (v: string) => /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/.test(v) || 'TT.MM.JJJJ Format',
    (v: string) => v && this.checkIsOlderThenSixteen(v) || 'Die Person ist bereits 16 Jahre alt und sollte seinen eigenen Account haben.',
  ];

  private notempty = [
    (v: string) => !!v || 'Feld muss ausgefüllt sein.',
  ];

  private onlydigitsorempty = [
    (v: string) => /^(\s*|\d+)$/.test(v) || 'Jamaat-ID darf nur Zahlen beinhalten.',
  ];

  private notemptyAndNoSpecialChar = [
    (v: string) => !!v || 'Feld muss ausgefüllt sein',
    (v: string) => /^[0-9][0-9]?[0-9]?[0-9]?[0-9]?[A-Z]$/.test(v) || 'Keine gültige Waqf-e-Nau Nummer',
  ];

  created() {
    BasedataService.getJamaatAndMajalis()
      .then((response: any) => {
        this.jamaatItems = response.data.jamaats;
        this.majlisItems = response.data.majalis;
      })
      .catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: 'Netzwerkfehler',
          type: 'error'
        });
      })
      .finally(() => {
        setTimeout(() => {
        }, 5000);
      });
  }

  private checkIsOlderThenSixteen(value: string) {

    let dateString = value;
    let dateParts = dateString.split(".");
    // month is 0-based, that's why we need dataParts[1] - 1
    let dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);

    let age = this.calculateAge(dateObject);

    if (age >= 16) {
      return false;
    } else {
      return true;
    }
  }

  private calculateAge(birthday: Date) { // birthday is a date
    var ageDifMs = Date.now() - +birthday;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  private requestChildAdd() {
    //@ts-ignore
    if(this.$refs['formChildAddRequest'].validate()){
      this.showOverlay = true;
      UseraccountService.requestChildAdd(this.childAddRequest)
        .then((response: AxiosResponse<ServerResponse>) => {
          this.showOverlay = false;
          this.childAddRequest = {};
          // @ts-ignore
          this.$refs['formChildAddRequest'].resetValidation();
          //@ts-ignore
          this.$notify({group: 'foo', title: 'Info', text: response.data.message, type: 'success'});
        }).catch((error: AxiosError<ServerResponse>) => {
        this.showOverlay = false;
        //@ts-ignore
        this.$notify({group: 'foo', title: 'Fehler', text: error.response!.data.message, type: 'error'});
      }).finally(() => {
        setTimeout(() => {
          this.showOverlay = false;
        }, 5000);
      });
    }
  }


  private complianceTextChild = "Ich willige freiwillig und ausdrücklich ein, " +
    "dass die AMJ die personenbezogenen Daten von meinem Kind (bspw. Name, " +
    "Adresse, Kontaktdaten, Geschlecht, Waqf-e-Nau-, Mitglieds- & ID-Nummer, ggf. " +
    "Wassiyat Nr., Religionsdaten, Nationalität, Gesundheitsdaten, Bildungsdaten, ggf. Photographie) " +
    "zum Zwecke der Verwaltung und Organisation der besonderen 'Waqf-e-Nau' Mitgliedschaft " +
    "unter Beachtung der EU - DSGVO und BDSG verarbeitet. Empfänger dieser Daten sind im " +
    "Rahmen gesetzlicher Pflichten und Mitteilungsbefugnisse Dritte, wie die Sadr Anjuman Ahmadiyya (Rabwah, Pakistan) " +
    "und AMJ International (London, UK).";

  private complianceTextHeader = "Wir als Ahmadiyya Muslim Jamaat Deutschland KdöR (\"AMJ\") brauchen Ihre " +
    "datenschutzrechtliche Einwilligung (über dem 16. Lebensjahr vom Waqfeen, darunter von den Eltern oder Erziehungsberechtigten)" +
    "um Ihre personenbezogenen Daten für das Verwalten und Organisieren der Mitgliedschaft im \"Waqf-e-Nau\" Programms " +
    "verarbeiten zu dürfen (gemäß Artikel 4 Ziff.11, Art. 6 Abs. 1 lit. (a), Art. 7 und Art. 9 Abs. 2 lit. (a) DSGVO, sowie § 51 BDSG). ";

  // language=HTML
  private complianceTextFooter = "Mein Einverständnis kann ich, ohne nachteilige Folgen für mich, verweigern bzw. jederzeit mit zukünftiger Wirkung widerrufen. " +
    " Mir ist bekannt, dass dies nicht die Rechtmäßigkeit der bisher auf der Grundlage der vorherigen Einwilligung erfolgten " +
    "Verarbeitung berührt. Meine Fragen, Anregungen und Rechtsgesuche, wie Löschung, Auskunft, Berichtigung oder Widerruf, bzgl. meiner Daten " +
    "kann ich postalisch an: Datenschutzbeauftragter der AMJ KdöR, Genferstraße 11 in 60437 Frankfurt am Main oder per Email-Nachricht an datenschutz@ahmadiyya.de " +
    "richten. Mehr Informationen zum Datenschutz finden Sie <a target=\"_blank\" href=\"https://waqfenau.ahmadiyya.de/datenschutz\" > hier. </a> "


}
