
import {Component, Vue} from "vue-property-decorator";
import AppLoader from "../../../core/components/AppLoader.vue"
import ReportService from '../report.service';
import moment from 'moment';
import TableButton from '../../../core/components/TableButton.vue';
import TimeSeriesChart from '../components/TimeSeriesChart.vue';

/**
 * Data (Label with values)
 */
export interface Data {
  label: string;
  data: Value[];
}

/**
 * Value
 */
export interface Value {
  x: string;
  y: number;
}

@Component({
  components: {AppLoader, TableButton, TimeSeriesChart}
})
export default class ViewIndividualDevelopment extends Vue {

  private personId: string = "";

  private loadingReports: boolean = false;

  private monthValuesJsonList: any = null;

  private xAxesLabels: string[] = [];

  private maximumReports = 150;

  /**
   * All data
   */
  private allDataTarbiyyat: Data[] = [];

  private allDataKhilafat: Data[] = [];

  /**
   * Data for chart
   */
  private chartDataTarbiyyat: Chart.ChartData = {};

  private chartDataKhilafat: Chart.ChartData = {};

  /**
   * chart style
   */
  private chartStyles = {
    height: "100%",
    width: "100%",
    margin: "auto"
  };

  created() {
    if (!this.$route.params.personId) {
      this.$router.push({path: '/'});
    } else {
      this.personId = this.$route.params.personId;
      this.loadReports();

      for(let i = 11; i > 0; i--) {
        this.xAxesLabels.push(this.getMonthForNumber(moment().subtract(i, 'M').month()));
      }
    }
  }

  private start() {
    this.allDataTarbiyyat = [];
    this.allDataKhilafat = [];
    // Initialize 30 data
    // How many lines should be there?

    this.allDataTarbiyyat.push({label: "Regelmäßig gebetet", data: []});
    this.allDataTarbiyyat.push({label: "Regelmäßig in Gemeinschaft gebetet", data: []});
    this.allDataTarbiyyat.push({label: "Regelmäßig in NC/Moschee gebetet", data: []});
    this.allDataTarbiyyat.push({label: "Quran regelmäßig rezitiert", data: []});
    this.allDataTarbiyyat.push({label: "An allen Freitagsgebeten teilgenommen", data: []});
    this.allDataTarbiyyat.push({label: "Alle Freitagsansprachen von Huzoor gehört", data: []});

    this.allDataKhilafat.push({label: "Nawafil für Khilafat regelmäßig verrichtet", data: []});
    this.allDataKhilafat.push({label: "Khilafat Gebete regelmäßig rezitiert", data: []});
    this.allDataKhilafat.push({label: "Wöchentlich gefastet ", data: []});

    this.updateDatasets();
  }

  /**
   * Update datasets
   */
  private updateDatasets() {

    // if we dont this, then the graphs will be jumping in months
    this.monthValuesJsonList = this.monthValuesJsonList.sort(function (a:any, b:any) {
      //@ts-ignore
      if (moment("01-" + b.month + "-" + b.year, "dd-MM-yyyy").isAfter(moment("01-" + a.month + "-" + a.year, "dd-MM-yyyy"))) return -1;
      if (moment("01-" + b.month + "-" + b.year, "dd-MM-yyyy").isBefore(moment("01-" + a.month + "-" + a.year, "dd-MM-yyyy"))) return 1;
      return 0;
    });

    console.log(this.monthValuesJsonList);

    // for each line
    this.monthValuesJsonList.forEach((td:any) => {

      if(!(+td.month == moment().month() +1 && +td.year == moment().year())) {

        this.allDataTarbiyyat[0].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.prayersRegularObserved
        });

        this.allDataTarbiyyat[1].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.prayersObservedInCongregation
        });

        this.allDataTarbiyyat[2].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.prayersObservedInNcMosque
        });

        this.allDataTarbiyyat[3].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.daysQuranRecited
        });

        this.allDataTarbiyyat[4].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.fridayPrayersAttended
        });

        this.allDataTarbiyyat[5].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.fridaySermonsListened
        });

        this.allDataKhilafat[0].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.daysKhilafatNawafilObserved
        });

        this.allDataKhilafat[1].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.daysKhilafatPrayersRecited
        });

        this.allDataKhilafat[2].data.push({
          x: this.getMonthForNumber(td.month - 1),
          y: td.daysFasted
        });
      }
    });

    const dataset = (labelData: Data) => ({
      label: labelData.label,
      data: labelData.data,
      radius: 5
    });

    this.chartDataTarbiyyat = {
      datasets: this.allDataTarbiyyat.map(label => dataset(label))
    };

    this.chartDataKhilafat = {
      datasets: this.allDataKhilafat.map(label => dataset(label))
    };
  }

  private reloadReports() {
    this.loadReports();
  }

  loadReports() {
    this.loadingReports = true;
      //@ts-ignore
      ReportService.getIndividualDevelopment(this.personId).then((response: any) => {
        this.loadingReports = false;
        this.monthValuesJsonList = response.data.monthValuesJsonList;
        this.start();
      }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
        this.loadingReports = false;
      }).finally(() => {
        setTimeout(() => {
          this.loadingReports = false;
        }, 5000);
      });

  }

  getMonthForNumber(month: number): string {
    switch (month) {
      case 0:
        return "Jan";
      case 1:
        return "Feb";
      case 2:
        return "Mär";
      case 3:
        return "Apr";
      case 4:
        return "Mai";
      case 5:
        return "Jun";
      case 6:
        return "Jul";
      case 7:
        return "Aug";
      case 8:
        return "Sep";
      case 9:
        return "Okt";
      case 10:
        return "Nov";
      case 11:
        return "Dez";
      default:
        return "";
    }
  }

  getNumberForMonth(month: string): number {
    switch (month) {
      case "Januar":
        return 0;
      case "Februar":
        return 1;
      case "März":
        return 2;
      case "April":
        return 3;
      case "Mai":
        return 4;
      case "Juni":
        return 5;
      case "Juli":
        return 6;
      case "August":
        return 7;
      case "September":
        return 8;
      case "Oktober":
        return 9;
      case "November":
        return 10;
      case "Dezember":
        return 11;
      default:
        return -1;
    }
  }
}
