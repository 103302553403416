
import { Component, Vue } from 'vue-property-decorator';

import AppCard from '../../../core/components/cards/AppCard.vue';
import DashboardRowOwn from '../components/DashboardRowOwn.vue';
import DashboardRowChildren from '../components/DashboardRowChildren.vue';
import DashboardMenuFirstRow from '../components/DashboardMenuFirstRow.vue';
import DashboardMenuSecondRow from '../components/DashboardMenuSecondRow.vue';
import DashboardMenuThirdRow from '../components/DashboardMenuThirdRow.vue';
import DashboardService from '../dashboard.service';
import AppLoader from "../../../core/components/AppLoader.vue"

@Component({
  components: {
    AppCard,
    AppLoader,
    DashboardRowOwn,
    DashboardRowChildren,
    DashboardMenuFirstRow,
    DashboardMenuSecondRow,
    DashboardMenuThirdRow
  }
})
export default class ViewDashboardMenu extends Vue {

  private loadingDashboardData: boolean = false;

  private personId: string = '';
  private tajneedId: string = '';
  private dateOfBirth: string = '';

  created() {
    // If someone has entered URL of /dashboardmenue directly in browser,
    // then redirect to persons overview:
    if (!this.$route.params.tajneedId) {
      this.$router.push({name: 'ViewDashboardPersons'});
    } else {
      this.tajneedId = this.$route.params.tajneedId;
      if (!this.$route.params.personId) {
        this.loadingDashboardData = true;
        // If person id has not been passed through route, we give direct backend call a try for
        // officer holder roles
        DashboardService.getDashobardData(this.$route.params.tajneedId)
          .then((response: any) => {
            this.personId = response.data.personId;
            this.dateOfBirth = response.data.dateOfBirth;
            this.loadingDashboardData = false;
          }).catch((error: any) => {
            this.$notify({
              group: 'foo',
              title: 'Fehler',
              text: error,
              type: 'error'
            });
            this.loadingDashboardData = false;
          }).finally(() => {
            setTimeout(() => {
              this.loadingDashboardData = false;
            }, 5000);
          });
      } else {
        this.personId = this.$route.params.personId;
        this.dateOfBirth = this.$route.params.dateOfBirth;
      }
    }
  }

}
