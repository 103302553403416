
  import {Component, Prop, Vue, Watch} from "vue-property-decorator";
  import {Tajneed} from "../../tajneed.types";
  import {Getter} from "vuex-class";
  import {Useraccount} from "../../../../models/useraccount.model";
  import moment from 'moment';

  @Component({
    components: {},
  })
  export default class DateOfBirthTextField extends Vue {

    @Prop({default: null})
    tajneed: Tajneed;

    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    @Prop({default: ""})
    dateOfBirth: any;

    public locDateOfBirth = "";

    daterules = [
      (v: string) => !!v || 'Feld muss ausgefüllt sein',
      (v: string) => /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/.test(v) || 'TT.MM.JJJJ Format',
    ];

    created() {
      //We do this local copy, because otherwise at every change of textfield parent component would be rerendered
      this.locDateOfBirth = this.dateOfBirth;
    }

    //We need this, cause in edit mode the dateOfBirth on parent is set after dateOfBirthTextField.vue is rendered
    @Watch('dateOfBirth')
    onPropertyChanged(value: string, oldValue: string) {
      this.locDateOfBirth = this.dateOfBirth;
    }

    @Watch('locDateOfBirth')
    onPropertyChanged1(value: string, oldValue: string) {
      //For new tajneed entry check for null, because field is empty:
      if (value != null) {
        if (value.match(/^([1-9]|0[1-9]|[12][0-9]|3[01])[-\.]([1-9]|0[1-9]|1[012])[-\.](19|20)\d\d$/)) {
          let dateString = value;
          let dateParts = dateString.split(".");
          // month is 0-based, that's why we need dataParts[1] - 1
          let dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);

          let age = this.calculateAge(dateObject);

          if (age >= 7) {
            this.$emit('update:showEducationPage', true);
          } else {
            this.$emit('update:showEducationPage', false);
          }

          if (age >= 15) {
            this.$emit('update:showEmail', true);
            this.$emit('update:showAddInfoAndConfirmPage', true);

          } else {
            this.$emit('update:showEmail', false);
            this.$emit('update:showAddInfoAndConfirmPage', false);
          }

          if (age >= 18) {
            this.$emit('update:showMaritalStatus', true);
          } else {
            this.$emit('update:showMaritalStatus', false);
          }

          /* if (this.tajneed.sex === 'Male') {
            let year = moment().year();
            let month = 11;
            let day = 1;
            if (age == 15) {
              if (new Date() < new Date(year, month, day)) {
                this.tajneed.tanzeem = "Atfal";
              } else {
                this.tajneed.tanzeem = "Khuddam";
              }
            } else if (age > 15) {
              this.tajneed.tanzeem = "Khuddam";
            } else if (age >= 7) {
              this.tajneed.tanzeem = "Atfal";
            } else {
              this.tajneed.tanzeem = "";
            }
          }

          if (this.tajneed.sex === 'Female') {
            if (age >= 7 && age < 15) {
              this.tajneed.tanzeem = "Nasirat";
            }
            else if (age >= 15) {
              this.tajneed.tanzeem = "Lajna";
            } else {
              this.tajneed.tanzeem = "";
            }
          } */
        }
      }
    }

    calculateAge(birthday: Date) { // birthday is a date
      var ageDifMs = Date.now() - +birthday;
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    get getDateOfBirth(): string {
      //@ts-ignore
      return this.locDateOfBirth
    }


  }
