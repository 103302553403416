
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import AppLoader from "../../../core/components/AppLoader.vue"
import {JamaatHalqa, RegionLocalAmarat} from "../basedata.types";
import BasedataService from "../basedata.service"
import AppCard from '../../../core/components/cards/AppCard.vue';
import CreateNewRegionLaDialog from "../components/CreateNewRegionLaDialog.vue";
import ConfirmRegionLaDeleteDialog from "../components/ConfirmRegionLaDeleteDialog.vue";
import CreateNewJamaatHalqaDialog from "../components/CreateNewJamaatHalqaDialog.vue";
import ConfirmJamaatHalqaDeleteDialog from "../components/ConfirmJamaatHalqaDeleteDialog.vue";

@Component({
  components: {
    ConfirmJamaatHalqaDeleteDialog,
    CreateNewJamaatHalqaDialog,
    ConfirmRegionLaDeleteDialog,
    CreateNewRegionLaDialog,
    AppCard,
    AppLoader
  }
})
export default class ViewJamaats extends Vue {

  private jamaatsTableData: JamaatHalqa[] = [];
  private regionsAndLocalAmaratsTableData: RegionLocalAmarat[] = [];

  private passwordResettedId: string = '';
  private passwordResettedType: string = '';
  private resettedPassword: string = '';

  @Prop({required: false, default: false})
  loadJamaats!: boolean;

  private jamaatsTableHeaders = [
    {text: 'ID', value: 'id'},
    {text: 'Jamaat', value: 'name'},
    {text: 'Username', value: 'username'},
    {text: 'Region / LA', value: 'regionLocalAmarat'},
    {text: 'Löschen', value: 'delete'},
    {text: 'Passwort zurücksetzen', value: 'resetPassword'},
  ];

  private regionsAndLocalAmaratsTableHeaders = [
    {text: 'ID', value: 'id'},
    {text: 'Region / LA', value: 'name'},
    {text: 'Username', value: 'username'},
    {text: 'Löschen', value: 'delete'},
    {text: 'Passwort zurücksetzen', value: 'resetPassword'},
  ];

  private allRegions: string[] = [];

  private createNewRegionDialogVisible: boolean = false;
  private confirmDeleteRegionLaDialogVisible: boolean = false;

  private regionLaToBeDeleted: RegionLocalAmarat | null = null;

  private createNewJamaatHalqaDialogVisible: boolean = false;

  private jamaatHalqaToBeDeleted: JamaatHalqa | null = null;
  private confirmDeleteJamaatHalqaDialogVisible: boolean = false;

  private jamaatHalqaToBeResetted: JamaatHalqa | null = null;

  private regionLocalAmaratToBeResetted: RegionLocalAmarat | null = null;

  @Watch('loadJamaats')
  onChange(value: boolean) {
    if (value) {
      this.loadRegions();
    }
  }

  created() {
    this.loadRegions();
  }

  loadRegions() {
    //@ts-ignore
    BasedataService.getJamaatsAndRegions().then((response: any) => {
      this.jamaatsTableData = response.data.jamaats;
      this.regionsAndLocalAmaratsTableData = response.data.regions;
      this.allRegions = response.data.regions.map((a: RegionLocalAmarat) => a.name);
      this.$emit('jamaats-loaded');
    }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error,
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
        this.$emit('jamaats-loaded');
      }, 5000);
    });
  }

  private saveNameChange(item: JamaatHalqa) {
    BasedataService.changeJamaatName(item)
        .then((response: any) => {
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: "Gespeichert",
            type: 'success'
          });
          // Update username
          var jh = this.jamaatsTableData.filter(j => 
            j.id == response.data.id
          );
          jh[0].username = response.data.username;
        }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: '',
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

  private saveRegionNameChange(item: RegionLocalAmarat) {
    BasedataService.changeRegionName(item)
        .then((response: any) => {
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: "Gespeichert",
            type: 'success'
          });
          /* // Update username
          var r = this.regionsAndLocalAmaratsTableData.filter(r => 
            r.id == response.data.id
          );
          r[0].username = response.data.username; */
          this.loadRegions();
        }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: '',
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

  private changeRegionForJamaat(item: JamaatHalqa) {
    BasedataService.changeRegionForJamaat(item)
        .then((response: any) => {
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: "Gespeichert",
            type: 'success'
          });
        }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: '',
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

  private createNewRegion() {
    this.createNewRegionDialogVisible = true;
  }

  private createNewJamaatHalqa() {
    this.createNewJamaatHalqaDialogVisible = true;
  }

  private handleCloseCreateNewJamaatHalqaDialog() {
    this.createNewJamaatHalqaDialogVisible = false;
    this.loadRegions();
  }

  private deleteJamaatHalqa(item: JamaatHalqa) {
    this.jamaatHalqaToBeDeleted = item;
    this.confirmDeleteJamaatHalqaDialogVisible = true;
  }

  private resetJamaatPassword(item: JamaatHalqa) {
    this.resetPassword(item.id.toString(), "jamaat");
  }

  private resetJamaatRegionPassword(item: RegionLocalAmarat) {
    this.resetPassword(item.id.toString(), "regionLocalAmarat")
  }

  private resetPassword(organizationId: string, type: string) {
    BasedataService.resetPassword(organizationId, type)
        .then((response: any) => {
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: "Erfolreich geändert",
            type: 'success'
          });
          this.passwordResettedId = organizationId;
          this.passwordResettedType = type;
          this.resettedPassword = response.data;
        }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error.response!.data.message,
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

  private handleCloseCreateNewRegionDialog() {
    this.createNewRegionDialogVisible = false;
    this.loadRegions();
  }

  private deleteRegionLa(item: RegionLocalAmarat) {
    this.regionLaToBeDeleted = item;
    this.confirmDeleteRegionLaDialogVisible = true;
  }

  private handleCloseDeleteRegionDialog() {
    this.confirmDeleteRegionLaDialogVisible = false;
    this.loadRegions();
  }

  private handleCloseDeleteJamaatHalqaDialog() {
    this.confirmDeleteJamaatHalqaDialogVisible = false;
    this.loadRegions();
  }

}
