
  import { Component, Mixins, Vue } from 'vue-property-decorator';
  import FormChangeUsername from "./FormChangeUsername.vue";

  @Component({
    components: {FormChangeUsername },
  })
  export default class ExpansionPanelChangeUsername extends Vue{

  }

