
import {
  Component, Vue,
} from 'vue-property-decorator';

import AppCard from './cards/AppCard.vue';

@Component({
  components: {
    AppCard,
  },
})
export default class DashboardRow extends Vue {}

