
  import {Component, Prop, Vue} from 'vue-property-decorator';

  import AppCard from '../../../core/components/cards/AppCard.vue';
  import DownloadTileNisab from './DownloadTileNisab.vue';
  import DownloadTileUrdu from './DownloadTileUrdu.vue';
  import DashboardCol from '../../../core/components/DashboardCol.vue';
  import DashboardRow from '../../../core/components/DashboardRow.vue';

  @Component({
    components: {
      DownloadTileNisab,
      DownloadTileUrdu,
      AppCard,
      DashboardRow,
      DashboardCol
    },
  })
  export default class DashboardDownloadsRow extends Vue {

  }
