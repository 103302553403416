
  import {Component, Prop, Vue} from "vue-property-decorator";
  import BasedataService from '../basedata.service';
  import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
  import {RegionLocalAmarat} from "../basedata.types";

  @Component({
    components: {LoadingOverlay},
  })
  export default class ConfirmRegionLaDeleteDialog extends Vue {

    @Prop({ default: null, required: true })
    regionLa!: RegionLocalAmarat;

    private showOverlay = false;

    deleteRegionLa() {
      this.showOverlay = true;
      BasedataService.deleteRegionLa(this.regionLa.id)
        .then((response: any) => {
          this.showOverlay = false;
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: 'Region / LA wurde erfolgreich gelöscht',
            type: 'success'
          });
          this.$emit('success');
        })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text:  error.response!.data.message,
            type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
    }

  }
