
  import {Component, Prop, Vue} from "vue-property-decorator";
  import {UseraccountService} from '../../../useraccount/useraccount.service';
  import LoadingOverlay from "../../../../core/components/LoadingOverlay.vue"

  @Component({
    components: {LoadingOverlay},
  })
  export default class  ChangeUsernameDialog extends Vue {

    @Prop({default: null})
    selectedAccount: any;

    private emailRules = [
      (v: string) => !!v || 'Feld muss ausgefüllt sein',
      (v: any) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-Mail überprüfen',
    ]

    private showOverlay = false;

    private email: string = '';

    deleteAccount() {
      this.showOverlay = true;
      UseraccountService.changeAccountUsername(this.selectedAccount.id, this.email)
        .then((response: any) => {
          this.showOverlay = false;
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: "Username erfolgreich geändert",
            type: 'success'
          });
          this.$emit('success');
        })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: error.response!.data.message,
            type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
    }

  }
