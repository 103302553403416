
  import {Component, Prop, Vue} from "vue-property-decorator";
  import StaticData from "../../../../../staticdata/staticdata"
  import TajneedService from "../../../tajneed.service"
  import LoadingOverlay from "../../../../../core/components/LoadingOverlay.vue"
  import {DeleteTajneedRequest, Tajneed} from '../../../tajneed.types';
  import Constants from "../../../../../staticdata/constants";
  import BasedataService from "../../../../../services/basedata.service"
  import {JamaatHalqa, LajnaMajlis} from "../../../../basedata/basedata.types";

  @Component({
    components: {LoadingOverlay},
  })
  export default class DeleteTajneedEntryDialog extends Vue {

    @Prop({default: null})
    selectedTajneed: Tajneed;

    private dtr: DeleteTajneedRequest = {};

    //@ts-ignore
    private Constants: Constants = Constants;

    private jamaatItems : string[] = [];

    private majlisItems : string[] = [];

    private jamaat: string = '';

    private majlis: string = '';

    private landItems = StaticData.getAllCountries();

    private showOverlay = false;

    private notempty = [
      (v: string) => !!v || 'Feld muss ausgefüllt sein'
    ];

    created(){

      BasedataService.getJamaatAndMajalis()
        .then((response: any) => {
          this.jamaatItems = response.data.jamaats;
          this.majlisItems = response.data.majalis;
        })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: 'Netzwerkfehler',
            type: 'error'
          });
        })
        .finally(() => {
          setTimeout(() => {
          }, 5000);
        });
    }

    deleteTajneed() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        if(this.selectedTajneed.sex == 'Male'){
          this.dtr.newJamaat = this.jamaat;
        }
        if(this.selectedTajneed.sex == 'Female'){
          this.dtr.newMajlis = this.majlis;
        }

        let data = {
          //@ts-ignore
          selectedTajneedId: +this.selectedTajneed.id,
          deleteTajneedRequest: this.dtr
        };

        this.showOverlay = true;
        TajneedService.deleteTajneed(data)
          .then((response: any) => {
              this.showOverlay = false;
              this.$notify({
                group: 'foo', title: 'Info', text: response.data, type: 'success'
              });
              (this.$refs.form as Vue & { reset: () => boolean }).reset();
              this.$emit('event', "");
            })
          .catch((error: any) => {
            this.$notify({
              group: 'foo', title: 'Fehler', text: error.response!.data.message, type: 'error'
            });
            this.showOverlay = false;
          })
          .finally(() => {
            setTimeout(() => {
              this.showOverlay = false;
            }, 5000);
          });
      }
    }

    handleJamaatChange(selection: any){
      /* for (var i =0; i< this.allJamaats.length; i++) {
        if(this.allJamaats[i].id===selection.value){
          this.dtr.newRegion = this.allJamaats[i].regionLa;
        }
      } */
    }

    handleMajlisChange(selection: any){
      /* for (var i =0; i< this.allMajalis.length; i++) {
        if(this.allMajalis[i].id===selection.value){
          this.dtr.newRegion = this.allMajalis[i].regionLajna;
        }
      } */
    }
  }
