
  import {Component, Prop, Vue, Watch} from "vue-property-decorator";
  import studiengaengeundfachrichtungen from '../../../../staticdata/studiengaengeundfachrichtungen.json';
  import ausbildungenundfachrichtungen from '../../../../staticdata/ausbildungenundfachrichtungen.json';

  import studiengaenge from '../../../../staticdata/studiengaenge.json';
  import berufe from '../../../../staticdata/berufe.json';
  import StaticData from "../../../../staticdata/staticdata"
  import {AdditionalQualification, Ausbildung, Education, Tajneed} from '../../tajneed.types';
  import {Mutation, Getter} from "vuex-class";
  import {store} from "../../../../store";
  import {Useraccount} from "../../../../models/useraccount.model";

  @Component({
    components: {},
  })
  export default class EducationForm extends Vue {

    @Mutation('SET_STUDENTORGRADUATE', {namespace: 'tajneed'})
    SET_STUDENTORGRADUATE!: (studentOrGraduate: string) => void;

    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    @Prop({default: null})
    tajneed: Tajneed;

    @Prop({required: false, default: false})
    deleteRequested!: boolean;

    @Prop({default: []})
    allAdditionalQualifications: AdditionalQualification[];

    private locTajneed: Tajneed = {};

    private uniItems = StaticData.getAllUnis();
    private courseOfStudyItems: string[] = [];
    private mainFocusItems: string[] = [];
    private subjectAreaItems: string[] = [];
    private schoolTypeItems = StaticData.getAllSchulformen();

    private trainingTypeItems: string[] = [];

    private ausbildungen: Ausbildung[] = [];
    private trainingSubjectAreaItems: string[] = [];

    private permissionForTraining: string = "";

    private additionalQualificationsTrainingItems: string[] = [];
    private additionalQualificationsTrainingSelected: string[] = [];

    private pursuedDegreeItems: string[] = StaticData.getAllUniAbschluesse();

    private additionalQualificationsStudyItems: string[] = [];
    private additionalQualificationsStudySelected: string[] = [];

    private berufItems: string[] = [];

    private allUniqueFachbereiche: string[] = StaticData.getAllUniqueFachbereiche();

    private additionalQualificationsEmploymentItems: string[] = [];
    private additionalQualificationsEmploymentSelected: string[] = [];

    private search = "";

    max = 50;

    notempty = [
      (v: string) => !!v || 'Feld muss ausgefüllt sein'
    ];

    created() {
      this.courseOfStudyItems = studiengaenge;
      this.ausbildungen = ausbildungenundfachrichtungen;
      this.berufItems = berufe;
      this.trainingTypeItems = this.ausbildungen.map(a => a.ausbildungsart);
      this.locTajneed = JSON.parse(JSON.stringify(this.tajneed));
      if(!this.locTajneed.education) {
        this.locTajneed.education = {};
      } else {
        //store.commit('tajneed/SET_BILDUNGBESCHAEFTIGUNG', this.locTajneed.education.bildungBeschaeftigung);
        //this.SET_BILDUNGBESCHAEFTIGUNG(this.locTajneed.education.bildungBeschaeftigung);
      }

      //We have to do this in order to load the selection items for schwerpunkt and fachbereich
      //@ts-ignore
      this.handleStudiengangChange(this.locTajneed.education.courseOfStudy);
      //@ts-ignore
      this.handleAusbildungsartChange(this.locTajneed.education.trainingType);
      //@ts-ignore
      if(this.locTajneed.education.additionalQualificationsTraining) {
        //@ts-ignore
        this.additionalQualificationsTrainingSelected = this.locTajneed.education.additionalQualificationsTraining.map(q => q.name);
      } else {
        //@ts-ignore
        this.locTajneed.education.additionalQualificationsTraining = [];
      }
      //@ts-ignore
      this.additionalQualificationsTrainingItems = this.allAdditionalQualifications.filter(q => q.type == 'Ausbildung' && q.sex == this.locTajneed.sex).map(q => q.name);
      //@ts-ignore
      if(this.locTajneed.education.additionalQualificationsStudy){
        //@ts-ignore
        this.additionalQualificationsStudySelected = this.locTajneed.education.additionalQualificationsStudy.map(q => q.name);
      } else {
        //@ts-ignore
        this.locTajneed.education.additionalQualificationsStudy = [];
      }
      //@ts-ignore
      this.additionalQualificationsStudyItems = this.allAdditionalQualifications.filter(q => q.type == 'Studium' && q.sex == this.locTajneed.sex).map(q => q.name);
      //@ts-ignore
      if(this.locTajneed.education.additionalQualificationsEmployment){
        //@ts-ignore
        this.additionalQualificationsEmploymentSelected = this.locTajneed.education.additionalQualificationsEmployment.map(q => q.name);
      } else {
        //@ts-ignore
        this.locTajneed.education.additionalQualificationsEmployment = [];
      }
      // Here we dont filter for type because these people can see all
      //@ts-ignore
      this.additionalQualificationsEmploymentItems = this.allAdditionalQualifications.filter(q =>q.sex == this.locTajneed.sex).map(q => q.name);
    }

    //We need this, cause in edit mode the dateOfBirth on parent is set after dateOfBirthTextField.vue is rendered
    @Watch('tajneed')
    onPropertyChanged(value: string, oldValue: string) {
      this.locTajneed = JSON.parse(JSON.stringify(this.tajneed));

      //We have to do this in order to load the selection items for schwerpunkt and fachbereich
      //@ts-ignore
      //this.handleStudiengangChange(this.locTajneed.education.studiengang);

    }

    @Watch('additionalQualificationsTrainingSelected')
    onAdditionalQualificationsTrainingSelectedChange(value: string[], oldValue: string[]) {
      //@ts-ignore
      if (this.locTajneed.education.educationalInstitution == 'Ausbildung') {
        value.forEach((q: string) => {
          //Check if element from input field is already in locTajneed
          //@ts-ignore
          if (this.locTajneed.education.additionalQualificationsTraining.filter(qLocTajneed => qLocTajneed.name == q && qLocTajneed.type == 'Ausbildung').length <= 0) {
            let qualiAlreadyInAllList = this.allAdditionalQualifications.filter(q2 => q2.name == q && q2.type == 'Ausbildung' && q2.sex == this.locTajneed.sex);
            let id = null;
            let createDate: any = null;
            if (qualiAlreadyInAllList.length > 0) {
              id = qualiAlreadyInAllList[0].id;
              createDate = qualiAlreadyInAllList[0].createDate;
            }
            //@ts-ignore
            this.locTajneed.education.additionalQualificationsTraining.push({
              id: id,
              createDate: createDate,
              name: q,
              type: 'Ausbildung',
              sex: this.locTajneed.sex
            });
          }
        });
        // If element has been removed
        //@ts-ignore
        for (var i = 0; i < this.locTajneed.education.additionalQualificationsTraining.length; i++) {
          //@ts-ignore
          if (value.filter(q => q == this.locTajneed.education.additionalQualificationsTraining[i].name) <= 0) {
            //@ts-ignore
            this.locTajneed.education.additionalQualificationsTraining.splice(i, 1);
            i++;
          }
        }
      }
    }

    @Watch('additionalQualificationsStudySelected')
    onAdditionalQualificationsStudySelectedChange(value: string[], oldValue: string[]) {
      //@ts-ignore
      if (this.locTajneed.education.educationalInstitution == 'Universität') {
        value.forEach((q: string) => {
          //Check if element from input field is already in locTajneed
          //@ts-ignore
          if (this.locTajneed.education.additionalQualificationsStudy.filter(qLocTajneed => qLocTajneed.name == q && qLocTajneed.type == 'Studium').length <= 0) {
            let qualiAlreadyInAllList = this.allAdditionalQualifications.filter(q2 => q2.name == q && q2.type == 'Studium' && q2.sex == this.locTajneed.sex);
            let id = null;
            let createDate: any = null;
            if (qualiAlreadyInAllList.length > 0) {
              id = qualiAlreadyInAllList[0].id;
              createDate = qualiAlreadyInAllList[0].createDate;
            }
            //@ts-ignore
            this.locTajneed.education.additionalQualificationsStudy.push({
              id: id,
              createDate: createDate,
              name: q,
              type: 'Studium',
              sex: this.locTajneed.sex
            });
          }
        });
        // If element has been removed
        //@ts-ignore
        for (var i = 0; i < this.locTajneed.education.additionalQualificationsStudy.length; i++) {
          //@ts-ignore
          if (value.filter(q => q == this.locTajneed.education.additionalQualificationsStudy[i].name) <= 0) {
            //@ts-ignore
            this.locTajneed.education.additionalQualificationsStudy.splice(i, 1);
            i++;
          }
        }
      }
    }

    @Watch('additionalQualificationsEmploymentSelected')
    onAdditionalQualificationsEmploymentSelectedChange(value: string[], oldValue: string[]) {
      //@ts-ignore
      if (this.locTajneed.education.studentOrGraduate == 'Absolvent') {
        value.forEach((q: string) => {
          //Check if element from input field is already in locTajneed
          //@ts-ignore
          if (this.locTajneed.education.additionalQualificationsEmployment.filter(qLocTajneed => qLocTajneed.name == q).length <= 0) {
            let qualiAlreadyInAllList = this.allAdditionalQualifications.filter(q2 => q2.name == q && q2.sex == this.locTajneed.sex);
            let id = null;
            let createDate: any = null;
            let type: any = 'Beschäftigung';
            if (qualiAlreadyInAllList.length > 0) {
              id = qualiAlreadyInAllList[0].id;
              createDate = qualiAlreadyInAllList[0].createDate;
              type = qualiAlreadyInAllList[0].type;
            }
            //@ts-ignore
            this.locTajneed.education.additionalQualificationsEmployment.push({
              id: id,
              createDate: createDate,
              name: q,
              // For simplicty we just go for Ausbildung here, but it actually does not matter
              type: type,
              sex: this.locTajneed.sex
            });
          }
        });
        // If element has been removed
        //@ts-ignore
        for (var i = 0; i < this.locTajneed.education.additionalQualificationsEmployment.length; i++) {
          //@ts-ignore
          if (value.filter(q => q == this.locTajneed.education.additionalQualificationsEmployment[i].name) <= 0) {
            //@ts-ignore
            this.locTajneed.education.additionalQualificationsEmployment.splice(i, 1);
            i++;
          }
        }
      }
    }

    @Watch('locTajneed.education.studentOrGraduate')
    onStudentOrGraduateChange(value: string, oldValue: string) {
      store.commit('tajneed/SET_STUDENTORGRADUATE', value);
      //this.SET_BILDUNGBESCHAEFTIGUNG(value);
    }

    private handleStudiengangChange(selection: string) {

      let allEntriesForCourseOfStudies = studiengaengeundfachrichtungen.filter(s => s.studiengang == selection);

      if (allEntriesForCourseOfStudies.length > 0) {
        this.mainFocusItems = (allEntriesForCourseOfStudies.map(s => s.schwerpunkt));
        //@ts-ignore
        this.locTajneed.education.mainFocus = this.mainFocusItems[0];

        this.subjectAreaItems = (allEntriesForCourseOfStudies.map(s => s.fachbereich));
        //@ts-ignore
        this.locTajneed.education.studySubjectArea = this.subjectAreaItems[0];
      } else {
        // Individual value has been entered:
        this.mainFocusItems = studiengaengeundfachrichtungen.map(s => s.schwerpunkt)
        //@ts-ignore
        this.subjectAreaItems = (studiengaengeundfachrichtungen.filter(s => s.schwerpunkt == this.locTajneed.education.mainFocus).map(s => s.fachbereich));
        //@ts-ignore
        this.locTajneed.education.studySubjectArea = this.subjectAreaItems[0];
      }
    }

    // User can change Studium Schwerpunkt, when Studiengang has been entered individual value.
    // According to selecte Schwerpunkt, Fachbereich needs to be adjusted
    private handleStudiumSchwerpunktChange(selection: string) {
      //@ts-ignore
      this.subjectAreaItems = (studiengaengeundfachrichtungen.filter(s => s.schwerpunkt == this.locTajneed.education.mainFocus).map(s => s.fachbereich));
      //@ts-ignore
      this.locTajneed.education.studySubjectArea = this.subjectAreaItems[0];
    }

    private handleAusbildungsartChange(selection: string) {
      if (this.trainingTypeItems.filter((a: string) => a == selection).length > 0) {
        this.trainingSubjectAreaItems = this.ausbildungen.filter((a: Ausbildung) => a.ausbildungsart == selection).map(a => a.fachbereich);
      } else {
        this.trainingSubjectAreaItems = this.ausbildungen.map(a => a.fachbereich);
      }
    }


    get isValid(): boolean {
      if ((this.$refs.formBildung as Vue & { validate: () => boolean }).validate()) {
        return true;
      } else {
        return false;
      }
    }

    get getEducation(): Education {
      //@ts-ignore
      return this.locTajneed.education;
    }
  }

