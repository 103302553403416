
  import moment from 'moment';
  import {Component, Vue} from "vue-property-decorator";
  import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
  import AppLoader from "../../../core/components/AppLoader.vue"
  import {MissionaryReport, MissionaryReportJson, ReportSessionAttendanceSet} from '../report.types';
  import ReportService from '../report.service';
  import TimeSeriesChart from '../components/TimeSeriesChart.vue';
  import {Getter} from "vuex-class";
  import {Useraccount} from "../../../models/useraccount.model";
  import LocalReportIndividualData from "../components/LocalReportIndividualData.vue";
  import {Tajneed} from "../../tajneed/tajneed.types";
  import LocalReportSignatureInput from "../components/LocalReportSignatureInput.vue";
  import {FileReadUtils} from "../../../utils/filereadutils";

  @Component({
    components: {LocalReportIndividualData, AppLoader, LoadingOverlay, TimeSeriesChart, LocalReportSignatureInput}
  })
  export default class ViewMissionaryReport extends Vue {
    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    private loadingReport: boolean = false;

    private reportSessionAttendancesSet: ReportSessionAttendanceSet[] = [];

    private finishEnabled: boolean = true;

    private saveEnabled: boolean = false;

    private signature: string = '';

    private report: MissionaryReport | null = null;

    private rules = {
      required: (value: any) => !!value || 'Pflichtfeld',
      digitsOnly: (v: string) => /^(\s*|\d+)$/.test(v) || 'Zur Zahlen erlaubt',
    };

    private items =  [
      { state: 'Ja', abbr: true },
      { state: 'Nein', abbr: false }
    ];

    created() {
      if (!this.$route.params.id) {
        this.$router.push({path: '/bericht/tabelle/murabbi'});
      } else {
        this.loadReport();
      }
    }

    private reloadReports() {
      this.loadReport();
    }

    loadReport() {
      this.loadingReport = true;
      //@ts-ignore
      let type = '';
      ReportService.getMissionaryReport(this.$route.params.id).then((response: any) => {
        this.report = response.data.report;
        this.reportSessionAttendancesSet = [];
        this.addToReportSessionAttandancesSet(response.data.report.sessionAttendances, 'NISAB_CLASS', 0);
        this.addToReportSessionAttandancesSet(response.data.report.sessionAttendances, 'NISAB_CLASS', 1);
        this.addToReportSessionAttandancesSet(response.data.report.sessionAttendances, 'NISAB_CLASS', 2);
        this.addToReportSessionAttandancesSet(response.data.report.sessionAttendances, 'NISAB_CLASS', 3);
        this.addToReportSessionAttandancesSet(response.data.report.sessionAttendances, 'URDU_CLASS', 0);
        this.addToReportSessionAttandancesSet(response.data.report.sessionAttendances, 'URDU_CLASS', 1);
        this.addToReportSessionAttandancesSet(response.data.report.sessionAttendances, 'URDU_CLASS', 2);
        this.addToReportSessionAttandancesSet(response.data.report.sessionAttendances, 'URDU_CLASS', 3);
        this.signature = response.data.report.signature;

        //@ts-ignore
        let month = this.report.month.toString();
        if (month.toString().length == 1) {
          month = "0" + (month.toString());
        }
        let today = moment();
        //@ts-ignore
        let dateAfterWhichFinishPossible = moment("01." + month + "." + this.report.year, "DD.MM.YYYY").add(1, 'M');
        if (today.isSameOrAfter(dateAfterWhichFinishPossible)) {
          // Current local Date is after the 10th of the next month
          this.saveEnabled = true;
          this.finishEnabled = true;
        } else {
          // Current local Date is before the 10th of the next month
          this.saveEnabled = false;
          this.finishEnabled = false;
        }
        //@ts-ignore
        let dateAfterWhichFinishIsNotPossibleAnymore = moment("11." + month + "." + this.report.year, "DD.MM.YYYY").add(1, 'M').endOf('month');
        if (today.isSameOrAfter(dateAfterWhichFinishIsNotPossibleAnymore)) {
          // Current local Date is after the 13th of the next month
          this.finishEnabled = false;
          this.saveEnabled = true;
        }

        this.loadingReport = false;
      }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
        this.loadingReport = false;
      }).finally(() => {
        setTimeout(() => {
          this.loadingReport = false;
        }, 5000);
      });
    }

    private addToReportSessionAttandancesSet(setFromBackend: ReportSessionAttendanceSet[], sessionType: string, index: number) {
      if(setFromBackend.filter((s: ReportSessionAttendanceSet) => s.session == index && s.sessionType == sessionType).length > 0) {
          this.reportSessionAttendancesSet.push(setFromBackend.filter((s: ReportSessionAttendanceSet) => s.session == index && s.sessionType == sessionType)[0]);
        } else {
          this.reportSessionAttendancesSet.push({
            session: index,
            sessionType: sessionType
          });
        }
    }

    private saveSignature(signature: { signature: string }, type: string) {
      this.signature = signature.signature;
    }

    private save(type: string) {
      if (type != 'save' && !this.signature) {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: "Signatur fehlt",
          type: 'error'
        });
      } else {
        let test: MissionaryReportJson = {
          //@ts-ignore
          id: this.report.id,
          month: this.$route.params.month,
          year: this.$route.params.year,
          reportSessionAttendancesSet: this.reportSessionAttendancesSet,
          status: type == 'save' ? 'SAVED' : 'FINISHED',
          signature: this.signature
        };
        ReportService.saveMissionaryReport(test)
          .then((response: any) => {
            this.$notify({
              group: 'foo',
              title: 'Info',
              text: "Gespeichert",
              type: 'success'
            });
            this.loadReport();
          }).catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: '',
            type: 'error'
          });
        }).finally(() => {
          setTimeout(() => {
          }, 5000);
        });
      }
    }

    private printReport() {
      let type = "";
      if (this.report!.jamaatHalqa != null && this.report!.jamaatHalqa != '') {
        type = "jamaat";
      } else if (this.report!.lajnaMajlis != null && this.report!.lajnaMajlis != '') {
        type = "lajna"
      }
      ReportService.printLocalReport(this.$route.params.month, this.$route.params.year, this.$route.params.organizationName, type).then((response: any) => {
        FileReadUtils.openSaveAs(response.data.document, 'bericht.pdf');
      }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
        this.loadingReport = false;
      }).finally(() => {
        setTimeout(() => {
          this.loadingReport = false;
        }, 5000);
      });
    }

    getMonthForNumber(month: number): string {
      switch (month) {
        case 0:
          return "Januar";
        case 1:
          return "Februar";
        case 2:
          return "März";
        case 3:
          return "April";
        case 4:
          return "Mai";
        case 5:
          return "Juni";
        case 6:
          return "Juli";
        case 7:
          return "August";
        case 8:
          return "September";
        case 9:
          return "Oktober";
        case 10:
          return "November";
        case 11:
          return "Dezember";
        default:
          return "";
      }
    }

    getNumberForMonth(month: string): number {
      switch (month) {
        case "Januar":
          return 0;
        case "Februar":
          return 1;
        case "März":
          return 2;
        case "April":
          return 3;
        case "Mai":
          return 4;
        case "Juni":
          return 5;
        case "Juli":
          return 6;
        case "August":
          return 7;
        case "September":
          return 8;
        case "Oktober":
          return 9;
        case "November":
          return 10;
        case "Dezember":
          return 11;
        default:
          return -1;
      }
    }
  }
