
import Vue from 'vue';
import {Component} from "vue-property-decorator";
import AuthService from '../../../services/auth.service';

import LoadingOverlay from "../../../core/components/LoadingOverlay.vue";
import {AxiosError, AxiosResponse} from "axios";
import {ServerResponse} from "../../../core/core.types";

@Component({
  components: {LoadingOverlay},
})
export default class ViewResetPassword extends Vue {

  private email = "";

  private showOverlay = false;

  private error = false;

  private success = false;

  private errorMessage = "";

  private successMessage = "";

  private resetPassword() {
    if (this.email) {
      this.showOverlay = true;
      AuthService.resetPassword(this.email)
        .then((response: AxiosResponse<ServerResponse>) => {
          this.showOverlay = false;
          this.success = true;
          //@ts-ignore
          this.successMessage = response.data.message;
        }).catch((error: AxiosError<ServerResponse>) => {
          this.showOverlay = false;
          this.error = true;
          this.errorMessage = "Es ist ein Fehler aufgetreten."
        }).finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
      });
    }
  }
}
