
  import {Component, Vue} from "vue-property-decorator";
  import ArchiveService from "../../archive.service";

  import LoadingOverlay from "../../../../core/components/LoadingOverlay.vue";
  //@ts-ignore
  import {Archive} from "../../../../models/archive";
  import Constants from "../../../../staticdata/constants";
  import TableButton from "../../../../core/components/TableButton.vue"

  @Component({
    components: {LoadingOverlay, TableButton}
  })
  //@ts-ignore
  export default class Archive extends Vue {

    private tableData = [];
    private showOverlay = false;

    search = "";

    private shiftAuslandTableHeaders = [
      {text: 'Jamaat ID', value: 'tajneedEntry.aimsId'},
      {text: 'Waqf-e-Nau ID', value: 'tajneedEntry.waqfenauId'},
      {text: 'Jamaat', value: 'tajneedEntry.jamaat'},
      {text: 'Neues Land', value: 'deleteTajneedRequest.newCountry'},
      {text: 'Antragsteller', value: 'deleteTajneedRequest.requester.username'},
      {text: 'Aktionen', value: 'actions'}
    ];

    private amooreAmaCaseTableHeader = [
      {text: 'Jamaat ID', value: 'tajneedEntry.aimsId'},
      {text: 'Waqf-e-Nau ID', value: 'tajneedEntry.waqfenauId'},
      {text: 'Jamaat', value: 'tajneedEntry.jamaat'},
      {text: 'Ikhraj oder Strafe', value: 'deleteTajneedRequest.ikhrajOrOtherPunishment'},
      {text: 'Strafe', value: 'deleteTajneedRequest.otherPunishment'},
      {text: 'Case number/Date', value: 'deleteTajneedRequest.amoreamaHawalaNumberAndDate'},
      {text: 'Antragsteller', value: 'deleteTajneedRequest.requester.username'},
    ];

    private verstorbenTableHeader = [
      {text: 'Jamaat ID', value: 'tajneedEntry.aimsId'},
      {text: 'Waqf-e-Nau ID', value: 'tajneedEntry.waqfenauId'},
      {text: 'Jamaat', value: 'tajneedEntry.jamaat'},
      {text: 'Antragsteller', value: 'deleteTajneedRequest.requester.username'},
    ];

    private ruecktrittTableHeader = [
      {text: 'ID', value: 'id'},
      {text: 'Jamaat-ID', value: 'tajneedEntry.aimsId'},
      {text: 'Grund', value: 'deleteTajneedRequest.reason'}
    ];

    private shiftInlandTableData = [];
    private shiftAuslandTableData = [];
    private amooreAmaCaseTableData = [];
    private verstorbenTableData = [];
    private ruecktrittTableData = [];

    beforeMount() {
      this.loadArchive();
    }

    loadArchive() {
      this.showOverlay = true;
      ArchiveService.getArchive()
        .then(
          (response: any) => {
            this.showOverlay = false;

            this.shiftInlandTableData = response.data.filter((archive: Archive) =>
              //@ts-ignore
              archive.deleteTajneedRequest.reason === Constants.REASON_SHIFT_WITHIN_COUNTRY
            );

            this.shiftAuslandTableData = response.data.filter((archive: Archive) =>
              //@ts-ignore
              archive.deleteTajneedRequest.reason === Constants.REASON_SHIFT_OUTSIDE_OF_COUNTRY
            );

            this.amooreAmaCaseTableData = response.data.filter((archive: Archive) =>
              //@ts-ignore
              archive.deleteTajneedRequest.reason === Constants.REASON_AMOORE_AMA_CASE
            );

            this.verstorbenTableData = response.data.filter((archive: Archive) =>
              //@ts-ignore
              archive.deleteTajneedRequest.reason === Constants.REASON_EXPIRED
            );

            this.ruecktrittTableData = response.data.filter((archive: Archive) =>
              //@ts-ignore
              archive.deleteTajneedRequest.reason === Constants.REASON_WAQFENAU_REFUSAL
            );
          })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: error,
            type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
    }

    private reactivateTajneed(archiveEntry: any) {
      ArchiveService.reactivateTajneed(archiveEntry.id)
        .then((response: any) => {
          this.loadArchive();
        })
    }

  }
