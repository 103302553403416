
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import AppLoader from "../../../core/components/AppLoader.vue"
import {JamaatHalqa, LajnaMajlis, LajnaRegion, RegionLocalAmarat} from "../basedata.types";
import BasedataService from "../basedata.service"
import AppCard from '../../../core/components/cards/AppCard.vue';
import ConfirmLajnaMajlisDeleteDialog from "../components/ConfirmLajnaMajlisDeleteDialog.vue";
import ConfirmLajnaRegionDeleteDialog from "../components/ConfirmLajnaRegionDeleteDialog.vue";
import CreateNewLajnaMajlisDialog from "../components/CreateNewLajnaMajlisDialog.vue";
import CreateNewLajnaRegionDialog from "../components/CreateNewLajnaRegionDialog.vue";

@Component({
  components: {CreateNewLajnaRegionDialog, CreateNewLajnaMajlisDialog, ConfirmLajnaRegionDeleteDialog, ConfirmLajnaMajlisDeleteDialog, AppCard, AppLoader}
})
export default class ViewLajnaMajalis extends Vue {

  @Prop({required: false, default: false})
  loadLajna!: boolean;

  private passwordResettedId: string = '';
  private passwordResettedType: string = '';
  private resettedPassword: string = '';

  majalisTableData: LajnaMajlis[] = [];
  lajnaRegionsTableData: LajnaRegion[] = [];
  search = "";
  majalisTableHeaders = [
    {text: 'ID', value: 'id'},
    {text: 'Lajna Majlis', value: 'name'},
    {text: 'Username', value: 'username'},
    {text: 'Lajna Region', value: 'regionName'},
    {text: 'Jamaat / Halqa', value: 'jamaatHalqaName'},
    {text: 'Löschen', value: 'delete'},
    {text: 'Passwort zurücksetzen', value: 'resetPassword'},
  ];

  lajnaRegionsTableHeaders = [
    {text: 'ID', value: 'id'},
    {text: 'Lajna Region', value: 'name'},
    {text: 'Username', value: 'username'},
    {text: 'Löschen', value: 'delete'},
    {text: 'Passwort zurücksetzen', value: 'resetPassword'},
  ];

  private allLajnaMajalis: string[] = [];

  private allJamaats: string[] = [];

  private allLajnaRegions: string[] = [];

  @Watch('loadLajna')
  onChange(value: boolean) {
    if (value) {
      this.loadMajalis();
    }
  }

  created() {
    console.log("created");
    this.loadMajalis();
  }

  loadMajalis() {
    BasedataService.getJamaatsLajnaMajalisandLajnaRegions().then((response: any) => {
      this.allJamaats = response.data.jamaats.map((a: JamaatHalqa) => a.name);
      this.majalisTableData = response.data.majalis;
      this.lajnaRegionsTableData = response.data.regions;
      this.allLajnaMajalis = response.data.majalis.map((a: LajnaRegion) => a.name);
      this.allLajnaRegions = response.data.regions.map((a: LajnaRegion) => a.name);
      this.$emit('lajna-loaded');
    }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error,
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

  private saveNameChange(item: LajnaMajlis) {
    BasedataService.changeLajnaMajlisName(item)
        .then((response: any) => {
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: "Gespeichert",
            type: 'success'
          });
          // Update username
          var lm = this.majalisTableData.filter(lm => 
            lm.id == response.data.id
          );
          lm[0].username = response.data.username;
        }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: '',
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

  private saveRegionNameChange(item: LajnaRegion) {
    BasedataService.changeLajnaRegionName(item)
        .then((response: any) => {
          this.loadMajalis();
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: "Gespeichert",
            type: 'success'
          });
          this.loadMajalis();
        }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: '',
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

  private changeRegionForLajnaMajlis(item: LajnaMajlis) {
    BasedataService.changeRegionForLajnaMajlis(item)
        .then((response: any) => {
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: "Gespeichert",
            type: 'success'
          });
        }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: '',
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

  private changeJamaatForLajnaMajlis(item: LajnaMajlis) {
    BasedataService.changeJamaatForLajnaMajlis(item)
        .then((response: any) => {
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: "Gespeichert",
            type: 'success'
          });
        }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: '',
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

  private lajnaMajlisToBeDeleted: LajnaMajlis | null = null;

  private confirmDeleteLajnaMajlisDialogVisible: boolean = false;

  private deleteLajnaMajlis(item: LajnaMajlis) {
    this.lajnaMajlisToBeDeleted = item;
    this.confirmDeleteLajnaMajlisDialogVisible = true;
  }

  private handleCloseDeleteLajnaMajlisDialog() {
    this.confirmDeleteLajnaMajlisDialogVisible = false;
    this.loadMajalis();
  }

  private lajnaRegionToBeDeleted: LajnaRegion | null = null;

  private confirmDeleteLajnaRegionDialogVisible: boolean = false;

  private deleteLajnaRegion(item: LajnaRegion) {
    this.lajnaRegionToBeDeleted = item;
    this.confirmDeleteLajnaRegionDialogVisible = true;
  }

  private handleCloseDeleteLajnaRegionDialog() {
    this.confirmDeleteLajnaRegionDialogVisible = false;
    this.loadMajalis();
  }

  private createNewLajnaMajlisDialogVisible: boolean = false;

  private createNewLajnaMajlis() {
    this.createNewLajnaMajlisDialogVisible = true;
  }

  private handleCloseCreateNewLajnaMajlisDialog() {
    this.createNewLajnaMajlisDialogVisible = false;
    this.loadMajalis();
  }

  private resetLajnaMajlisPassword(item: LajnaMajlis) {
    this.resetPassword(item.id.toString(), "lajnaMajlis");
  }

  private resetLajnaRegionPassword(item: LajnaRegion) {
    this.resetPassword(item.id.toString(), "lajnaRegion");
  }

  private resetPassword(organizationId: string, type: string) {
    BasedataService.resetPassword(organizationId, type)
        .then((response: any) => {
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: "Erfolreich geändert",
            type: 'success'
          });
          this.passwordResettedId = organizationId;
          this.passwordResettedType = type;
          this.resettedPassword = response.data;
        }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error.response!.data.message,
        type: 'error'
      });
    }).finally(() => {
      setTimeout(() => {
      }, 5000);
    });
  }

  private createNewLajnaRegionDialogVisible: boolean = false;

  private createNewLajnaRegion() {
    this.createNewLajnaRegionDialogVisible = true;
  }

  private handleCloseCreateNewRegionDialog() {
    this.createNewLajnaRegionDialogVisible = false;
    this.loadMajalis();
  }

}
