
  import {Component, Prop, Vue} from "vue-property-decorator";
  import BasedataService from '../basedata.service';
  import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"

  @Component({
    components: {LoadingOverlay},
  })
  export default class CreateNewRegionLaDialog extends Vue {

    private showOverlay = false;

    private newRegionLaName: string = '';

    private localAmarat: boolean = false;

    notempty = [
      (v: string) => !!v || 'Feld muss ausgefüllt sein'
    ];

    createNewRegionLa() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.showOverlay = true;
        BasedataService.createNewRegionLa(this.newRegionLaName, this.localAmarat)
          .then((response: any) => {
            this.showOverlay = false;
            this.$notify({
              group: 'foo',
              title: 'Info',
              text: 'Region / LA wurde erfolgreich angelegt',
              type: 'success'
            });
            this.$emit('success');
          })
          .catch((error: any) => {
            this.$notify({
              group: 'foo',
              title: 'Fehler',
              text:  error.response!.data.message,
              type: 'error'
            });
            this.showOverlay = false;
          })
          .finally(() => {
            setTimeout(() => {
              this.showOverlay = false;
            }, 5000);
          });
      }
    }

  }
