
  import {Component, Vue} from "vue-property-decorator";
  import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
  import AppLoader from "../../../core/components/AppLoader.vue"
  import {Getter, Mutation} from "vuex-class";
  import {Useraccount} from "../../../models/useraccount.model";
  import {ReportsForGroupJson} from '../report.types';
  import ReportService from '../report.service';
  import {UseraccountService} from '../../useraccount/useraccount.service';

  import moment from 'moment';
  import TableButton from '../../../core/components/TableButton.vue';

  @Component({
    components: {AppLoader, LoadingOverlay, TableButton}
  })
  export default class ViewTableLocalReportsGroup extends Vue {
    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    @Mutation('SET_ORGANIZATION_REPORTS_GROUP', {namespace: 'report'})
    SET_ORGANIZATION_REPORTS_GROUP!: (organizationReports: ReportsForGroupJson[]) => void;

    @Getter('getOrganizationForGroup', {namespace: 'report'})
    private getOrganizationForGroup!: ReportsForGroupJson[];

    tableDataJamaats: any[] = [];

    tableDataMajalis: any[] = [];

    headersJamaatOrJamaatRegion = [
      {text: 'Name', value: 'organizationName'},
    ];

    headersLajnaMajlisOrRegion = [
      {text: 'Name', value: 'organizationName'},
    ];

    private loadingReports: boolean = false;

    private monthsAndYears: any[] = [];

    private tab: number = 0;

    private regionFilterValue = "";

    created() {
      if (this.getUseraccount.isNationalUserOrNationalAssistantUser) {
        //@ts-ignore
        this.headersJamaatOrJamaatRegion.push({text: 'Region', value: 'region', filter: this.regionFilter});
        //@ts-ignore
        this.headersLajnaMajlisOrRegion.push({text: 'Region', value: 'region', filter: this.regionFilter});
      }

      this.headersJamaatOrJamaatRegion.push({text: 'Khuddam/Atfal', value: 'region'});
      this.headersLajnaMajlisOrRegion.push({text: 'Lajna/Nasirat', value: 'region'});

      this.headersJamaatOrJamaatRegion.push({text: 'Sekretär/Muawina', value: 'organizationHeadName'});
      this.headersLajnaMajlisOrRegion.push({text: 'Muawina', value: 'organizationHeadName'});
      this.headersJamaatOrJamaatRegion.push({text: 'Telefon', value: 'organizationHeadPhone'});
      this.headersLajnaMajlisOrRegion.push({text: 'Telefon', value: 'organizationHeadPhone'});
      this.loadReports();

      // Get the last 12 months, starting from current month
      for (let i = 1; i <= 12; i++) {
        this.headersJamaatOrJamaatRegion.push({
          text: this.getMonthForNumber(moment().subtract(i, 'M').month()) + ' ' + moment().subtract(i, 'M').year(),
          value: '',
        });
        this.monthsAndYears.push({
          month: moment().subtract(i, 'M').month() + 1,
          year: moment().subtract(i, 'M').year(),
        });

        this.headersLajnaMajlisOrRegion.push({
          text: this.getMonthForNumber(moment().subtract(i, 'M').month()) + ' ' + moment().subtract(i, 'M').year(),
          value: '',
        });
      }
    }

    private saveOrganizationHeadName(item: any) {
      UseraccountService.updateOfficeHolderName(item.organizationHeadName, item.organizationType, item.organizationName)
          .then((response: any) => {
            this.$notify({
              group: 'foo',
              title: 'Info',
              text: "Gespeichert",
              type: 'success'
            });
          }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: '',
          type: 'error'
        });
      }).finally(() => {
        setTimeout(() => {
        }, 5000);
      });
    }

    private saveOrganizationHeadPhone(item: any) {
      UseraccountService.updateOfficeHolderPhone(item.organizationHeadPhone, item.organizationType, item.organizationName)
          .then((response: any) => {
            this.$notify({
              group: 'foo',
              title: 'Info',
              text: "Gespeichert",
              type: 'success'
            });
          }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: '',
          type: 'error'
        });
      }).finally(() => {
        setTimeout(() => {
        }, 5000);
      });
    }

    private getReportIsPresent(item: any, month: number, year: number): boolean {
      if (item.allReports.filter((r: any) => r.month == month && r.year == year).length == 1) {
        return true;
      }
      return false;
    }

    private reloadReports() {
      this.loadReports(true);
    }

    loadReports(force: boolean = false) {
      this.loadingReports = true;
      if (this.getOrganizationForGroup.length == 0 || force) {
        //@ts-ignore
        ReportService.getLocalReportsForRegion().then((response: any) => {
          this.SET_ORGANIZATION_REPORTS_GROUP(response.data);
          console.log('SET');
          this.tableDataJamaats = response.data.filter((r: any) => r.organizationType == 'JamaatHalqa');
          this.tableDataMajalis = response.data.filter((r: any) => r.organizationType == 'LajnaMajlis');
          this.loadingReports = false;
        }).catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: error,
            type: 'error'
          });
          this.loadingReports = false;
        }).finally(() => {
          setTimeout(() => {
            this.loadingReports = false;
          }, 5000);
        });
      } else {
        this.tableDataJamaats = this.getOrganizationForGroup.filter((r: any) => r.organizationType == 'JamaatHalqa');
        this.tableDataMajalis = this.getOrganizationForGroup.filter((r: any) => r.organizationType == 'LajnaMajlis');
        this.loadingReports = false;
      }
    }

    private openReport(selectedEntry: ReportsForGroupJson, month: number, year: number) {
      let report = selectedEntry.allReports.filter(r => r.month == month && r.year == year)[0];
      console.log(selectedEntry);
      //@ts-ignore
      this.$router.push({
        name: 'ViewLocalReport',
        params: {
          month: month,
          year: year,
          organizationName: selectedEntry.organizationName,
          type: selectedEntry.type,
        }
      });
    }

    private regionFilter(valueInRow: any) {
      if (!this.regionFilterValue) {
        return true;
      }
      else if (!valueInRow) {
        return false;
      }
      else {
        return valueInRow.toString().toUpperCase().indexOf(this.regionFilterValue.toUpperCase()) >= 0;
      }
    }

    getMonthForNumber(month: number): string {
      switch (month) {
        case 0:
          return "Jan";
        case 1:
          return "Feb";
        case 2:
          return "Mär";
        case 3:
          return "Apr";
        case 4:
          return "Mai";
        case 5:
          return "Jun";
        case 6:
          return "Jul";
        case 7:
          return "Aug";
        case 8:
          return "Sep";
        case 9:
          return "Okt";
        case 10:
          return "Nov";
        case 11:
          return "Dez";
        default:
          return "";
      }
    }

    getNumberForMonth(month: string): number {
      switch (month) {
        case "Januar":
          return 0;
        case "Februar":
          return 1;
        case "März":
          return 2;
        case "April":
          return 3;
        case "Mai":
          return 4;
        case "Juni":
          return 5;
        case "Juli":
          return 6;
        case "August":
          return 7;
        case "September":
          return 8;
        case "Oktober":
          return 9;
        case "November":
          return 10;
        case "Dezember":
          return 11;
        default:
          return -1;
      }
    }
  }
