
  import {Component, Vue, Watch} from "vue-property-decorator";
  import AppLoader from "../../../../core/components/AppLoader.vue"
  import ConfigurationService from "../configuration.service";
  @Component({
    components: {AppLoader}
  })
  export default class ViewConfiguration extends Vue {

    private loadingConfiguration: boolean = false;

    private configurationsList: [] = [];

    private headers = [
      {text: 'Parameter', value: 'key'},
      {text: 'Rolle', value: 'role'},
      {text: 'Wert', value: 'value'}
    ]

    created() {
      this.loadConfiguration();
    }

    loadConfiguration() {
      this.loadingConfiguration = true;
        //@ts-ignore
        ConfigurationService.getConfiguration().then((response: any) => {
          this.configurationsList = response.data;
        }).catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: error,
            type: 'error'
          });
          this.loadingConfiguration = false;
        }).finally(() => {
          setTimeout(() => {
            this.loadingConfiguration = false;
          }, 5000);
        });
    }

    setConfiguration() {
      //@ts-ignore
      ConfigurationService.setConfiguration(this.configurationsList).then((response: any) => {
        this.$notify({
          group: 'foo',
          title: 'Info',
          text: "Gespeichert",
          type: 'success'
        });
      }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
      }).finally(() => {
        setTimeout(() => {
        }, 5000);
      });
    }


  }
