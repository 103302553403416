
import {Component, Vue} from "vue-property-decorator";
import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
import AppLoader from "../../../core/components/AppLoader.vue"
import {Getter} from "vuex-class";
import {Useraccount} from "../../../models/useraccount.model";
import {IndividualReport} from '../report.types';
import ReportService from '../report.service';
import moment from 'moment';

@Component({
  components: {AppLoader, LoadingOverlay}
})
export default class ViewTableIndividualReports extends Vue {
  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  tableData: IndividualReport[] = [];

  private personId: string = "";

  headers = [
    {text: 'Monat', value: 'month'},
    {text: 'Jahr', value: 'year'},
    {text: 'Erstellt', value: 'createDate'},
    {text: 'Abschlussdatum', value: 'dateFinished'},
    {text: 'Status', value: 'status'}
  ];

  private loadingReports: boolean = false;

  created() {
    if (!this.$route.params.personId) {
      this.$router.push({path: '/'});
    } else {
      this.personId = this.$route.params.personId;
      this.loadReports();
    }
  }

  loadReports() {
    this.loadingReports = true;

    //@ts-ignore
    ReportService.getIndividualReports(this.personId).then((response: any) => {
      //We only want the latest delete request to be seen
      this.loadingReports = false;

      this.tableData = response.data;

    }).catch((error: any) => {
      this.$notify({
        group: 'foo',
        title: 'Fehler',
        text: error,
        type: 'error'
      });
      this.loadingReports = false;
    }).finally(() => {
      setTimeout(() => {
        this.loadingReports = false;
      }, 5000);
    });
  }

  customRowClass(item: any) {
    if (item.status === 'NEW_TAJNEED_REQUEST') {
      return 'blue-bg';
    }
    if (item.status === 'SENT') {
      return 'green-bg';
    }
    if (item.status === 'DELETE_REQUESTED' || item.status === 'SHIFT_REQUESTED') {
      return 'yellow-bg';
    }
    if (item.status === 'NEW_TAJNEED_DECLINED' || item.status === 'DELETE_TAJNEED_DECLINED') {
      return 'red-bg';
    } else {
      return 'normal';
    }
  }

  handleEdit(selectedEntry: IndividualReport) {
    //@ts-ignore
    this.$router.push({
      name: 'ViewReportIndividual',
      params: {
        reportId: selectedEntry.id,
        personId:this.$route.params.personId
      }
    });
  }

  getMonthForNumber(month: number): string {
    switch (month) {
      case 0:
        return "Januar";
      case 1:
        return "Februar";
      case 2:
        return "März";
      case 3:
        return "April";
      case 4:
        return "Mai";
      case 5:
        return "Juni";
      case 6:
        return "Juli";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "Oktober";
      case 10:
        return "November";
      case 11:
        return "Dezember";
      default:
        return "";
    }
  }

  getNumberForMonth(month: string): number {
    switch (month) {
      case "Januar":
        return 0;
      case "Februar":
        return 1;
      case "März":
        return 2;
      case "April":
        return 3;
      case "Mai":
        return 4;
      case "Juni":
        return 5;
      case "Juli":
        return 6;
      case "August":
        return 7;
      case "September":
        return 8;
      case "Oktober":
        return 9;
      case "November":
        return 10;
      case "Dezember":
        return 11;
      default:
        return -1;
    }
  }

  getTextForStatus(status: string): string {
    switch (status) {
      case "CREATED" :
        return "ERSTELLT"
      case "SAVED" :
        return "ZWISCHENGESPEICHERT"
      case "FINISHED" :
        return "ABGESCHLOSSEN"
      case "EXPIRED" :
        return "ABGELAUFEN"
      default:
        return "";
    }
  }


}
