
    import {Component, Prop, Vue} from 'vue-property-decorator';
  import AppCardButton from '../../../core/components/cards/AppCardButton.vue';
  
  
  @Component({
    components: {
      AppCardButton,
    },
  })
  export default class DashboardTileExam extends Vue {
  
    @Prop({ default: false, required: false })
    isDisabled!: boolean;
  
    @Prop({ default: "", required: false })
    personId!: string;
  
  }
  
  