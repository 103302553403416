
  import {Component, Prop, Vue} from "vue-property-decorator";
  import StaticData from "../../../../../staticdata/staticdata"
  import ApprovalService from "../../../approval.service"
  import Approvals from "../../../views/approvals/Approvals.vue"
  import DeleteTajneedDeclineDialog from "./DeleteTajneedDeclineDialog.vue";
  import LoadingOverlay from "../../../../../core/components/LoadingOverlay.vue"
  import {Tajneed} from "../../../tajneed.types";
  import BasedataService from "../../../../../services/basedata.service"

  @Component({
    components: {DeleteTajneedDeclineDialog, LoadingOverlay},
  })
  export default class ApproveDeleteDialog extends Vue {

    @Prop({default: null})
    selectedTajneed: Tajneed;

    umzugInland = false;

    umzugAusland = false;

    verstorben = false;

    private deleteTajneedDeclineDialogVisible = false;

    private showOverlay = false;

    private jamaatItems: string[] = [];

    private majlisItems: string[] = [];


    private rules = {
      required: (value: any) => !!value || 'Pflichtfeld'
    };

    approveDeleteTajneed() {
      this.showOverlay = true;
      //@ts-ignore
      ApprovalService.approveTajneedDelete(this.selectedTajneed.deleteTajneedRequestSet[0])
        .then(
          (response: any) => {
            this.showOverlay = false;
            this.$notify({
              group: 'foo',
              title: 'Info',
              text: response.data,
              type: 'success'
            });
            this.$emit('event', "");
          })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: error,
            type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
    }

    declineDeleteTajneed(){
      this.deleteTajneedDeclineDialogVisible = true;
    }

    handleCloseDeleteTajneedDeclineEntryDialog(){
      this.deleteTajneedDeclineDialogVisible = false;
      this.$emit('event');
    }


    beforeMount(){
      //@ts-ignore
      if(this.selectedTajneed.deleteTajneedRequestSet[0].reason==="Umzug Inland"){
        this.umzugInland = true;
        //@ts-ignore
      } else if (this.selectedTajneed.deleteTajneedRequestSet[0].reason==="Umzug Ausland"){
        this.umzugAusland = true;
        //@ts-ignore
      } else if (this.selectedTajneed.deleteTajneedRequestSet[0].reason==="Verstorben") {
        this.verstorben = true;
      }

      BasedataService.getJamaatAndMajalis()
        .then((response: any) => {
          this.jamaatItems = response.data.jamaats;
          this.majlisItems = response.data.majalis;
        })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: 'Netzwerkfehler',
            type: 'error'
          });
        })
        .finally(() => {
          setTimeout(() => {
          }, 5000);
        });
    }

  }
