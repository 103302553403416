
import {Component, Prop, Vue} from 'vue-property-decorator';
import AppCardButton from '../../../core/components/cards/AppCardButton.vue';

@Component({
  components: {
    AppCardButton,
  },
})
export default class DashboardTileTajneed extends Vue {
  @Prop({ default: "-1", required: false })
  tajneedId!: string;

  @Prop({ default: "", required: false })
  isChild!: string;

  @Prop({ default: "", required: false })
  isOwnTajneed!: string;

}

