
  import moment from 'moment';
  import {Component, Vue} from "vue-property-decorator";
  import TajneedService from "../../tajneed.service";
  import LoadingOverlay from "../../../../core/components/LoadingOverlay.vue"
  import AppLoader from "../../../../core/components/AppLoader.vue"
  import AppCard from '../../../../core/components/cards/AppCard.vue';

  import TajneedForm from "./TajneedForm.vue";
  import {Tajneed} from "../../tajneed.types";
  import {Getter, Mutation} from "vuex-class";
  import {Useraccount} from "../../../../models/useraccount.model";

  import {store} from "../../../../store";
  import TableButton from '../../../../core/components/TableButton.vue';

  export interface test {
    text: string,
    value: string,
    filter?: any
  }

  @Component({
    components: {AppCard, AppLoader, TajneedForm, LoadingOverlay, TableButton}
  })
  export default class FirstConfirmationMissingTableView extends Vue {
    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    private numbersonly = [
      (v: string) => !v || /^\d+$/.test(v) || 'Nur Zahlen erlaubt',
    ];


    tableData: Tajneed[] = [];
    search = "";

    private on: any = null;

    private showOverlay = false;
    private loadingTajneed = false;

    private type = "";

    private isReadOnlyUser = false;

    created() {
      this.type = this.$route.meta.id;
      this.loadTajneed();
    }

    get getHeaders() {
      let headers: test[] = [];
      
      headers.push(
          {text: 'Jamaat ID', value: 'aimsId'},
          {text: 'Waqf-e-Nau ID', value: 'waqfenauId'},
        );

        headers.push(
          {text: 'Name', value: 'fullname'},
        );

        if ((this.getUseraccount.isNationalJamaatUser || this.getUseraccount.isRegionalJamaatUser) || this.getUseraccount.isMissionaryUser) {
          headers.push(
            {text: 'Jamaat', value: 'jamaat'}
          );
        }

        if ((this.getUseraccount.isNationalJamaatUser || this.getUseraccount.isNationalLajnaUser)) {
          headers.push(
            {text: 'Region', value: 'region'},
          );
        }

        headers.push(
          {text: 'Alter', value: 'age'},
        );

        if ((this.getUseraccount.isLocalJamaatUser || this.getUseraccount.isLocalLajnaUser)) {
          headers.push(
            {text: 'Telefon', value: 'phoneNumber'},
          );
        }

      return headers;
    }

    loadTajneed(fromButton: boolean = false) {
      this.loadingTajneed = true;
      //@ts-ignore
      TajneedService.getTajneedFirstConfirmationMissing().then((response: any) => {
        this.tableData = response.data;
        this.loadingTajneed = false;
      }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
        this.loadingTajneed = false;
      }).finally(() => {
        setTimeout(() => {
          this.loadingTajneed = false;
        }, 5000);
      });
    }

  
    formatDate(date: any) {
      if (!date) {
        return null;
      }
      const [year, month, day] = date.split('-');

      return `${day}.${month}.${year}`;
    }

    openTajneed(selectedEntry: any) {
      this.$router.push({path: '/dashboard/menue/' + selectedEntry.id});
    }
  }
