
  import moment from 'moment';
  import {Component, Vue} from "vue-property-decorator";
  import ApprovalService from "../../approval.service";
  import ApproveDeleteDialog from "../../components/approvals/dialogs/ApproveDeleteDialog.vue";
  import TajneedForm from "../tajneed/TajneedForm.vue";
  import {ResponseApprovals, Tajneed} from "../../tajneed.types";
  import Constants from "../../../../staticdata/constants";
  import {AxiosResponse} from "axios";
  import {ChildAddRequest} from '../../../useraccount/useraccount.types';
  import ApproveChildAddRequest from "../../components/approvals/dialogs/ApproveChildAddRequest.vue";
  import ApproveNewTajneedDialog from "../../components/approvals/dialogs/ApproveNewTajneedDialog.vue";
  import {FileReadUtils} from "../../../../utils/filereadutils";
  import {Getter} from "vuex-class";
  import {Useraccount} from "../../../../models/useraccount.model";
  import AppLoader from "../../../../core/components/AppLoader.vue"

  @Component({
    components: {AppLoader, ApproveNewTajneedDialog, ApproveChildAddRequest, TajneedForm, ApproveDeleteDialog}
  })
  export default class Approvals extends Vue {

    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    private selectedTajneedForApprove = "";
    private approveDeleteDialogVisible = false;
    private approveChildAddRequestDialogVisible = false;
    private newTajneedDeclineDialogVisible: boolean = false;
    private selectedRequestForApprove: ChildAddRequest = {};

    private showOverlay = false;

    private shiftInlandWaqifinTableData: Tajneed[] = [];
    private shiftAuslandWaqifinTableData: Tajneed[] = [];
    private amooreAmaCaseWaqifinTableData: Tajneed[] = [];
    private verstorbenWaqifinTableData: Tajneed[] = [];
    private ruecktrittWaqifinTableData: Tajneed[] = [];
    private newTajneedWaqifinTabledata: Tajneed[] = [];
    private childAddRequestsWaqifinTabledata = [];
    private jamiaTableData: Tajneed[] = [];

    private shiftInlandWaqifatTableData: Tajneed[] = [];
    private shiftAuslandWaqifatTableData: Tajneed[] = [];
    private amooreAmaCaseWaqifatTableData: Tajneed[] = [];
    private verstorbenWaqifatTableData: Tajneed[] = [];
    private ruecktrittWaqifatTableData: Tajneed[] = [];
    private newTajneedWaqifatTabledata: Tajneed[] = [];
    private childAddRequestsWaqifatTabledata = [];

    private loadingWaqifin: boolean = false;

    private loadingWaqifat: boolean = false;

    private type = "";

    private search = "";
    private search2 = "";

    private tab: number = 0;

    private newTajneedTableHeaders = [
      {text: 'Datum', value: 'newTajneedRequest.createDate'},
      {text: 'Jamaat ID', value: 'aimsId'},
      {text: 'Waqf-e-Nau ID', value: 'waqfenauId'},
      {text: 'Jamaat', value: 'jamaat'},
      {text: 'Antragsteller', value: 'newTajneedRequest.requester'},
      {text: 'Genehmigen', value: 'approve', sortable: false, filterable: false, align: 'center'},
    ];

    private childAddRequestsWaqifinTableHeaders = [
      {text: 'Datum', value: 'createDate'},
      {text: 'Jamaat ID', value: 'aimsId'},
      {text: 'Waqf-e-Nau ID', value: 'waqfenauId'},
      {text: 'Geb-Datum', value: 'dateOfBirth'},
      {text: 'Vorname', value: 'firstname'},
      {text: 'Name', value: 'lastname'},
      {text: 'Jamaat', value: 'jamaat'},
      {text: 'Tajneed-Match', value: 'tajneedMatchId'},
      {text: 'Genehmigen', value: 'approve'},
    ];

    private childAddRequestsWaqifatTableHeaders = [
      {text: 'Datum', value: 'createDate'},
      {text: 'Jamaat ID', value: 'aimsId'},
      {text: 'Waqf-e-Nau ID', value: 'waqfenauId'},
      {text: 'Geb-Datum', value: 'dateOfBirth'},
      {text: 'Vorname', value: 'firstname'},
      {text: 'Name', value: 'lastname'},
      {text: 'Majlis', value: 'majlis'},
      {text: 'Tajneed-Match', value: 'tajneedMatchId'},
      {text: 'Genehmigen', value: 'approve'},
    ];

    private shiftInlandTableHeaders = [
      {text: 'Datum', value: 'deleteTajneedRequestSet[0].createDate'},
      {text: 'Jamaat ID', value: 'aimsId'},
      {text: 'Waqf-e-Nau ID', value: 'waqfenauId'},
      {text: 'Bisherige Jamaat', value: 'jamaat'},
      {text: 'Neue Jamaat', value: 'deleteTajneedRequestSet[0].newJamaat'},
      {text: 'Neue Majlis', value: 'deleteTajneedRequestSet[0].newMajlis'},
      {text: 'Antragsteller', value: 'deleteTajneedRequestSet[0].requester'},
      {text: 'Genehmigen', value: 'approve', sortable: false, filterable: false, align: 'center'},
    ];

    private shiftAuslandTableHeaders = [
      {text: 'Datum', value: 'deleteTajneedRequestSet[0].createDate'},
      {text: 'Jamaat ID', value: 'aimsId'},
      {text: 'Waqf-e-Nau ID', value: 'waqfenauId'},
      {text: 'Jamaat', value: 'jamaat'},
      {text: 'Neues Land', value: 'deleteTajneedRequestSet[0].newCountry'},
      {text: 'Antragsteller', value: 'deleteTajneedRequestSet[0].requester'},
      {text: 'Genehmigen', value: 'approve', sortable: false, filterable: false, align: 'center'},
    ];

    private shiftAuslandOrphansTableHeaders = [
      {text: 'Jamaat ID', value: 'tajneed.aimsId'},
      {text: 'Waqf-e-Nau ID', value: 'tajneed.waqfenauId'},
    ];

    private amooreAmaCaseTableHeader = [
      {text: 'Datum', value: 'deleteTajneedRequestSet[0].createDate'},
      {text: 'Jamaat ID', value: 'aimsId'},
      {text: 'Waqf-e-Nau ID', value: 'waqfenauId'},
      {text: 'Jamaat', value: 'jamaat'},
      {text: 'Ikhraj oder Strafe', value: 'deleteTajneedRequestSet[0].ikhrajOrOtherPunishment'},
      {text: 'Strafe', value: 'deleteTajneedRequestSet[0].otherPunishment'},
      {text: 'Case number/Date', value: 'deleteTajneedRequestSet[0].amoreamaHawalaNumberAndDate'},
      {text: 'Antragsteller', value: 'deleteTajneedRequestSet[0].requester'},
      {text: 'Genehmigen', value: 'approve', sortable: false, filterable: false, align: 'center'},
    ];

    private amooreAmaCaseOrphansTableHeader = [
      {text: 'Jamaat ID', value: 'tajneed.aimsId'},
      {text: 'Waqf-e-Nau ID', value: 'tajneed.waqfenauId'},
    ];

    private verstorbenTableHeader = [
      {text: 'Datum', value: 'deleteTajneedRequestSet[0].createDate'},
      {text: 'Jamaat ID', value: 'aimsId'},
      {text: 'Waqf-e-Nau ID', value: 'waqfenauId'},
      {text: 'Jamaat', value: 'jamaat'},
      {text: 'Antragsteller', value: 'deleteTajneedRequestSet[0].requester'},
      {text: 'Genehmigen', value: 'approve', sortable: false, filterable: false, align: 'center'},
    ];

    private verstorbenOrphansTableHeader = [
      {text: 'Jamaat ID', value: 'tajneed.aimsId'},
      {text: 'Waqf-e-Nau ID', value: 'tajneed.waqfenauId'},
    ];

    private ruecktrittTableHeader = [
      {text: 'Datum', value: 'deleteTajneedRequestSet[0].createDate'},
      {text: 'Jamaat ID', value: 'aimsId'},
      {text: 'Waqf-e-Nau ID', value: 'waqfenauId'},
      {text: 'Jamaat', value: 'jamaat'},
      {text: 'Antragsteller', value: 'deleteTajneedRequestSet[0].requester'},
      {text: 'Genehmigen', value: 'approve', sortable: false, filterable: false, align: 'center'},
      {text: 'Download', value: 'download', sortable: false, filterable: false, align: 'center'},
    ];

    private jamiaTableHeaders = [
      {text: 'Datum', value: 'deleteTajneedRequestSet[0].createDate'},
      {text: 'Jamaat ID', value: 'aimsId'},
      {text: 'Waqf-e-Nau ID', value: 'waqfenauId'},
      {text: 'Jamaat', value: 'jamaat'},
      {text: 'Jamia', value: 'deleteTajneedRequestSet[0].jamia'},
      {text: 'Genehmigen', value: 'approve', sortable: false, filterable: false, align: 'center'},
    ];

    beforeMount() {
      // This will always be defined. Also on page reload
      this.type = this.$route.meta.id;
      this.loadApprovals();
    }

    onTabWaqifinClicked() {
      this.loadWaqifinApprovals();
    }

    onTabWaqifatClicked() {
      this.loadWaqifatApprovals();
    }

    loadApprovals() {
      if (this.getUseraccount.isNationalJamaatUser) {
        if(this.tab==0) {
          this.loadWaqifinApprovals();
        }
        if(this.tab==1) {
          this.loadWaqifatApprovals();
        }
      }
    }

    private loadWaqifatApprovals() {
      this.loadingWaqifat = true;
      ApprovalService.getApprovals('Female').then((response: AxiosResponse<ResponseApprovals>) => {
        //@ts-ignore
        this.newTajneedWaqifatTabledata = response.data.allNewTajneedRequests;
        //@ts-ignore
        this.childAddRequestsWaqifatTabledata = response.data.allChildAddRequests;

        let allDeleteRequests: Tajneed[] = response.data.allDeleteRequests || [];

        //We only want the latest delete request to be seen:
        for (var i = 0; i < allDeleteRequests.length; i++) {
          let maxId: any = 0;
          let maxIdIndex = 0;
          //@ts-ignore
          for (var j = 0; j < allDeleteRequests[i].deleteTajneedRequestSet.length; j++) {
            //@ts-ignore
            if (allDeleteRequests[i].deleteTajneedRequestSet[j].id > maxId) {
              //@ts-ignore
              maxId = allDeleteRequests[i].deleteTajneedRequestSet[j].id;
              maxIdIndex = j;
            }
          }
          //@ts-ignore
          let result = allDeleteRequests[i].deleteTajneedRequestSet.splice(maxIdIndex, 1);
          //Is still array, but only with one element
          allDeleteRequests[i].deleteTajneedRequestSet = result;
        }

        this.shiftInlandWaqifatTableData = allDeleteRequests.filter((tajneed: Tajneed) =>
          //@ts-ignore
          tajneed.deleteTajneedRequestSet.length > 0 && tajneed.deleteTajneedRequestSet[0].reason === Constants.REASON_SHIFT_WITHIN_COUNTRY
        );

        this.shiftAuslandWaqifatTableData = allDeleteRequests.filter((tajneed: Tajneed) =>
          //@ts-ignore
          tajneed.deleteTajneedRequestSet.length > 0 && tajneed.deleteTajneedRequestSet[0].reason === Constants.REASON_SHIFT_OUTSIDE_OF_COUNTRY
        );

        this.amooreAmaCaseWaqifatTableData = allDeleteRequests.filter((tajneed: Tajneed) =>
          //@ts-ignore
          tajneed.deleteTajneedRequestSet.length > 0 && tajneed.deleteTajneedRequestSet[0].reason === Constants.REASON_AMOORE_AMA_CASE
        );

        this.verstorbenWaqifatTableData = allDeleteRequests.filter((tajneed: Tajneed) =>
          //@ts-ignore
          tajneed.deleteTajneedRequestSet.length > 0 && tajneed.deleteTajneedRequestSet[0].reason === Constants.REASON_EXPIRED
        );

        this.ruecktrittWaqifatTableData = allDeleteRequests.filter((tajneed: Tajneed) =>
          //@ts-ignore
          tajneed.deleteTajneedRequestSet.length > 0 && tajneed.deleteTajneedRequestSet[0].reason === Constants.REASON_WAQFENAU_REFUSAL
        );

        this.loadingWaqifat = false;

      }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
        this.showOverlay = false;
      }).finally(() => {
        setTimeout(() => {
          this.showOverlay = false;
          this.loadingWaqifat = false;
        }, 5000);
      });
    }

    private loadWaqifinApprovals() {
      this.loadingWaqifin = true;
      //@ts-ignore
      ApprovalService.getApprovals('Male').then((response: AxiosResponse<ResponseApprovals>) => {
        //@ts-ignore
        this.newTajneedWaqifinTabledata = response.data.allNewTajneedRequests;
        //@ts-ignore
        this.childAddRequestsWaqifinTabledata = response.data.allChildAddRequests;

        let allDeleteRequests: Tajneed[] = response.data.allDeleteRequests || [];

        //We only want the latest delete request to be seen:
        for (var i = 0; i < allDeleteRequests.length; i++) {
          let maxId: any = 0;
          let maxIdIndex = 0;
          //@ts-ignore
          for (var j = 0; j < allDeleteRequests[i].deleteTajneedRequestSet.length; j++) {
            //@ts-ignore
            if (allDeleteRequests[i].deleteTajneedRequestSet[j].id > maxId) {
              //@ts-ignore
              maxId = allDeleteRequests[i].deleteTajneedRequestSet[j].id;
              maxIdIndex = j;
            }
          }
          //@ts-ignore
          let result = allDeleteRequests[i].deleteTajneedRequestSet.splice(maxIdIndex, 1);
          //Is still array, but only with one element
          allDeleteRequests[i].deleteTajneedRequestSet = result;
        }

        this.shiftInlandWaqifinTableData = allDeleteRequests.filter((tajneed: Tajneed) =>
          //@ts-ignore
          tajneed.deleteTajneedRequestSet.length > 0 && tajneed.deleteTajneedRequestSet[0].reason === Constants.REASON_SHIFT_WITHIN_COUNTRY
        );

        this.shiftAuslandWaqifinTableData = allDeleteRequests.filter((tajneed: Tajneed) =>
          //@ts-ignore
          tajneed.deleteTajneedRequestSet.length > 0 && tajneed.deleteTajneedRequestSet[0].reason === Constants.REASON_SHIFT_OUTSIDE_OF_COUNTRY
        );

        this.amooreAmaCaseWaqifinTableData = allDeleteRequests.filter((tajneed: Tajneed) =>
          //@ts-ignore
          tajneed.deleteTajneedRequestSet.length > 0 &&  tajneed.deleteTajneedRequestSet[0].reason === Constants.REASON_AMOORE_AMA_CASE
        );

        this.verstorbenWaqifinTableData = allDeleteRequests.filter((tajneed: Tajneed) =>
          //@ts-ignore
          tajneed.deleteTajneedRequestSet.length > 0 &&  tajneed.deleteTajneedRequestSet[0].reason === Constants.REASON_EXPIRED
        );

        this.ruecktrittWaqifinTableData = allDeleteRequests.filter((tajneed: Tajneed) =>
          //@ts-ignore
          tajneed.deleteTajneedRequestSet.length > 0 &&  tajneed.deleteTajneedRequestSet[0].reason === Constants.REASON_WAQFENAU_REFUSAL
        );

        this.jamiaTableData = allDeleteRequests.filter((tajneed: Tajneed) =>
          //@ts-ignore
          tajneed.deleteTajneedRequestSet.length > 0 &&  tajneed.deleteTajneedRequestSet[0].reason === Constants.REASON_JAMIA
        );
        this.loadingWaqifin = false;
      }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
        this.showOverlay = false;
      }).finally(() => {
        setTimeout(() => {
          this.loadingWaqifin = false;
          this.showOverlay = false;
        }, 5000);
      });
    }

    handleApproveDeleteTajneedRequest(selectedEntry: any) {
      this.selectedTajneedForApprove = Object.assign({}, selectedEntry)
      this.approveDeleteDialogVisible = true;
    }

    handleCloseApproveDeleteDialog() {
      this.loadApprovals();
      this.approveDeleteDialogVisible = false;
    }

    handleApproveNewTajneedRequest(selectedEntry: any) {
      this.selectedTajneedForApprove = Object.assign({}, selectedEntry);
      this.newTajneedDeclineDialogVisible = true;
    }

    handlerCloseNewTajneedApproveDialog() {
      this.newTajneedDeclineDialogVisible = false;
      this.loadApprovals();
    }

    handleApproveAddChildRequest(selectedEntry: ChildAddRequest) {
      this.selectedRequestForApprove = JSON.parse(JSON.stringify(selectedEntry));
      this.approveChildAddRequestDialogVisible = true;
    }

    handleCloseApproveChildAddRequestDialog() {
      this.approveChildAddRequestDialogVisible = false;
      this.loadApprovals();
    }

    downloadRefusalPdf(selectedEntry: any) {
      ApprovalService.getRefusalPdf(selectedEntry.deleteTajneedRequestSet[0].id.toString()).then((response: any) => {
        FileReadUtils.openSaveAs(response.data.document, "Refusal.pdf");
      });
    }

    private customSort(items: Tajneed[], index: any, isDesc: boolean[]) {
      items.sort((a: Tajneed, b: Tajneed) => {
        if (index[0] === 'newTajneedRequest.createDate') {
          console.log('dawd');
          if (!isDesc[0]) {
            return moment.utc(a.newTajneedRequest.createDate, 'HH:mm DD.MM.YYYY') > moment.utc(b.newTajneedRequest.createDate, 'HH:mm DD.MM.YYYY') ? -1 : 1;
          }
          return moment.utc(a.newTajneedRequest.createDate, 'HH:mm DD.MM.YYYY') < moment.utc(b.newTajneedRequest.createDate, 'HH:mm DD.MM.YYYY') ? -1 : 1;
        }
        if (index[0] === 'createDate') {
          console.log('dawd');
          if (!isDesc[0]) {
            // @ts-ignore
            return moment.utc(a.createDate, 'HH:mm DD.MM.YYYY') > moment.utc(b.createDate, 'HH:mm DD.MM.YYYY') ? -1 : 1;
          }
          //@ts-ignore
          return moment.utc(a.createDate, 'HH:mm DD.MM.YYYY') < moment.utc(b.createDate, 'HH:mm DD.MM.YYYY') ? -1 : 1;
        }
        if (index[0] === 'deleteTajneedRequestSet[0].createDate') {
          console.log('dawd');
          if (!isDesc[0]) {
            // @ts-ignore
            return moment.utc(a.deleteTajneedRequestSet[0].createDate, 'HH:mm DD.MM.YYYY') > moment.utc(b.deleteTajneedRequestSet[0].createDate, 'HH:mm DD.MM.YYYY') ? -1 : 1;
          }
          //@ts-ignore
          return moment.utc(a.deleteTajneedRequestSet[0].createDate, 'HH:mm DD.MM.YYYY') < moment.utc(b.deleteTajneedRequestSet[0].createDate, 'HH:mm DD.MM.YYYY') ? -1 : 1;
        }
        // Sorting on all other columns
        if (!isDesc[0]) {
          // @ts-ignore
          return a[index] < b[index] ? -1 : 1;
        }
        // @ts-ignore
        return b[index] < a[index] ? -1 : 1;
      });
      return items;
    }
  }
