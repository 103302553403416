import {ChildAddRequest} from "@/modules/useraccount/useraccount.types";
import httpResource from "../../http/httpResource";

const API_URL = process.env.VUE_APP_API_URL + '/api/useraccount/';


export const UseraccountService = {

  changePassword(username: string, newPassword: string, oldPassword: string) {
    return httpResource.post(API_URL + 'changepassword', {username: username, newPassword: newPassword, oldPassword: oldPassword});
  },

  changeUsername(newUsername: string) {
    return httpResource.post(API_URL + 'changeusername', {newUsername: newUsername});
  },

  getOwnMetaData(username: string | undefined) {
    return httpResource.post(API_URL + 'getownmetadata', {username: username});
  },

  requestChildAdd(childAddRequest: ChildAddRequest){
    return httpResource.post(API_URL + 'requestchildadd', childAddRequest);
  },

  createAccountForChild(data: any){
    return httpResource.post(API_URL + 'createaccountforchild', data);
  },

  deleteAccount(accountId: string){
    return httpResource.post(API_URL + 'delete/' + accountId);
  },

  getAccountsAndTajneeds() {
    return httpResource.get(API_URL + 'getaccountsandtajneeds');
  },

  saveOfficeHolderData(name: string, aimsId: string, phoneNumber: string, dateOfBirth: string, whatsAppNumber: string, email: string) {
    return httpResource.post(API_URL + 'saveofficeholderdata',
      {
        name: name,
        aimsId: aimsId,
        phoneNumber: phoneNumber,
        dateOfBirth: dateOfBirth,
        whatsAppNumber: whatsAppNumber,
        email: email
      });
  },

  updateOfficeHolderName(name: string, organizationType: string, organizationName: string) {
    return httpResource.post(API_URL + 'updateofficeholdername',
      {
        newName: name,
        organizationType: organizationType,
        organizationName: organizationName
      });
  },

  updateOfficeHolderPhone(phone: string, organizationType: string, organizationName: string) {
    return httpResource.post(API_URL + 'updateofficeholderphone',
      {
        newPhone: phone,
        organizationType: organizationType,
        organizationName: organizationName
      });
  },

  changeAccountUsername(accountId: string, username: string){
    return httpResource.put(API_URL + 'change-username/' + accountId, {username: username});
  },
};
