
  import {Component, Vue} from "vue-property-decorator";
  import AppLoader from "../../../core/components/AppLoader.vue"
  import AppCard from '../../../core/components/cards/AppCard.vue';
  import {Getter} from "vuex-class";
  import {Useraccount} from "../../../models/useraccount.model";
  import CreateNewCaseEntryDialog from "../components/CreateNewCaseEntryDialog.vue";
  import ParentsCouncellingService from "../parents-councelling.service";
  import TableButton from '../../../core/components/TableButton.vue';
  import {ParentsCounsellingCase} from "../parents-councelling.types";

  @Component({
    components: {AppCard, AppLoader, CreateNewCaseEntryDialog, TableButton}
  })
  export default class ViewParentsCounsellingCases extends Vue {
    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    private loadingCases: boolean = false;

    private createNewCaseEntryDialogVisible: boolean = false;

    private selectedCaseForEdit: ParentsCounsellingCase | null = null;

    private casesTableHeaders = [
      {text: 'PF-Nummer', align: 'start', sortable: false, value: 'pfNumber'},
      {text: 'Name Kind', align: 'start', sortable: false, value: 'childName'},
      {text: 'Name Vater', align: 'start', sortable: false, value: 'fathersName'},
      {text: 'Name Mutter', align: 'start', sortable: false, value: 'mothersName'},
      {text: 'Jamaat', align: 'start', sortable: false, value: 'jamaatHalqa'},
      {text: 'Zuständiger Murabbi', align: 'start', sortable: false, value: 'assignedMissionary'},
      {text: 'Aktionen', align: 'center', sortable: false, value: 'actions'},
    ];

    private addingToTajneed: boolean = false;

    private casesTableItems: any[] = [];

    created() {
      this.loadCasesList();
    }

    private loadCasesList() {
      this.loadingCases = true;
      ParentsCouncellingService.getCounsellingCases().then((response: any) => {
        console.log(response);
        this.casesTableItems = response.data;
        this.loadingCases = false;
      })
    }

    private createNewCaseEntry() {
      this.addingToTajneed = false;
      this.selectedCaseForEdit = null;
      this.createNewCaseEntryDialogVisible = true;
    }

    private handleCloseCreateNewCaseEntryDialog() {
      this.createNewCaseEntryDialogVisible = false;
      this.addingToTajneed = false;
      this.selectedCaseForEdit = null;
      this.loadCasesList();
    }

    private editCase(item: ParentsCounsellingCase) {
      this.addingToTajneed = false;
      this.selectedCaseForEdit = item;
      this.createNewCaseEntryDialogVisible = true;
    }

    private deleteCase(id: number) {
      this.loadingCases = true;
      ParentsCouncellingService.deleteCaseEntry(id).then((response: any) => {
        this.loadCasesList();
        this.$notify({
          group: 'foo',
          title: 'Info',
          text: 'Eintrag wurde erfolgreich gelöscht',
          type: 'success'
        });
        this.loadingCases = false;
      })
    }

    private markCaseAsDone(counsellingCase: ParentsCounsellingCase) {
      counsellingCase.done = true;
      ParentsCouncellingService.updateCaseEntry(counsellingCase).then((response: any) => {
        this.loadCasesList();
        this.$notify({
          group: 'foo',
          title: 'Info',
          text: 'Eintrag wurde erfolgreich bearbeitet',
          type: 'success'
        });
        this.loadingCases = false;
      })
    }

    private addToTajneed(counsellingCase: ParentsCounsellingCase) {
      this.selectedCaseForEdit = counsellingCase;
      this.addingToTajneed = true;
      this.createNewCaseEntryDialogVisible = true;
    }


    private row_classes(item: ParentsCounsellingCase) {
        if (item.status == 'DONE') {
          return "green"; //can also return multiple classes e.g ["orange","disabled"]
        } 
    }
  }
