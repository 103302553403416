var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('app-card',{attrs:{"has-header":false,"is-transparent":true,"closeable":false}},[_c('v-layout',{attrs:{"row":"","wrap":"","py-4":""}},[_c('v-form',{ref:"form-changeusername",staticStyle:{"width":"100%"},attrs:{"lazy-validation":false}},[_c('span',[_vm._v("Bitte geben Sie den neue Email-Adresse ein:")]),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required,
          _vm.rules.correctEmail
        ],"data-cy":'input-password-new',"dense":""},model:{value:(_vm.username.username),callback:function ($$v) {_vm.$set(_vm.username, "username", $$v)},expression:"username.username"}}),_c('span',[_vm._v("Bitte geben Sie die neue Email-Adresse erneut ein:")]),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required,
          _vm.rules.usernameConfirmed
        ],"data-cy":'input-password-new-confirm',"dense":""},model:{value:(_vm.username.usernameConfirmed),callback:function ($$v) {_vm.$set(_vm.username, "usernameConfirmed", $$v)},expression:"username.usernameConfirmed"}})],1),_c('v-btn',{staticStyle:{"border-radius":"0px","background-color":"#3365bd","color":"white"},attrs:{"depressed":"","data-cy":'button-http-change-password'},on:{"click":function($event){return _vm.changeUsername()}}},[_vm._v(" Benutzername ändern ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }