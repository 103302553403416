
import { Component, Vue } from 'vue-property-decorator';

import AppCard from '../../../core/components/cards/AppCard.vue';
import DashboardRowAddChild from '../components/DashboardRowAddChild.vue';
import DashboardRowOwn from '../components/DashboardRowOwn.vue';
import DashboardRowChildren from '../components/DashboardRowChildren.vue';
import {Getter, Mutation} from "vuex-class";
import {Useraccount} from "../../../models/useraccount.model";
import {Tajneed} from "../../tajneed/tajneed.types";
import TajneedService from "../../tajneed/tajneed.service";
import {OwnMetaData} from '../../tajneed/tajneed.types';

@Component({
  components: { AppCard, DashboardRowAddChild, DashboardRowOwn, DashboardRowChildren}
})
export default class ViewDashboardPersons extends Vue {

  @Getter('getUseraccount', {namespace: 'useraccount'})
  private getUseraccount!: Useraccount;

  @Getter('getOwnMetaData', {namespace: 'accountmetadata'})
  private getOwnMetaData!: OwnMetaData;

}
