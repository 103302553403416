
  import Vue from 'vue';
  import moment from 'moment';
  import {Useraccount} from "../../../models/useraccount.model";
  import {Component} from "vue-property-decorator";

  import {Getter, Mutation} from 'vuex-class';
  import {OwnMetaData, Tajneed} from "../../tajneed/tajneed.types";
  import LoadingOverlay from "../../../core/components/LoadingOverlay.vue";
  import AuthService from '../../../services/auth.service';

  //const store = this.$store.direct;
  @Component({
    components: {LoadingOverlay},
  })
  export default class ActivateAccountView extends Vue {

    @Mutation('SET_TAJNEED', {namespace: 'tajneed'})
    SET_TAJNEED!: (tajneed: Tajneed[]) => void;

    @Mutation('SET_OWN_METADATA', {namespace: 'accountmetadata'})
    SET_OWN_METADATA!: (ownMetaData: OwnMetaData) => void;

    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    private username = "";

    private tajneedFirstName = "";

    private tajneedLastName = "";

    private confirmationAttempts: number = 3;

    private waqfenauId = "";

    private aimsId = "";

    private dateOfBirth = "";

    private error = false;

    private errorMessage = "";

    private success = false;

    private successMessage = "";

    private showOverlay = false;

    private daterules = [
      (v: string) => !!v || 'Feld muss ausgefüllt sein.',
      (v: string) => /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/.test(v) || 'TT.MM.JJJJ Format',
    ];

    private notemptyAndNoSpecialChar = [
      (v: string) => !!v || 'Feld muss ausgefüllt sein',
      (v: string) => /^[0-9][0-9]?[0-9]?[0-9]?[0-9]?[A-Z]$/.test(v) || 'Keine gültige Waqf-e-Nau Nummer',
    ];

    private numbersonlyandnotempty = [
      (v: string) => !!v || 'Feld muss ausgefüllt sein',
      (v: string) => !v || /^\d+$/.test(v) || 'Nur Zahlen erlaubt',
    ];

    created() {
      if (!this.$route.params.username) {
        this.$router.push({path: '/'});
      } else {
        this.tajneedFirstName = this.$route.params.tajneedFirstName;
        this.tajneedLastName = this.$route.params.tajneedLastName;
        this.username = this.$route.params.username;
        this.confirmationAttempts = this.confirmationAttempts - (+this.$route.params.confirmationAttempts);
      }
    }

    activateAccount() {
      //@ts-ignore
      if (this.$refs['form'].validate()) {
        let data = {
          username: this.username,
          tajneedFirstName: this.tajneedFirstName,
          tajneedLastName: this.tajneedLastName,
          waqfenauId: this.waqfenauId,
          aimsId: this.aimsId,
          dateOfBirth: (moment.utc(this.dateOfBirth, "DD.MM.YYYY")).format('YYYY-MM-DD')

        }
        this.showOverlay = true;
        AuthService.activateAccount(data)
          .then((response: any) => {
            console.log("then");
            this.showOverlay = false;
            this.error = false;
            this.success = true;
            this.successMessage = response.data;
          })
          .catch((error: any) => {
            this.showOverlay = false;
            this.success = false;
            this.error = true;
            this.confirmationAttempts = this.confirmationAttempts -1;
            this.errorMessage = error.response.data.message;
          })
          .finally(() => {
            setTimeout(() => {
              this.showOverlay = false;
            }, 5000);
          });
      }
    }

    setHeight() {
      if (this.error || this.success) {
        return 'largeActivationCard';
      } else {
        return 'smallActivationCard';
      }
    }

  }
