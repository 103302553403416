
  import {Component, Vue} from 'vue-property-decorator';
  import AppLoader from "../../../core/components/AppLoader.vue";
  import {Getter} from "vuex-class";
  import {OwnMetaData} from "../../tajneed/tajneed.types";
  import {Jaiza, JaizaRequest} from "../jaiza.types";
  import JaizaService from "../jaiza.service";
  import moment from 'moment';
  import {Useraccount} from "../../../models/useraccount.model";
  import HifzeQuranSection from "../components/HifzeQuranSection.vue";
  import NamazSection from "../components/NamazSection.vue";
  import BookReadingSection from "../components/BookReadingSection.vue";
  import PrayersSection from "../components/PrayersSection.vue";
  import PoemsSection from "../components/PoemsSection.vue";
  import ReligiousKnowledgeSection from "../components/ReligiousKnowledgeSection.vue";
  import EtiquettesSection from "../components/EtiquettesSection.vue";
  import ConfirmSendJaizaDialog from "../components/ConfirmSendJaizaDialog.vue";

  @Component({
    components: {
      ConfirmSendJaizaDialog,
      EtiquettesSection,
      ReligiousKnowledgeSection,
      PoemsSection,
      PrayersSection,
      BookReadingSection,
      NamazSection,
      HifzeQuranSection,
      AppLoader,
    },
  })
  export default class ViewJaiza extends Vue {
    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    @Getter('getOwnMetaData', {namespace: 'accountmetadata'})
    private getOwnMetaData!: OwnMetaData;

    private loadingJaizas: boolean = false;

    private finishVisible: boolean = false;

    private judgeColumnVisible: boolean = false;

    private judgeCheckDisabled: boolean = false;

    private isFourYearsOld: boolean = false;
    private isFiveYearsOld: boolean = false;
    private isSixYearsOld: boolean = false;
    private isSevenYearsOld: boolean = false;
    private isEightYearsOld: boolean = false;
    private isNineYearsOld: boolean = false;
    private isTenYearsOld: boolean = false;
    private isElevenYearsOld: boolean = false;
    private isTwelveYearsOld: boolean = false;
    private isThirteenYearsOld: boolean = false;
    private isFourteenYearsOld: boolean = false;
    private isFifteenYearsOld: boolean = false;
    private isSixteenYearsOld: boolean = false;
    private isSeventeenYearsOld: boolean = false;

    private requiredHadith: string = "";
    private requiredHadithTranslations: string = "";
    private requiredNamesOfAllah: string = "";
    private requiredSeventeenVerses: string = "";
    private requiredQuranParahsWithTranslation: string = "";
    private requiredQaseedahVerses: string = "";
    private requiredQuranicPrayers: string = "";

    private saveVisible: boolean = false;

    private confirmSendJaizaDialogVisible: boolean = false;

    private jaiza: Jaiza = {
      year: 0
    };

    private jaizaRequest: JaizaRequest | null = null;

    private rules = {
      required: (value: any) => !!value || 'Pflichtfeld',
    };

    private showOverlay: boolean = false;

    created() {
      if (!this.$route.params.personId || !this.$route.params.year || !this.$route.params.dateOfBirth) {
        this.$router.push({path: '/'});
      } else {
        this.loadJaiza();
      }
    }

    get showEssayReceivedCheckbox() {
      if(this.getUseraccount.isNationalJamaatUser || this.getUseraccount.isNationalAssistantJamaatUser) {
        return true;
      }
      return false;
    }

    private loadJaiza() {
      this.loadingJaizas = true;
      this.saveVisible = false;
      this.finishVisible = false;
      this.judgeColumnVisible = false;
      this.judgeCheckDisabled = false;
      //@ts-ignore
      JaizaService.getJaiza(this.$route.params.personId, this.$route.params.year).then((response: any) => {
        //We only want the latest delete request to be seen
        this.loadingJaizas = false;
        this.jaiza = response.data;
        let ageAsOfFirstJayzaNisabDate = moment(this.jaiza.year.toString() + "-09-01").diff(moment(this.$route.params.dateOfBirth), 'years');
        if (this.getUseraccount.isNormalUser) {
          let dateFirstOfSeptember = this.jaiza.year.toString() + "-09-01";
          if (moment().isSameOrBefore(moment(this.jaiza.lastJaizaFinishDate))
            && (this.jaiza.status == 'CREATED' || this.jaiza.status == 'SAVED')) {
            this.saveVisible = true;
          }
          if (moment().isSameOrAfter(moment(dateFirstOfSeptember))
            && moment().isSameOrBefore(moment(this.jaiza.lastJaizaFinishDate))
            && (this.jaiza.status == 'CREATED' || this.jaiza.status == 'SAVED')) {
            this.finishVisible = true;
          }
        } else if (this.getUseraccount.isJudgeUser) {
          if (ageAsOfFirstJayzaNisabDate >= 6) {
            let dateFirstOfSeptember = this.jaiza.year.toString() + "-09-01";
            if (moment().isSameOrAfter(moment(dateFirstOfSeptember)) && moment().isSameOrBefore(moment(this.jaiza.lastJaizaFinishDate))
              && (this.jaiza.status == 'CREATED' || this.jaiza.status == 'SAVED' || this.jaiza.status == 'SENT_BY_WAQIFIN' || this.jaiza.status == 'SAVED_BY_JUDGE')) {
              this.saveVisible = true;
              this.finishVisible = true;
            } else {
              this.judgeCheckDisabled = true;
            }
            this.judgeColumnVisible = true;
          }
        } else if (this.getUseraccount.isLocalLajnaUser || this.getUseraccount.isLocalJamaatUser || this.getUseraccount.isRegionalJamaatUser || this.getUseraccount.isRegionalLajnaUser) {
          if (ageAsOfFirstJayzaNisabDate >= 6) {
            let dateFirstOfSeptember = this.jaiza.year.toString() + "-09-01";
            if (moment().isSameOrAfter(moment(dateFirstOfSeptember)) && moment().isSameOrBefore(moment(this.jaiza.lastJaizaFinishDate))
              && (this.jaiza.status == 'CREATED' || this.jaiza.status == 'SAVED' || this.jaiza.status == 'SENT_BY_WAQIFIN' || this.jaiza.status == 'SAVED_BY_JUDGE' || this.jaiza.status == 'SENT_BY_JUDGE')) {
              // Secretary does not have save Button
              this.finishVisible = true;
            } else {
              this.judgeCheckDisabled = true;
            }
            this.judgeColumnVisible = true;
          }
        } else if (this.getUseraccount.isNationalUserOrNationalAssistantUser) {
          if (ageAsOfFirstJayzaNisabDate >= 6) {
            this.judgeColumnVisible = true;
            let dateAfterWhichEditIsPossible = this.jaiza.year.toString() + "-10-31";
            if (moment().isSameOrAfter(moment(dateAfterWhichEditIsPossible)) && moment().isSameOrBefore(moment(this.jaiza.lastJaizaFinishDate))) {
              this.judgeCheckDisabled = false;
              if(this.jaiza.status != 'SENT_BY_NATIONAL_JAMAAT' && this.jaiza.status != 'SENT_BY_NATIONAL_LAJNA') {
                this.saveVisible = true;
                this.finishVisible = true;
              }
            } else {
              this.judgeCheckDisabled = true;
              this.saveVisible = false;
              this.finishVisible = false;
            }
          }
        }
      }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
        this.loadingJaizas = false;
      }).finally(() => {
        setTimeout(() => {
          this.loadingJaizas = false;
        }, 5000);
      });

      let age = moment().diff(moment(this.$route.params.dateOfBirth), 'years');
      if (age >= 4) {
        this.isFourYearsOld = true;
      }
      if (age >= 5) {
        this.isFiveYearsOld = true;
        this.requiredHadith = "3";
        this.requiredHadithTranslations = "3";
      }
      if (age >= 6) {
        this.isSixYearsOld = true;
        this.requiredHadith = "3";
        this.requiredHadithTranslations = "3";
      }
      if (age >= 7) {
        this.isSevenYearsOld = true;
        this.requiredHadith = "4";
        this.requiredHadithTranslations = "4";
      }
      if (age >= 8) {
        this.isEightYearsOld = true;
        this.requiredHadith = "6";
        this.requiredHadithTranslations = "6";
        this.requiredNamesOfAllah = "4";
      }
      if (age >= 9) {
        this.isNineYearsOld = true;
        this.requiredHadith = "8";
        this.requiredHadithTranslations = "8";
        this.requiredNamesOfAllah = "10";
        this.requiredSeventeenVerses = "10";
      }
      if (age >= 10) {
        this.isTenYearsOld = true;
        this.requiredHadith = "10";
        this.requiredHadithTranslations = "10";
        this.requiredNamesOfAllah = "15";
        this.requiredSeventeenVerses = "17";
      }
      if (age >= 11) {
        this.isElevenYearsOld = true;
        this.requiredHadith = "16";
        this.requiredHadithTranslations = "16";
        this.requiredNamesOfAllah = "15";
        this.requiredSeventeenVerses = "17";
      }
      if (age >= 12) {
        this.isTwelveYearsOld = true;
        this.requiredHadith = "26";
        this.requiredHadithTranslations = "26";
        this.requiredNamesOfAllah = "24";
        this.requiredSeventeenVerses = "17";
        this.requiredQuranParahsWithTranslation = "1";
      }
      if (age >= 13) {
        this.isThirteenYearsOld = true;
        this.requiredHadith = "36";
        this.requiredHadithTranslations = "36";
        this.requiredNamesOfAllah = "32";
        this.requiredSeventeenVerses = "17";
        this.requiredQuranParahsWithTranslation = "1";
      }
      if (age >= 14) {
        this.isFourteenYearsOld = true;
        this.requiredHadith = "43";
        this.requiredHadithTranslations = "43";
        this.requiredNamesOfAllah = "52";
        this.requiredSeventeenVerses = "17";
        this.requiredQuranParahsWithTranslation = "4";
      }
      if (age >= 15) {
        this.isFifteenYearsOld = true;
        this.requiredHadith = "48";
        this.requiredHadithTranslations = "48";
        this.requiredNamesOfAllah = "81";
        this.requiredSeventeenVerses = "17";
        this.requiredQuranParahsWithTranslation = "8";
        this.requiredQaseedahVerses = "20";
      }
      if (age >= 16) {
        this.isSixteenYearsOld = true;
        this.requiredHadith = "52";
        this.requiredHadithTranslations = "52";
        this.requiredNamesOfAllah = "81";
        this.requiredSeventeenVerses = "17";
        this.requiredQuranParahsWithTranslation = "12";
        this.requiredQaseedahVerses = "20";
        this.requiredQuranicPrayers = "4";
      }
      if (age >= 17) {
        this.isSeventeenYearsOld = true;
        this.requiredHadith = "55";
        this.requiredHadithTranslations = "55";
        this.requiredNamesOfAllah = "81";
        this.requiredSeventeenVerses = "17";
        this.requiredQuranParahsWithTranslation = "16";
        this.requiredQaseedahVerses = "50";
        this.requiredQuranicPrayers = "8";
      }
    }

    private saveJaiza() {
      let request: JaizaRequest = {
        jaiza: this.jaiza,
        personId: this.$route.params.personId,
      }

      this.doSave(request);
    }

    private finishJaiza() {
      if (!(this.$refs.formJaiza as Vue & { validate: () => boolean }).validate()) {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: "Es fehlen noch Pflichtangaben.",
          type: 'error'
        });
      } else {
        this.jaizaRequest = {
          jaiza: this.jaiza,
          personId: this.$route.params.personId,
        }
        this.confirmSendJaizaDialogVisible = true;
      }
    }

    private doSave(request: JaizaRequest) {
      JaizaService.saveJaiza(request)
        .then((response: any) => {
          this.showOverlay = false;
          this.loadJaiza();
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: response.data,
            type: 'success'
          });
        })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: error.response!.data.message,
            type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
    }

    private handleClosConfirmJaizaSendDialog(){
      this.confirmSendJaizaDialogVisible = false;
      this.loadJaiza();
    }

    getNumberAsArray(number: number): string[] {
      return [...Array((number + 1)).keys()].map(String);
    }

    getTextForStatus(status: string): string {
      switch (status) {
        case "CREATED" :
          return "ERSTELLT"
        case "SAVED" :
          return "ZWISCHENGESPEICHERT"
        case "SENT_BY_WAQIFIN" :
          return "GESENDET VON WAQIFIN"
        case "SAVED_BY_JUDGE" :
          return "GESPEICHERT VON JUDGE"
        case "SENT_BY_JUDGE" :
          return "GESENDET VON JUDGE"
        case "SENT_BY_LOCAL_SECRETARY" :
          return "GESENDET VON JAMAAT/HALQA SEKRETÄR"
        case "SENT_BY_LOCAL_MUAWINA" :
          return "GESENDET VON LOKAL MUAWINA"
        case "SENT_BY_REGIONAL_SECRETARY" :
          return "GESENDET VON LOCAL SEKRETÄR"
        case "SENT_BY_REGIONAL_MUAWINA" :
          return "GESENDET VON REGIONAL MUAWINA"
        case "SAVED_BY_NATIONAL_JAMAAT" :
          return "GESPEICHERT VON NATIONAL SHOBA"
        case "SENT_BY_NATIONAL_JAMAAT" :
          return "GESENDET VON NATIONAL SHOBA"
        case "SAVED_BY_NATIONAL_LAJNA" :
          return "GESPEICHERT VON NATIONAL SHOBA"
        case "SENT_BY_NATIONAL_LAJNA" :
          return "GESENDET VON NATIONAL SHOBA"
        default:
          return "";
      }
    }
  }
