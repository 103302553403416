
  import {Component, Prop, Vue, Watch} from "vue-property-decorator";
  import {AdditionalInformation, Tajneed} from '../../tajneed.types';
  import StaticData from '../../../../staticdata/staticdata';
  import {Mutation, Getter} from "vuex-class";
  import {Useraccount} from "../../../../models/useraccount.model";

  @Component({
    components: {},
  })
  export default class AdditionalInformationForm extends Vue {

    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    @Prop({default: null})
    tajneed: Tajneed;

    @Prop({required: false, default: false})
    deleteRequested!: boolean;

    private locTajneed: Tajneed = {};

    private allLanguages: string[] = StaticData.getAllLanguages();

    max = 50;

    notempty = [
      (v: string) => !!v || 'Feld muss ausgefüllt sein'
    ];

    created() {
      this.locTajneed = JSON.parse(JSON.stringify(this.tajneed));
      if(!this.locTajneed.additionalInformation){
        this.locTajneed.additionalInformation = {
          languageSet: []
        };
      }
    }

    @Watch('tajneed')
    onPropertyChanged(value: string, oldValue: string) {
      this.locTajneed = JSON.parse(JSON.stringify(this.tajneed));
      if(!this.locTajneed.additionalInformation){
        this.locTajneed.additionalInformation = {
          languageSet: []
        };
      }
    }

    removeRowFromVoucherKostenSet(index: number){
      //@ts-ignore
      this.locTajneed.additionalInformation.languageSet.splice(index, 1);
    }

    addRowToVoucherKostenSet(){
      //@ts-ignore
      this.locTajneed.additionalInformation.languageSet.push({
        name: "",
        level: ""
      });
    }


    get isValid(): boolean {
      if ((this.$refs.formAdditionalInformation as Vue & { validate: () => boolean }).validate()) {
        return true;
      } else {
        return false;
      }
    }

    get getAdditionalInformation(): AdditionalInformation {
      //@ts-ignore
      return this.locTajneed.additionalInformation;
    }

  }
