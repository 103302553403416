
  import {Component, Prop, Vue} from "vue-property-decorator";
  import LoadingOverlay from "../../../../../core/components/LoadingOverlay.vue";
  import ArchiveService from "../../../archive.service";


  @Component({
    components: {LoadingOverlay},
  })
  export default class ReactivateArchiveEntryDialog extends Vue {

    @Prop({default: null})
    selectedArchive: any;

    private showOverlay = false;

    private reactivateArchive() {
      this.showOverlay = true;
      ArchiveService.reactivateTajneed(this.selectedArchive.id)
        .then((response: any) => {
          this.showOverlay = false;
          this.$notify({
            group: 'foo',
            title: 'Info',
            text: response.data,
            type: 'success'
          });
          this.$emit('event', "");
        })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: error,
            type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
    }

  }
