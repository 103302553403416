
  import moment from 'moment';
  import {Component, Prop, Vue} from 'vue-property-decorator';

  import AppCard from '../../../core/components/cards/AppCard.vue';
  import DashboardCol from '../../../core/components/DashboardCol.vue';
  import DashboardRow from '../../../core/components/DashboardRow.vue';
  import DashboardTileExam from './DashboardTileExam.vue';
  import {Getter} from "vuex-class";
  import {Useraccount} from "../../../models/useraccount.model";

  @Component({
    components: {
      DashboardTileExam,
      AppCard,
      DashboardCol,
      DashboardRow,
    },
  })
  export default class DashboardMenuThirdRow extends Vue {
    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    @Prop({default: "-1", required: false})
    tajneedId!: string;

    @Prop({default: "", required: false})
    isChild!: string;

    @Prop({default: "", required: false})
    isOwnTajneed!: string;

    @Prop({default: "", required: false})
    dateOfBirth!: string;

    @Prop({default: "", required: true})
    personId!: string;

    private isOlderThenFifteen: boolean = false;

    private showDiaryTile: boolean = false;

    created() {
      this.isOlderThenFifteen = moment("01.01.2024", "DD.MM.YYYY").diff(this.dateOfBirth, 'years') >= 15 ? true : false;
      if (this.isOlderThenFifteen && this.getUseraccount.getRoles.indexOf("ROLE_NORMAL_MEMBER") >= 0) {
        this.showDiaryTile = true;
      }
    }

  }
