
  import {Component, Prop, Vue} from "vue-property-decorator";
  import {TajneedChangeRecord} from "../../../tajneed.types";
  import {AxiosResponse} from "axios";
  import TajneedService from "../../../tajneed.service";
  import AppLoader from "../../../../../core/components/AppLoader.vue"

  @Component({
    components: {AppLoader},
  })
  export default class ChangeHistoryDialog extends Vue {
    @Prop({default: ''})
    tajneedId: string;

    private changeHistory: TajneedChangeRecord[] = [];
    private loadingHistory = false;

    created() {
      this.loadingHistory = true;
      TajneedService.getChangeHistory(+this.tajneedId)
        .then((response: AxiosResponse<TajneedChangeRecord[]>) => {
          this.changeHistory = response.data;
          this.loadingHistory = false;
        })
        .catch((error: any) => {
          this.$notify({
            group: 'foo', title: 'Fehler', text: error, type: 'error'
          });
          this.loadingHistory = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.loadingHistory = false;
          }, 5000);
        });
    }

  }
