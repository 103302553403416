
  import {Component, Vue, Watch} from 'vue-property-decorator';

  import AppCard from '../../../core/components/cards/AppCard.vue';
  import {UseraccountService} from '../useraccount.service';
  import {Getter} from "vuex-class";
  import {Useraccount} from "../../../models/useraccount.model";
  import {ServerResponse} from '../../../core/core.types';
  import {AxiosError, AxiosResponse} from "axios";
  import {performLogout} from "../../../utils/utils";
  import {router} from "../../../router";

  interface Passwords {
    password: string;
    passwordConfirmed: string;
  }

  @Component({
    components: {
      AppCard,
    },
  })
  export default class FormChangePassword extends Vue {


    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;


    private oldPassword: string = '';

    private passwords: Passwords = {
      password: '',
      passwordConfirmed: '',
    }

    private showOverlay: boolean = false;

    private rules = {
      required: (value: any) => !!value || 'Pflichtfeld',
      minLength: (v: { length: number; }) => v.length >= 7 || 'Mindestens 7 Zeichen',
      letterUppercase: (password: any) => /[A-Z-ÄÖÜ]+/.test(password) || 'Mindestens ein Großbuchstabe',
      letterLowercase: (password: any) => /[a-z-äöü]+/.test(password) || 'Mindestens ein Kleinbuchstabe',
      numeric: (password: any) => /[0-9]+/.test(password) || 'Mindestens eine Ziffer',
      passwordConfirmed: (v: string) => this.passwordMatch(v) || 'Passwörter stimmen nicht überein',
    };

    private passwordMatch(p: string) {
      if (this.passwords.passwordConfirmed.length > 0) {
        return (this.passwords.password === this.passwords.passwordConfirmed);
      }

      return true;
    }

    get getRequestBodyChangePassword(): any {
      return {
        password: this.oldPassword,
        passwordNew: this.passwords.password,
      };
    }

    @Watch('passwords', {immediate: true, deep: true})
    async onErrorCountQuickChanged(val: Passwords, oldVal: Passwords) {
      await this.$nextTick();

      if (this.passwords.passwordConfirmed.length > 0) {
        // @ts-ignore
        this.$refs['form-changepassword'].validate();
      }
    }

    private changePassword() {
      // @ts-ignore
      if (!this.$refs['form-changepassword'].validate()) return;

      this.showOverlay = true;

      UseraccountService.changePassword(
        this.getUseraccount.getUsername,
        this.passwords.password,
        this.oldPassword
      ).then((response: AxiosResponse<ServerResponse>) => {
        this.oldPassword = '';
        this.passwords.password = '';
        this.passwords.passwordConfirmed = '';
        // @ts-ignore
        this.$refs['form-changepassword'].resetValidation();
        //@ts-ignore
        this.$notify({group: 'foo', title: 'Info', text: response.data.message, type: 'success'});
        performLogout();
      }).catch((error: AxiosError<ServerResponse>) => {
        //@ts-ignore
        this.$notify({group: 'foo', title: 'Fehler', text: error.response!.data.message, type: 'error'});
      }).finally(() => {
        setTimeout(() => {
          this.showOverlay = false;
        }, 5000);
      });
    }
  }
