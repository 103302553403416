
  import {Component, Prop, Ref, Vue, Watch,} from 'vue-property-decorator';
  import VueSignaturePad from 'vue-signature-pad';
  @Component
  export default class LocalReportSignatureInput extends Vue {

    private valid: boolean = true;

    @Ref('signaturePad') readonly signaturePad!: VueSignaturePad;

    @Prop({required: false})
    signature!: string;

    private locSignature: string = '';

    created(){
      this.locSignature = this.signature;
    }

    @Watch('signature')
    onPropertyChanged(value: string, oldValue: string) {
      this.locSignature = value;
    }

    validate(): boolean {
      const dialogForm = this.$refs.dialogForm as Vue & { validate: () => boolean };
      return (dialogForm.validate() && this.locSignature != '');
    }

    private clearSignature(): void {
      this.signaturePad.clearSignature();
      this.$emit('signatureSave', {signature: undefined, name: undefined});
    }

    private saveSignature(): void {
      const signature = this.signaturePad.saveSignature();
      if (!signature.isEmpty) {
        const dataString = signature.data.split(',')[1];
        this.locSignature = dataString;
        this.$emit('signatureSave', {signature: dataString});
      }
    }
  }

