
  import {Component, Prop, Vue} from "vue-property-decorator";
  import TajneedService from "../../../tajneed.service"
  import LoadingOverlay from "../../../../../core/components/LoadingOverlay.vue"
  import {DeleteTajneedRequest, Tajneed} from '../../../tajneed.types';
  import Constants from "../../../../../staticdata/constants";
  import {ServerResponse} from '../../../../../core/core.types';
  import {AxiosError} from "axios";
  import DeleteTajneedSignatureInput from "./DeleteTajneedSignatureInput.vue";

  @Component({
    components: {DeleteTajneedSignatureInput, LoadingOverlay},
  })
  export default class RemoveAccountTajneedDialog extends Vue {

    //its only a subset of Tajneed, not complete Tajneed object
    @Prop({default: null})
    selectedTajneed: Tajneed;

    @Prop({ default: "", required: false })
    isChild!: string;

    @Prop({ default: "", required: false })
    isOwnTajneed!: string;

    private dtr: DeleteTajneedRequest = {};

    private showOverlay = false;

    private rules = {
      required: (value: any) => !!value || 'Pflichtfeld',
    };

    requestRemoveChild() {
      this.dtr.reason = Constants.REASON_WAQFENAU_REFUSAL;
      if(this.isOwnTajneed && !this.dtr.signature) {
        this.$notify({group: 'foo', title: 'Fehler', text: "Unterschrift fehlt", type: 'error'});
        return;
      }

      if(this.isChild && (!this.dtr.signatureFather || !this.dtr.signatureMother)) {
        this.$notify({group: 'foo', title: 'Fehler', text: "Unterschrift fehlt", type: 'error'});
        return;
      }

      let data = {
        //@ts-ignore
        selectedTajneedId: +this.selectedTajneed.id,
        deleteTajneedRequest: this.dtr
      };

      this.showOverlay = true;
      TajneedService.deleteTajneed(data)
        .then((response: any) => {
          this.showOverlay = false;
          this.$notify({
            group: 'foo', title: 'Info', text: response.data, type: 'success'
          });
         this.closeModal();
        })
        .catch((error: AxiosError<ServerResponse>) => {
          //@ts-ignore
          this.$notify({group: 'foo', title: 'Fehler', text: error.response!.data.message, type: 'error'});
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
    }

    private saveSignature(signature: { signature: string }, type: string) {
      this.dtr.signature = signature.signature;
    }

    private saveSignatureFather(signature: { signature: string }, type: string) {
      this.dtr.signatureFather = signature.signature;
    }

    private saveSignatureMother(signature: { signature: string }, type: string) {
      this.dtr.signatureMother = signature.signature;
    }

    private closeModal(): void {
      this.$emit('modal-closed');
    }
  }
