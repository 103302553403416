
  import {Component, Vue} from "vue-property-decorator";
  import ArchiveService from "../../archive.service";
  import LoadingOverlay from "../../../../core/components/LoadingOverlay.vue"
  import {Archive} from "../../../../models/archive";
  import Constants from "../../../../staticdata/constants";
  import ReactivateArchiveEntryDialog from "../../components/archive/dialogs/ReactivateArchiveEntryDialog.vue";

  @Component({
    components: {ReactivateArchiveEntryDialog, LoadingOverlay}
  })
  export default class ArchiveAmooreAmaCase extends Vue {

    private tableData = [];
    private showOverlay = false;

    private reactivateArchiveEntryDialogVisible = false;
    private selectedArchiveForReactivate: any = null;

    search = "";


    private amooreAmaCaseTableHeader = [
      {text: 'Jamaat ID', value: 'tajneedEntry.aimsId'},
      {text: 'Waqf-e-Nau ID', value: 'tajneedEntry.waqfenauId'},
      {text: 'Jamaat', value: 'tajneedEntry.jamaat'},
      {text: 'Ikhraj oder Strafe', value: 'deleteTajneedRequest.ikhrajOrOtherPunishment'},
      {text: 'Strafe', value: 'deleteTajneedRequest.otherPunishment'},
      {text: 'Case number/Date', value: 'deleteTajneedRequest.amoreamaHawalaNumberAndDate'},
      {text: 'Antragsteller', value: 'deleteTajneedRequest.requester.username'},
     // {text: 'Reaktivieren', value: 'reactivate', sortable: false, filterable: false, align: 'center'},
    ];


    private amooreAmaCaseTableData = [];

    beforeMount() {
      this.loadArchive();
    }

    loadArchive() {
      this.showOverlay = true;
      ArchiveService.getArchive()
        .then(
          (response: any) => {
            this.showOverlay = false;

            this.amooreAmaCaseTableData = response.data.filter((archive: Archive) =>
              //@ts-ignore
              archive.deleteTajneedRequest.reason === Constants.REASON_AMOORE_AMA_CASE
            );
          })
        .catch((error: any) => {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: error,
            type: 'error'
          });
          this.showOverlay = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.showOverlay = false;
          }, 5000);
        });
    }

    handleReactivateArchiveEntry(selectedEntry: any){
      this.reactivateArchiveEntryDialogVisible = true;
      this.selectedArchiveForReactivate = selectedEntry
    }

    handleCloseReactivateArchiveEntryDialog(){
      this.reactivateArchiveEntryDialogVisible = false;
    }

  }
