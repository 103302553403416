import {AxiosResponse} from "axios";
import httpResource from "../../http/httpResource";

const API_URL = process.env.VUE_APP_API_URL + '/api/documents';

class DocumentsService {

  getAllDocuments(personId: string) : Promise<AxiosResponse> {
    return httpResource.get(API_URL + '/' + personId);
  }

  getDocument(personId: string, documentName: string) : Promise<AxiosResponse> {
    return httpResource.get(API_URL + '/' + personId + '/' + documentName );
  }

  uploadDocuments(data: any) {
    return httpResource.post(API_URL, data);
  }

  updateDocument(data: any): Promise<AxiosResponse> {
    return httpResource.put(API_URL, data);
  }

  deleteDocument(personId: string, documentName: string) : Promise<AxiosResponse> {
    return httpResource.delete(API_URL + '/' + personId + '/' + documentName );
  }

}

export default new DocumentsService();