
  import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
  import plzandorte from '../../../../staticdata/plzandorte.json';
  import streets from '../../../../staticdata/streets.json';

  import DateOfBirthTextField from "./DateOfBirthTextField.vue";
  import FileUploader from "./FileUploader.vue";
  import StaticData from '../../../../staticdata/staticdata'

  import {PersonImage, Tajneed} from '../../tajneed.types';
  import {Getter} from "vuex-class";
  import {Useraccount} from "../../../../models/useraccount.model";

  @Component({
    components: {DateOfBirthTextField, FileUploader},
  })
  export default class BasisdatenForm extends Vue {

    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    @Prop({default: null})
    tajneed: Tajneed;

    @Prop({default: false})
    editingTajneed: boolean;

    @Prop({default: false})
    approvingNewTajneed: boolean;

    @Prop({default: false})
    showEducationPage: boolean;

    @Prop({default: false})
    showAddInfoAndConfirmPage: boolean;

    @Prop({required: false, default: false})
    deleteRequested!: boolean;


    @Ref() readonly fileUploader!: FileUploader;

    @Ref() readonly dateOfBirthTextField!: DateOfBirthTextField;

    private locTajneed: Tajneed = {
      street: ""
    };

    private cityItems: any[] = [];
    private jamaat = "";
    private majlis = "";

    private streetItems: string[] = [];

    private musiItems = ["Ja", "Nein", "Beantragt"];

    private disability = "";

    private musiJa: boolean = false;

    private cityPostalCode = "";
    private dateOfBirth: any = "";

    private showMaritalStatus: boolean = false;

    private showEmail: boolean = false;

    private locShowEducationPage: boolean = false;

    private locShowAddInfoAndConfirmPage: boolean = false;

    private allJamaats: string[] = [];

    private allMajalis: string[] = [];

    private allCountries = StaticData.getAllCountries();

    private allNationalities = StaticData.getAllNationalities();

    private enterPartnerWaqfenauIdDialogVisible = false;

    max = 50;

    notempty = [
      (v: string) => !!v || 'Feld muss ausgefüllt sein'
    ];

    numbersonly = [
      (v: string) => !v || /^\d+$/.test(v) || 'Nur Zahlen erlaubt'
    ];

    phonenumber = [
      (v: string) => !!v || 'Feld muss ausgefüllt sein',
      (v: string) => !v || /^\d+$/.test(v) || 'Nur Zahlen erlaubt',
    ];

    aimsId = [
      (v: string) => !v || /^\d+$/.test(v) || 'Nur Zahlen erlaubt',
      (v: string) => /^(0|[0-9][0-9][0-9][0-9][0-9])$/.test(v) || 'Keine gültige Jamaat-ID',
    ];

    created() {
      this.loadOrtAndPlzSelectItems();

      this.loadStreets();

      this.updateOnTajneedChange();
    }

    private loadStreets() {
      for (var i = 0; i < streets.length; i++) {
        this.streetItems.push(streets[i]);
      }
    }

    @Watch('tajneed')
    onTajneedChange(value: string) {
      this.updateOnTajneedChange();
    }

    get isValid(): boolean {
      if ((this.$refs.formBasisdaten as Vue & { validate: () => boolean }).validate()) {
        if ((!this.locTajneed.street && !this.locTajneed.missingStreet)) {
          this.$notify({
            group: 'foo',
            title: 'Fehler',
            text: "Straße fehlt",
            type: 'error'
          });
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    private updateOnTajneedChange() {
      this.locTajneed = JSON.parse(JSON.stringify(this.tajneed));

      if (this.editingTajneed || this.approvingNewTajneed) {
        for (var i = 0; i < this.cityItems.length; i++) {
          if (this.cityItems[i].text.indexOf(this.locTajneed.postalCode) >= 0 && this.cityItems[i].text.indexOf(this.locTajneed.city) >= 0) {
            this.cityPostalCode = this.cityItems[i];
          }
        }

        this.musiJa = this.locTajneed.musi == 'Ja' ? true : false;

        this.dateOfBirth = this.locTajneed.dateOfBirth

      } else {
        let roles = this.getUseraccount.getRoles;
        let jamaatR = roles.filter((r: any) => r.indexOf("JAMAAT") >= 0);
        if (jamaatR.length > 0) {
          this.locTajneed.sex = "Male";
        } else {
          this.locTajneed.sex = "Female";
        }
      }

      this.locShowAddInfoAndConfirmPage = this.showAddInfoAndConfirmPage;
    }

    @Watch('locShowEducationPage')
    onLocShowEducationPage(value: string, oldValue: string) {
      this.$emit('update:showEducationPage', this.locShowEducationPage);
    }

    @Watch('locShowAddInfoAndConfirmPage')
    onLocShowAddInfoAndConfirmPage(value: string, oldValue: string) {
      this.$emit('update:showAddInfoAndConfirmPage', this.locShowAddInfoAndConfirmPage);
    }

    private loadOrtAndPlzSelectItems() {
      //Load orte in autocomplete field:
      for (var i = 0; i < plzandorte.length; i++) {
        this.cityItems.push({
          text: plzandorte[i].plz + " " + plzandorte[i].ort,
          value: plzandorte[i].id
        });
      }
    }

    get getBasisdaten(): Tajneed {
      return this.locTajneed;
    }

    handleMusiChange(selection: any) {
      if (selection === "Ja") {
        this.musiJa = true;
      } else {
        this.musiJa = false;
      }
    }

    handleOrtChange(selection: any) {
      this.locTajneed.postalCode = selection.text.substring(0, 5);
      this.locTajneed.city = selection.text.substring(6);
    }

    get getDateOfBirth(): string {
      //@ts-ignore
      return this.dateOfBirthTextField.getDateOfBirth;
    }

    get getFileData(): PersonImage | null {
      //@ts-ignore
      if (this.fileUploader) {
        //@ts-ignore
        return this.fileUploader.getFile;
      } else {
        // Can be null for women, because they dont have picture
        return null;
      }
    }
  }
