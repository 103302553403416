
  import {Component, Prop, Vue} from "vue-property-decorator";
  import BasedataService from '../basedata.service';
  import LoadingOverlay from "../../../core/components/LoadingOverlay.vue"
  import {RegionLocalAmarat} from "../basedata.types";

  @Component({
    components: {LoadingOverlay},
  })
  export default class CreateNewJamaatHalqaDialog extends Vue {

    private showOverlay = false;

    private newJamaatHalqaName: string = '';

    private regionLaItems: string[] = [];

    private regionLa: string = '';

    private valid: boolean = true;

    notempty = [
      (v: string) => !!v || 'Feld muss ausgefüllt sein'
    ];

    created() {
      this.loadRegions();
    }

    private loadRegions() {
      BasedataService.getRegionsandLocalAmarats().then((response: any) => {
        this.regionLaItems = response.data.map((a: RegionLocalAmarat) => a.name);
      }).catch((error: any) => {
        this.$notify({
          group: 'foo',
          title: 'Fehler',
          text: error,
          type: 'error'
        });
      }).finally(() => {
        setTimeout(() => {
        }, 5000);
      });
    }

    createNewJamaatHalqa() {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        this.showOverlay = true;
        BasedataService.createNewJamaatHalqa(this.newJamaatHalqaName, this.regionLa)
          .then((response: any) => {
            this.showOverlay = false;
            this.$notify({
              group: 'foo',
              title: 'Info',
              text: 'Jamaat wurde erfolgreich angelegt',
              type: 'success'
            });
            this.$emit('success');
          })
          .catch((error: any) => {
            this.$notify({
              group: 'foo',
              title: 'Fehler',
              text:  error.response!.data.message,
              type: 'error'
            });
            this.showOverlay = false;
          })
          .finally(() => {
            setTimeout(() => {
              this.showOverlay = false;
            }, 5000);
          });
      }
    }

  }
