
  import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";

  import {Getter} from "vuex-class";
  import {Useraccount} from "../../../models/useraccount.model";
  import {Jaiza} from "../jaiza.types";

  @Component({
    components: {},
  })
  export default class BookReadingSection extends Vue {

    @Getter('getUseraccount', {namespace: 'useraccount'})
    private getUseraccount!: Useraccount;

    @Prop({default: null})
    jaiza: Jaiza;

    @Prop({default: false})
    isFourYearsOld: boolean;

    @Prop({default: false})
    isFiveYearsOld: boolean;

    @Prop({default: false})
    isSixYearsOld: boolean;

    @Prop({default: false})
    isSevenYearsOld: boolean;

    @Prop({default: false})
    isEightYearsOld: boolean;

    @Prop({default: false})
    isNineYearsOld: boolean;

    @Prop({default: false})
    isTenYearsOld: boolean;

    @Prop({default: false})
    isElevenYearsOld: boolean;

    @Prop({default: false})
    isTwelveYearsOld: boolean;

    @Prop({default: false})
    isThirteenYearsOld: boolean;

    @Prop({default: false})
    isFourteenYearsOld: boolean;

    @Prop({default: false})
    isFifteenYearsOld: boolean;

    @Prop({default: false})
    isSixteenYearsOld: boolean;

    @Prop({default: false})
    isSeventeenYearsOld: boolean;

    @Prop({default: false})
    judgeColumnVisible: boolean;

    @Prop({default: false})
    judgeCheckDisabled: boolean;


  }
