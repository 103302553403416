
  import Vue from 'vue';
  import {router} from '../../../router/index'
  import {Useraccount} from "../../../models/useraccount.model";
  import {Component} from "vue-property-decorator";

  import {Getter, Mutation} from 'vuex-class';
  import {OwnMetaData, Tajneed} from "../../tajneed/tajneed.types";
  import LoadingOverlay from "../../../core/components/LoadingOverlay.vue";
  import AuthService, {SignInResponse} from '../../../services/auth.service';
  import {getAuthenticatedUser, parseApierror, performLogout} from "../../../utils/utils";
  import httpResource from "../../../http/httpResource";


  //const store = this.$store.direct;
  @Component({
    components: {LoadingOverlay},
  })
  export default class LoginView extends Vue {

    @Mutation('SET_TAJNEED', {namespace: 'tajneed'})
    SET_TAJNEED!: (tajneed: Tajneed[]) => void;

    @Mutation('SET_OWN_METADATA', {namespace: 'accountmetadata'})
    SET_OWN_METADATA!: (ownMetaData: OwnMetaData) => void;

    user = new Useraccount(0, '', '', '', '', [], false, []);

    private badCredentials = false;

    private showOverlay = false;

    private username = '';

    private password = '';

    private isSafari: boolean = false;

    created() {
      // @ts-ignore
      this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    async login() {
      if (this.username && this.password) {
        this.showOverlay = true;
        const loginRequest = {
          username: this.username.trim(),
          password: this.password.trim(),
          origin: 'web'
        };
        try {
          const response = await httpResource.post("api/auth/login", loginRequest);
          if (response.status === 200) {
            window.location.reload();
          }
          if (response.status == 201) {
            this.showOverlay = false;
            router.push({
              name: 'ActivateAccountView', params: {
                username: this.username,
                tajneedFirstName: response.data.tajneedFirstName,
                tajneedLastName: response.data.tajneedLastName,
                confirmationAttempts: response.data.confirmationAttempts + "",
              }
            });
          }
        } catch (error) {
          this.showOverlay = false;
          performLogout();
          const apierror = parseApierror(error);
          this.badCredentials = true;
          // @ts-ignore
          if (apierror.status == 401) {
            this.badCredentials = true;
          }
        }
      }
    }

    setHeight() {
      if (this.badCredentials) {
        if(this.$vuetify.breakpoint.xsOnly) {
          return 'largeCard';
        } else {
          return 'largeCardDesktop';
        }
      } else {
        if(this.$vuetify.breakpoint.xsOnly) {
          return 'smallCard';
        } else {
          return 'smallCardDesktop';
        }
      }
    }
  }
