var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticStyle:{"padding":"20px"}},[_c('h2',{attrs:{"align":"center"}},[_vm._v("Daten des Amtsinhabers")]),_c('br'),_c('v-container',[_c('v-form',{ref:"form"},[_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required,
        ],"dense":"","label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required,
          _vm.rules.digitsOnly,
        ],"dense":"","label":"Jamaat-ID"},model:{value:(_vm.aimsId),callback:function ($$v) {_vm.aimsId=$$v},expression:"aimsId"}}),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required,
          _vm.rules.dateFormat,
        ],"dense":"","label":"Geburtsdatum"},model:{value:(_vm.dateOfBirth),callback:function ($$v) {_vm.dateOfBirth=$$v},expression:"dateOfBirth"}}),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required,
          _vm.rules.digitsOnly,
        ],"dense":"","label":"Telefon/Handy"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.digitsOnly,
        ],"dense":"","label":"Whatspp-Nummer"},model:{value:(_vm.whatsAppNumber),callback:function ($$v) {_vm.whatsAppNumber=$$v},expression:"whatsAppNumber"}}),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","persistent-hint":true,"rules":[
          _vm.rules.required,
        ],"dense":"","label":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{staticStyle:{"border-radius":"0px"},attrs:{"outlined":"","label":"E-Mail Wiederholen","rules":[
          _vm.rules.required,
          _vm.rules.emailConfirmation,
        ],"dense":""},model:{value:(_vm.emailConfirmation),callback:function ($$v) {_vm.emailConfirmation=$$v},expression:"emailConfirmation"}})],1),_c('br'),_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":"","justify":'center'}},[_c('v-btn',{staticStyle:{"border-radius":"0px"},attrs:{"color":"primary"},nativeOn:{"click":function($event){return _vm.createNewTajneed.apply(null, arguments)}}},[_vm._v(" Speichern ")])],1),(_vm.showOverlay)?_c('LoadingOverlay'):_vm._e()],1),_c('br')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }